import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_NOTES, ShopOrderNoteDocument} from '../../shared/helpers/FirebaseHelper';
import {firestoreTimestamp} from '../../../__internal/local/helpers/FirestoreHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {GLOBAL_GRAPPYFILL} from '../../../../aspire-app';

@customElement('component-shop-admin-order-notes')
class ComponentShopAdminOrderNotes extends BunnyElement {

    @property({type: Object})
    value: any;

    @property({type: Array})
    get fields() {
        return [
            {
                cellProperties: {
                    style: '--col-md: 2',
                    field: 'type',
                },
                header: {
                    properties: {
                        textContent: 'Type',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 7',
                    field: 'body',
                },
                header: {
                    properties: {
                        textContent: 'Message',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 3',
                    field: 'created',
                    type: 'datetime',
                },
                header: {
                    properties: {
                        textContent: 'Created',
                    },
                },
                cell: {
                    properties: {
                        format: 'dd/MM/yyyy HH;mm;ss.SSS',
                    },
                },
            },
            {
                cellProperties: {
                    field: 'transaction.id.id',
                },
                header: false,
                cell: {
                    properties: {
                        style: 'font-size: 60%',
                    },
                },
            },
        ];
    }

    listOrder = [['created', 'asc']];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let path = this.value?._ref?.path;

        return html`
            ${path ? html`
                <component-firestore-collection-list .path="${this.value?._ref?.path}/notes"
                                                     .fields="${this.fields}"
                                                     .fetchMethod="${'live'}"
                                                     .order="${this.listOrder}"
                                                     .limit="${999}"
                >
                </component-firestore-collection-list>
            ` : ''}

            <component-button @click="${(_: MouseEvent) => this.addNote('customer')}">
                Add customer note
            </component-button>
            <component-button @click="${(_: MouseEvent) => this.addNote('private')}">
                Add private note
            </component-button>
        `;
    }

    @toastProgressWrapper({progressMessage: 'Adding note', successMessage: 'Added note'})
    async addNote(type: string) {
        let message = '';

        while (1) {
            message = prompt(`${type} note`, message) as string;

            if (!message) throw new FriendlyMessage(`Note ${type}: Was not added`);
            if (confirm(`Are you sure you want to add this ${type} note: ${message}`)) break;
        }


        let collectionRef = collection((this as any).value._ref, FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_NOTES);
        await addDoc(collectionRef, {
            type: type,
            updated: firestoreTimestamp(),
            created: firestoreTimestamp(),
            causer: GLOBAL_GRAPPYFILL.account?._ref as any,
            body: message,
        } as ShopOrderNoteDocument);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-order-notes': ComponentShopAdminOrderNotes;
    }
}
