import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';

@customElement('component-admin-auth-methods')
class ComponentAdminAuthMethods extends BunnyElement {

    @property({type: String})
    accountId: string;

    @property({type: Array})
    authMethods: any[] = [];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <div class="gridContainer">
                ${this.authMethods.map(item => html`
                    <div style="--col: 2">${item.type}</div>
                    <div style="--col: 6">
                        ${JSON.stringify(item.value)}
                    </div>
                    <div style="--col: 4">
                        ${item.type === 'password' ? html`
                            <component-button @click="${(_: MouseEvent) => this.setPassword(item)}">
                                Set password
                            </component-button>
                            <component-button @click="${(_: MouseEvent) => this.setEmailAddress(item)}">
                                Set email address
                            </component-button>
                        ` : undefined}

                        <component-button @click="${(_: MouseEvent) => this.unlinkMethod(item)}"
                                          style="background-color: var(--attention-color); ">
                            Unlink
                        </component-button>
                    </div>
                `)}
            </div>
        `;
    }

    @observe('accountId')
    async loadAccountAuthMethods(accountId: string) {
        if (!accountId) return;

        this.authMethods = await adminCall.authGetAccountAuthMethods(accountId);
    }

    @toastProgressWrapper()
    @confirmationDialog({body: 'Are you sure you want to unlink this method'})
    async unlinkMethod(method: any) {
        await adminCall.authUnlinkAccountAuth(this.accountId, method.type, method.value.uid);
    }

    @toastProgressWrapper()
    @confirmationDialog({body: 'Are you sure you want to set the password'})
    async setPassword(method: any) {
        let password = prompt('New password');
        if (!password || password?.length < 6) throw new Error('Password needs to be atleast 6 long');

        await adminCall.authUpdateAccountAuthPassword(this.accountId, method.type, method.value.uid, password);
    }

    @toastProgressWrapper()
    @confirmationDialog({body: 'Are you sure you want to set the email address'})
    async setEmailAddress(method: any) {
        let emailAddress = prompt('New email address');
        if (!emailAddress || emailAddress?.length < 6) throw new Error('Email address needs to be atleast 6 long');

        await adminCall.authUpdateAccountAuthEmail(this.accountId, method.type, method.value.uid, emailAddress);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-auth-methods': ComponentAdminAuthMethods;
    }
}