import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-data-collection-list-item-cell-order-item-name')
class ComponentDataCollectionListItemCellOrderItemName extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            a {
                display: block;
                height: 100%;
            }

            ul {
                font-size: 85%;
                margin-top: 5px;
                margin-bottom: 5px;
                padding-left: 25px;
                list-style: disc;
                text-transform: capitalize;
            }
        `,
    ];


    override render() {
        return html`
            ${this.item.name ? html`
                <span>${this.item.name}</span>
            ` : html`
                <resolve-firestore-document-name .path="${this.item.productVariant.path}"
                                                 format="::name::"></resolve-firestore-document-name>
            `}
        `;
    }


}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-order-item-name': ComponentDataCollectionListItemCellOrderItemName;
    }
}