import {customElement, query} from 'lit/decorators.js';
import {ComponentMenusItem} from '../../../menu/local/components/component-menus-item';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument';

let getUnreadNotificationsCountSurrealDocument: SurrealDocument;

@customElement('component-menus-item-notifications')
class ComponentMenusItemNotifications extends ComponentMenusItem {

    @property({type: Object, notify: true})
    auth = Auth.getInstance(this);

    @property({type: String, notify: true})
    @computed('auth')
    get authUserId() {
        return this.auth.user?.uid;
    }

    @property({type: Object, notify: true})
    @computed('authUserId')
    get notificationStats() {
        if (!this.authUserId) return undefined;

        if (!getUnreadNotificationsCountSurrealDocument) {
            getUnreadNotificationsCountSurrealDocument = new SurrealDocument<{
                count: number
            }>(undefined, 'notifications::getUnreadNotificationsCount', [], {method: FetchMethod.LIVE});
        }

        getUnreadNotificationsCountSurrealDocument.addHost(this);
        return getUnreadNotificationsCountSurrealDocument;
    }

    @property({type: Number})
    @computed('notificationStats')
    get count() {
        if (!this.notificationStats?.data) return 0;

        let unreadCount = this.notificationStats.data.count || 0;
        if (unreadCount > 99) return ':D';

        return unreadCount;
    };

    @query('#count')
    countElement: HTMLElement;

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            #count {
                position: absolute;
                top: -5px;
                left: -9px;
                border-radius: 100%;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                color: white;
                background-color: var(--attention-color);
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                font-size: 12px;
                transform-origin: center center;
                transition: .075s cubic-bezier(0, 0, 0.79, 1.63);
                transform: scale(0);
            }

            #count.show {
                transition: .125s;
                transform: scale(1);
            }

            #count[data-count="0"] {
                transform: scale(0);
            }

            @media screen and (min-width: 1025px) and (max-width: 1300px) {
                component-button {
                    --padding: 9.125px 11.2px;

                    slot {
                        display: none;
                    }
                }
            }
        `];

    override render() {
        return html`
            <a href="${this.href}">
                <component-button>
                    <span style="position:relative;">
                        <component-icon style="margin-top: -5px"
                                        icon="social:notifications-${this.count ? 'active' : 'none'}">
                        </component-icon>
                        <div id="count" data-count="${this.count}">
                            ${this.count}
                        </div>
                    </span>
                    <slot></slot>
                </component-button>
            </a>
        `;
    }

    @observe('count')
    async triggerShowCount() {
        let classList = this.countElement.classList;
        classList.remove('show');

        await delayPromise(75);
        classList.add('show');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-menus-item-notifications': ComponentMenusItemNotifications;
    }
}