import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper.ts';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper.ts';
import {Auth} from '../controllers/Auth.ts';

export const userCall = async <ReturnType = any>(action: string, args: any, contentType: 'application/json' | 'multipart/form-data' | any = 'application/json') => {
    let body = args;
    if (contentType === 'application/json') {
        body = JSONStringify(args);

    } else if (contentType === 'multipart/form-data') {
        body = new FormData();
        for (let name in args) {
            (body as FormData).append(name, args[name]);
        }

        contentType = '';//nuke the content type so the browser can give it a multi part boundary
    }

    let auth = Auth.getInstance().getIdToken() as string;
    let response = await (await fetch(`/_/usercallv2?action=${action}`, {
        headers: {
            ...(auth ? {'X-Auth': auth as string} : undefined),
            ...(contentType ? {'Content-Type': contentType} : undefined),
        },
        method: 'POST',
        body: body,
    })).json();
    if (response.status === 'error') throw new FriendlyMessage(response._messages[0]);

    return response as ReturnType;
};


export const userCalls = {
    confirmPasswordReset(code: string, newPassword: string) {
        return userCall<{ emailAddress: string }>('AuthConfirmPasswordReset', {
            code: code,
            newPassword: newPassword,
        });
    },
};