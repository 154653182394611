import {ShopProductDocument, ShopProductVariantDocument, ShopProductVariantSaleMatrixRule} from './FirebaseHelper';

interface FlatPrice {
    originalValue: number;
    value: number;
    isSale: boolean;
}

export class ShopProductPrice {
    private product: ShopProductDocument;
    private productVariant: ShopProductVariantDocument;
    private productVariantStockLock: { stock: number; };
    private flatPrice: FlatPrice;


    constructor(product: ShopProductDocument, productVariant: ShopProductVariantDocument, productVariantStockLock: any) {
        this.product = product;
        this.productVariant = productVariant;
        this.productVariantStockLock = productVariantStockLock;

        this.flatPrice = this.calculatePrice();
    }

    getProductFinished() {
        let date = this.product.finished;
        if (!date) return new Date(0);

        return date;
    }

    getProductPublished() {
        let date = this.product.published;
        if (!date) return new Date(Number.MAX_SAFE_INTEGER);

        return date;
    }

    private ruleValid(rule: ShopProductVariantSaleMatrixRule) {
        let conditionValue = undefined;
        let compareValue = rule.value;

        if (typeof compareValue === 'string' && ['finishedTill', 'now', 'publishedAgo']) {
            //TODO TMP fix for elasticsearch existing, remove when gone
            throw new Error('Shouldnt be hit any more');
        }


        switch (rule.rule) {
            case 'finishedTill':
                conditionValue = (this.getProductFinished().getTime() - Date.now()) / 86400000;
                break;

            case 'now':
                conditionValue = Date.now();
                compareValue = compareValue instanceof Date ? compareValue.getTime() : 0;
                break;

            case 'publishedAgo':
                conditionValue = (Date.now() - this.getProductPublished().getTime()) / 86400000;
                break;

            case 'soldPercentage':
                conditionValue = (this.productVariant.maxStock - this.productVariantStockLock.stock) / this.productVariant.maxStock;
                break;

            case 'soldQuantity':
                conditionValue = this.productVariant.maxStock;
                break;

            default:
                throw new Error(`Unknown rule ${rule.rule}`);
        }


        if (compareValue instanceof Date) {
            compareValue = compareValue.getTime();
        }

        switch (rule.condition) {
            case 'equalTo':
                return conditionValue === compareValue;

            case 'moreThan':
                return conditionValue > compareValue;

            case 'moreThanEqualTo':
                return conditionValue >= compareValue;

            case 'lessThan':
                return conditionValue < compareValue;

            case 'lessThanEqualTo':
                return conditionValue <= compareValue;

            default:
                throw new Error(`Unknown condition ${rule.condition}`);
        }
    }

    private rulesValid(rules: ShopProductVariantSaleMatrixRule[]) {
        for (let rule of rules) {
            if (!this.ruleValid(rule)) return false;
        }

        return true;
    }

    private calculatePrice() {
        let originalValue = this.productVariant.price;
        let value = originalValue;

        if (this.productVariant.saleMatrix) {
            for (let saleMatrix of this.productVariant.saleMatrix) {
                if (!this.rulesValid(saleMatrix.rules)) continue;

                switch (saleMatrix.modifier) {
                    case 'percentage':
                        value = originalValue * saleMatrix.value;
                        break;

                    case 'reducePercentage':
                        value *= 1 - saleMatrix.value;
                        break;

                    case 'reduceValue':
                        value -= saleMatrix.value;
                        break;

                    case 'value':
                        value = saleMatrix.value;
                        break;

                    default:
                        throw new Error(`Unknown modifier ${saleMatrix.modifier}`);
                }


                if (saleMatrix.final) break;
            }
        }


        let newValue = Math.max(0, Math.round(value));
        return {
            originalValue: originalValue,
            value: newValue,
            isSale: newValue < originalValue,
        } as FlatPrice;
    }

    getPrice() {
        return this.flatPrice.value;
    }

    getOriginalPrice() {
        return this.flatPrice.originalValue;
    }

    isSale() {
        return this.flatPrice.isSale;
    }


}