import {FirebaseAnalyticsPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-google-analytics';
import './components/component-firebase-remote-config';
import './components/component-facebook-analytics';
import './components/component-trustpilot-analytics';
import './components/component-tracking';
import './components/component-tracking-interactable';
import './components/component-firebase-analytics-activity-stream';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';


export class FirebaseAnalyticsLocalPackage extends FirebaseAnalyticsPackage implements LocalPackage {

    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            if (localStorage['testMode']) return;

            e.registerPostComponent({
                component: 'component-facebook-analytics',
                properties: {
                    pixelId: '2174968035946622',
                },
            }, undefined, true);
            // e.registerPostComponent({
            //     component: 'component-tiktok-analytics',
            //     properties: {
            //         pixelId: 'C85AS37Q6F4IGJFVKL3G',
            //     },
            // }, undefined, true);
            e.registerPostComponent({
                component: 'component-google-analytics',
                properties: {
                    pixelId: 'AW-589436067',
                },
            }, undefined, true);
            e.registerPostComponent({
                component: 'component-trustpilot-analytics',
                properties: {
                    integrationKey: 'pkHFXXA1DsInx8LT',
                },
            }, undefined, true);

            e.registerPostComponent({
                component: 'component-tracking',
            }, undefined, true);
        },
    };
}
