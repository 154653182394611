import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {storageBoundLocalStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';

// language=SCSS
const LOG_STYLES = scss`
    .logRow {
        min-height: 0 !important;
        min-width: 100% !important;
        padding-bottom: 5px;
        padding-top: 5px;
        border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
        display: flex !important;
        column-gap: 5px;
        flex-wrap: wrap;
    }

    .logRow span {
        padding: 2px;
        border-radius: 3px;
        background-color: rgba(32, 32, 32, .2);
        text-align: center;
        min-width: 15px;
        font-size: 10px;
    }

    .logRow span[data-log-severity="info"] {
        background-color: rgba(0, 33, 122, 0.37);
    }

    .logRow span[data-log-severity="warn"] {
        background-color: rgba(122, 106, 0, 0.37);
    }

    .logRow span[data-log-severity="error"] {
        background-color: rgba(122, 33, 0, 0.37);
    }

    .logRow pre {
        display: inline-block;
        height: 18px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        vertical-align: text-bottom;
        margin-left: 5px;
        padding-left: 5px;
        border-left: solid rgba(0, 0, 0, 0.1) 1px;
        flex: 1;
        white-space: break-spaces;
    }
`;

@customElement('component-dev-tools-emulator-log')
export class ComponentDevToolsEmulatorLog extends BunnyElement {

    @property({type: Array, notify: true})
    rawItems: any[] = [];

    @property({type: String, notify: true})
    @storageBoundLocalStorage('dev-tools-emulator-log-search')
    search: string = '';

    @property({type: Object})
    enabledLogLevels = {
        ERROR: true,
        LOG: true,
        WARNING: true,
        DEBUG: false,
        INFO: false,
    };

    @property({type: Object, notify: true})
    @computed('rawItems')
    get logLevelStats() {
        let logBuckets = Object.fromEntries(
            Object.keys(this.enabledLogLevels)
                .map(_ => [_, 0]),
        );

        for (let rawItem of this.rawItems) {
            logBuckets[rawItem.severity]++;
        }

        return logBuckets;
    }

    set logLevelStats(_ignore: any) {
    }

    @property({type: Array})
    @computed('search')
    get regexSearches() {
        let search = this.search;
        if (!search) return [];

        let searches = search.split(' && ');
        return searches.map(_ => {
            if (_[0] === '-') {
                let regex = new RegExp(_.substr(1), 'i');
                (regex as any).invert = true;

                return regex;

            } else {
                return new RegExp(_, 'i');
            }
        });
    }


    @property({type: Array})
    @computed('rawItems', 'regexSearches', 'enabledLogLevels')
    get items() {
        let regexSearches = this.regexSearches;
        if (!regexSearches?.length) {
            return this.rawItems.filter(_ => {
                return this.enabledLogLevels[_.severity];
            });
        }


        return this.rawItems.filter(_ => {
            if (!this.enabledLogLevels[_.severity]) return false;


            for (let regexSearch of regexSearches) {
                let matches = _._search.match(regexSearch);
                if ((regexSearch as any).invert) {
                    matches = !matches;
                }


                if (!matches) return false;
            }

            return true;
        });
    }

    private tempNewItems: any[] = [];

    private socket: WebSocket;

    @property({type: Object})
    viewFullLog: any;

    @property({type: Number, notify: true})
    @storageBoundLocalStorage('dev-tools-emulator-log-last-cleared', '0', _ => parseInt(_))
    lastCleared = 0;

    @property({type: String})
    bunDebugUrl: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: flex;
                flex-direction: column;
                height: 400px;
            }

            .fullLog {
                overflow: auto;
                flex: 1;
                height: auto;
                max-height: none;
            }


            component-input-checkbox[data-log-severity] {
                background-color: rgba(32, 32, 32, .2);
                padding: 3px;
                flex: 1;
            }

            component-input-checkbox[data-log-severity="info"] {
                background-color: rgba(0, 33, 122, 0.37);
            }

            component-input-checkbox[data-log-severity="warn"] {
                background-color: rgba(122, 106, 0, 0.37);
            }

            component-input-checkbox[data-log-severity="error"] {
                background-color: rgba(122, 33, 0, 0.37);
            }
        `,
    ];

    renderLogLabel(title: string, field: string, value: string) {
        if (!value) return undefined;

        return html`
            <span title="${title}"
                  @click="${(_: MouseEvent) => this.search = `${field}:${value}`}">${value}</span>
        `;
    }

    override render() {
        return html`
            <component-input .value="${this.bind.search}" placeholder="Search" style="margin-top: 0;">
                ${this.bunDebugUrl ? html`
                    <component-button slot="prefix"
                                      style="min-width: 0; padding: 3px 5px;"
                                      title="${this.bunDebugUrl}"
                                      @click="${() => open(this.bunDebugUrl, 'BUN_DEBUGGER')}">
                        Debug
                    </component-button>
                ` : undefined}
                <component-button slot="suffix"
                                  style="min-width: 0; padding: 3px 5px;"
                                  @click="${this.clearLogs}">
                    Clear
                </component-button>
            </component-input>
            <div style="font-size: 25px; zoom:.5; display: flex; gap: 3px; border-bottom: solid black 1px">
                ${Object.entries(this.enabledLogLevels).map(([key, value]) => html`
                    <component-input-checkbox data-log-severity="${key.toLowerCase()}"
                                              .checked="${this.bindDeep(`enabledLogLevels.${key}`)}"
                                              @click="${() => {
                                                  setTimeout(() => {
                                                      this.requestUpdate('enabledLogLevels');
                                                  });
                                              }}">
                        ${key[0]}${this.logLevelStats?.[key]}
                    </component-input-checkbox>
                `)}
            </div>

            ${!this.items?.length ? html`
                <p>No results for ${this.search}</p>
            ` : undefined}
            <component-list-view
                    class="fullLog"
                    .items="${this.items}"
                    .injectedStyles="${LOG_STYLES}"
                    .renderItem="${(item: any, _index: number, column: number, row: number) => {
                        if (!item) return undefined;

                        return html`
                            <div class="logRow" style="--x: ${column}; --y: ${row};">
                                <span data-log-severity="${item.severity?.toLowerCase()}"
                                      style="text-transform: uppercase"
                                      title="${item.severity}"
                                      @click="${(_: MouseEvent) => this.appendSearch(`level:${item.severity}`)}">
                                    ${item.severity[0]}
                                </span>

                                ${this._formatTimestamp(item.timestamp)}

                                ${this.renderLogLabel(`Function: ${this._formatFunctionName(item.function)}`, 'function', this._formatShortFunctionName(item.function))}
                                ${this.renderLogLabel('Package', 'package', item.package)}
                                ${this.renderLogLabel('RequestId', 'requestId', item.requestId, value => value.split('-').at(-1))}
                                ${this.renderLogLabel('Logger', 'logger', item.logger)}
                                ${this.renderLogLabel('Handler', 'handler', item.handler)}

                                <pre @click="${(_: MouseEvent) => this.viewFullLog = item}">${this._formatMessage(item.message || item.message)}</pre>
                            </div>
                        `;
                    }}">
            </component-list-view>


            ${this.viewFullLog ? html`
                <component-dialog modal opened @opened-changed="${(e: CustomEvent) => {
                    if (!e.detail.value) this.viewFullLog = undefined;
                }}">
                    <pre style="overflow: auto; max-height: 80vh">${this._linkUpSourceFiles(this._formatMessage(this.viewFullLog.message))}</pre>
                    <hr>
                    ${this.viewFullLog.stackTrace ? html`
                        <pre style="overflow: auto; max-height: 80vh">${this._linkUpSourceFiles(this.viewFullLog.stackTrace.split('\n').slice(4).join('\n'))}</pre>
                        <hr>
                    ` : undefined}
                    <pre style="overflow: auto; max-height: 20vh;">${JSON.stringify({
                        ...this.viewFullLog,
                        message: undefined,
                        stackTrace: undefined,
                    }, undefined, 4)}</pre>
                </component-dialog>
            ` : undefined}
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.socket = new WebSocket('ws://127.0.0.1:4500/');
        this.socket.onmessage = this.handleMessage;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.socket.close();
    }

    renderLogLabel(title: string, field: string, value: string, valueFormatter = (value) => value) {
        if (!value) return undefined;

        return html`
            <span title="${title}"
                  @click="${(_: MouseEvent) => this.appendSearch(`${field}:${value}`)}">
                ${valueFormatter(value)}
            </span>
        `;
    }

    appendSearch(value) {
        if (this.search) {
            this.search += ' && ';
        }

        this.search += value;
    }

    clearLogs() {
        this.lastCleared = Date.now();
    }

    @listen('keydown', window)
    onCtrlL(e: Event) {
        if (!(e instanceof KeyboardEvent)) return;

        if ((e.ctrlKey || e.metaKey) && e.key === 'l') {
            this.clearLogs();
            e.preventDefault();
        }
    }

    _linkUpSourceFiles(value: string) {
        let htmlSanitizer = document.createElement('div');
        htmlSanitizer.textContent = value;

        value = htmlSanitizer.innerHTML;

        value = value.replace(new RegExp(`${location.origin}(/src/[^:]*.ts)(?:\\?t=\\d+)?:(\\d+):(\\d+)`, 'g'), (m, m1, m2, m3) => {
            return `<a href="#" style="font-weight: bold" onclick="event.preventDefault(); fetch('http://localhost:54322/api/file?file=frontend${m1}&line=${m2}&column=${m3}&focused=true')">${m}</a>`;
        });
        value = value.replace(new RegExp(`/workspace/([^:]*.ts):(\\d+):(\\d+)`, 'g'), (m, m1, m2, m3) => {
            return `<a href="#" style="font-weight: bold" onclick="event.preventDefault(); fetch('http://localhost:54322/api/file?file=${m1.replace('node_modules/@lupimedia', '@lupimedia').replace(/^packages\//g, 'src/packages/')}&line=${m2}&column=${m3}&focused=true')">${m}</a>`;
        });

        return unsafeHTML(value);
    }

    _formatMessage(value: string) {
        return value.replace(/^> /, '');
    }

    _formatFunctionName(value: string) {
        return value?.replace('europe-west1-', '');
    }

    _formatShortFunctionName(value: string) {
        return this._formatFunctionName(value)?.split('-')?.reverse()[0];
    }

    _formatTimestamp(value: string) {
        return RenderingHelper._dateFormat(new Date(value), 'HH:mm:ss.SSS');
    }

    generateLogMessageSearchValue(logMessage: any) {
        let search = `level:${logMessage.severity}`;
        if (logMessage.function) {
            search += `; function:${this._formatShortFunctionName(logMessage.function)}`;
        }
        if (logMessage.package) {
            search += `; package:${logMessage.package}`;
        }
        if (logMessage.logger) {
            search += `; logger:${logMessage.logger}`;
        }
        if (logMessage.requestId) {
            search += `; requestId:${logMessage.requestId}`;
        }
        if (logMessage.handler) {
            search += `; handler:${logMessage.handler}`;
        }

        search += '; ' + this._formatMessage(logMessage.message);

        return search;
    }

    @bind()
    handleMessage(e: MessageEvent) {
        let logMessage;
        try {
            logMessage = JSON.parse(e.data);

        } catch (err) {
            logMessage = {
                timestamp: ((this.tempNewItems[0] || this.rawItems[0])?.timestamp || Date.now()) + 1,
                message: e.data,
                severity: 'ERROR',
            };
        }

        if (logMessage.message.includes('https://debug.bun.sh/#')) {
            //capture the bun debugger
            this.bunDebugUrl = logMessage.message;
            return;
        }


        Object.defineProperty(logMessage, '_search', {
            value: this.generateLogMessageSearchValue(logMessage),
        });


        if (!this.tempNewItems.length) {
            setTimeout(() => {
                this.rawItems.unshift(...this.tempNewItems);
                this.dispatchEvent(new CustomEvent('new-raw-items', {detail: {newItems: this.tempNewItems}}));
                this.requestUpdate('rawItems');
                this.tempNewItems = [];
            }, 100);
        }


        this.tempNewItems.unshift(logMessage);
    }

    @observe('lastCleared')
    applyClearLogs() {
        this.rawItems = this.rawItems.filter(_ => {
            return _.timestamp >= this.lastCleared;
        });
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-dev-tools-emulator-log': ComponentDevToolsEmulatorLog;
    }
}
