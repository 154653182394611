import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-input';
import './components/component-input-array';
import './components/component-input-checkbox';
import './components/component-input-currency';
import './components/component-input-date';
import './components/component-input-datetime';
import './components/component-input-email';
import './components/component-input-location';
import './components/component-input-number';
import './components/component-input-password';
import './components/component-input-percentage';
import './components/component-input-phone';
import './components/component-input-quill';
import './components/component-input-radio-group';
import './components/component-input-radio-item';
import './components/component-input-select';
import './components/component-input-select-item';
import './components/component-input-select-multiple';
import './components/component-input-textarea';
import './components/component-native-form';
import './components/component-input-surreal-select';
import './components/component-input-surreal-select-multiple';
import {InputsPackage} from '../shared';


export class InputsLocalPackage extends InputsPackage implements LocalPackage {
}