import {customElement} from 'lit/decorators.js';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {
    FIRESTORE_COLLECTION_SHOP_CARTS,
    ShopCartDocument,
    ShopProductDocument,
} from '../../shared/helpers/FirebaseHelper';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {storageBoundLocalStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper.ts';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper.ts';

const CLAIMING_CART_PROMISES: { [key: string]: Promise<void> } = {};
const internalCartCall = async (cartId: string, action: string, args: any) => {
    let auth = Auth.getInstance().getIdToken() as string;
    let response = await (await fetch(`/_/cartcall`, {
        headers: {
            ...(auth ? {'X-Auth': auth as string} : undefined),
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSONStringify({
            cartId: cartId,
            action: action,
            args: args,
        }),
    })).json();
    if (response.status === 'error') {
        let responseError = new FriendlyMessage(response._messages[0]);
        (responseError as any)._response = response;
        throw responseError;
    }

    return response;
};

let instance: ComponentShopCartInternal;

@customElement('component-shop-cart-internal')
export class ComponentShopCartInternal extends BunnyElement {
    static getInstance() {
        return instance;
    }

    @property({type: String, notify: true})
    @storageBoundLocalStorage('shopCartId')
    cartId: string | false;

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Object, notify: true})
    @computed('cartId')
    get cart() {
        if (!this.cartId) return undefined;

        return new SurrealDocument<ShopCartDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_CARTS}/${this.cartId}`],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: Number})
    pendingCartUpdates: number = 0;

    @property({type: Boolean, notify: true})
    @computed('pendingCartUpdates')
    get pendingCartUpdate() {
        return this.pendingCartUpdates > 0;
    }

    static override styles = [
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return null;
    }

    connectedCallback() {
        super.connectedCallback();

        instance = this;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        instance = undefined as any;
    }

    @listen('logout', window)
    _onLogout() {
        this.cartId = false;
    }

    async addDiscount(handler: string, meta: any) {
        await showToast('Applying discount');

        try {
            await this.modifyDiscountCode('add', {handler: handler, meta: meta});

            await showToast('Applied discount', {autoDismiss: 6000});

        } catch (e: any) {
            await showToast(`Failed applying discount: ${e.message}`, {autoDismiss: 6000});
        }
    }

    async removeDiscount(discount: any) {
        await showToast('Removing discount');


        try {
            await this.modifyDiscountCode('remove', {discount: discount.id.id});

            await showToast('Removed discount', {autoDismiss: 6000});

        } catch (e: any) {
            await showToast(`Failed removing discount: ${e.message}`, {autoDismiss: 6000});
        }
    }

    async modifyDiscountCode(action: 'add' | 'remove', data: any) {
        this.dispatchEvent(new CustomEvent('shop-cart-discount-updated', {
            bubbles: true,
            detail: {
                processing: true,
                action: action,
                data: data,
            },
            composed: true,
        }));

        try {
            if (this.cart?.data && !this.cart.data.owner) {
                await this.claimAnonymousCart();
            }


            await this._cartCall('modifyDiscount', {
                action: action,
                data: data,
            });


            this.dispatchEvent(new CustomEvent('shop-cart-discount-updated', {
                bubbles: true,
                detail: {
                    processing: false,
                    action: action,
                    data: data,
                },
                composed: true,
            }));

        } catch (e) {
            this.dispatchEvent(new CustomEvent('shop-cart-discount-updated', {
                bubbles: true,
                detail: {
                    processing: false,
                    error: true,
                    action: action,
                    data: data,
                },
                composed: true,
            }));

            throw e;
        }
    }


    async addItemToCart(product: any, productVariant: any, quantity: number = 1) {
        try {
            let minWaitDelay = delayPromise(250);

            this.dispatchEvent(new CustomEvent('shop-cart-updated', {
                bubbles: true,
                detail: {
                    action: 'product-adding',
                    product: product,
                    variant: productVariant,
                    quantity: quantity,
                },
                composed: true,
            }));


            await this.modifyCartItemQuantity(productVariant, quantity);

            await minWaitDelay;

            this.dispatchEvent(new CustomEvent('shop-cart-updated', {
                bubbles: true,
                detail: {
                    action: 'product-added',
                    product: product,
                    variant: productVariant,
                    quantity: quantity,
                },
                composed: true,
            }));

        } catch (e) {
            this.dispatchEvent(new CustomEvent('shop-cart-updated', {
                bubbles: true,
                detail: {
                    action: 'product-added-error',
                    error: e,
                    product: product,
                    variant: productVariant,
                    quantity: quantity,
                },
                composed: true,
            }));
        }
    }

    async setCartItemQuantity(productVariant: any, quantity: number = 1) {
        await showToast('Updating quantity');

        try {
            await this.modifyCartItemQuantity(productVariant, quantity, true);

            await showToast('Quantity updated', {autoDismiss: 6000});
            return true;

        } catch (e: any) {
            await showToast(`Failed updating quantity: ${e.message}`, {autoDismiss: 6000});
            return false;
        }
    }

    async removeFromCart(productVariant: any, currentQuantity: number) {
        await showToast('Removing from cart');

        try {
            await this.modifyCartItemQuantity(productVariant, 0, true);

            await showToast(html`
                <div @click="${() => this.setCartItemQuantity(productVariant, currentQuantity)}">
                    Removed from cart, <span style="color: #84ba22">undo?</span>
                </div>
            `, {autoDismiss: 6000});

        } catch (e: any) {
            await showToast(`Failed removing from cart: ${e.message}`, {autoDismiss: 6000});
        }
    }

    public waitForPendingModifies() {
        return (Promise as any).allSettled(this.pendingCartCalls);
    }

    private pendingCartCalls: Set<Promise<any>> = new Set<Promise<any>>();

    private async _cartCall(action: string, args: object) {
        const getCartUpdatedTime = () => {
            return this.cart?.data?.updated?.getTime();
        };
        let promise = new Promise(async (s, f) => {
            let lastUpdated = getCartUpdatedTime();

            try {
                let response = await internalCartCall(this.cartId, action, args);
                this.cartId = response._cartId;


                //Wait for the doc to actually update form the server before letting the promise complete
                let newUpdated = getCartUpdatedTime();
                let checksRemaining = 10000 / 15;
                while (newUpdated === lastUpdated && checksRemaining-- > 0) {
                    await delayPromise(15);
                    newUpdated = getCartUpdatedTime();
                }

                s(response);

            } catch (e: any) {
                if (typeof e?._response?._cartId === 'string') {
                    this.cartId = e._response._cartId;
                }

                f(e);
            }
        });


        this.pendingCartUpdates++;
        let caughtWrappedPromise = promise.catch(async _ => undefined);
        this.pendingCartCalls.add(caughtWrappedPromise);


        try {
            return await promise;

        } finally {
            this.pendingCartUpdates--;
            this.pendingCartCalls.delete(caughtWrappedPromise);
        }
    }

    private async modifyCartItemQuantity(productVariant: any, quantity: number | string = 1, setQuantity = false) {
        quantity = parseInt(quantity as string);

        await this._cartCall('setQuantity', {
            productVariant: productVariant,
            quantity: quantity,
            setQuantity: setQuantity,
        });
    }

    @listen('shop-product-add', window)
    async onShopProductAdd(e: Event) {
        let detail = (e as CustomEvent).detail;

        await this.addItemToCart(detail.product, detail.variant, detail.quantity);


        track('shopAddToCart', {
            id: detail.product._ref.id,
            name: detail.product.name,
            quantity: detail.quantity,
            currency: 'GBP',
            value: detail.price,
            categories: (detail.product as ShopProductDocument).category?.map(_ => _.content),
            path: location.href.replace(location.origin, ''),
            image: (detail.product?.productImages || [])[0]?.media?.id,
            meta: {
                stock: detail.stockLock?.stock,
                maxStock: detail.variant?.maxStock,
                onSale: detail.isOnSale ? 1 : 0,
                inStock: detail.stockLock?.stock > 0 ? 1 : 0,
            } as any,
        });
    }

    @listen('shop-product-remove', window)
    async onShopProductRemove(e: Event) {
        let detail = (e as CustomEvent).detail;

        await this.removeFromCart(detail.variant, detail.currentQuantity);
    }

    @listen('shop-product-set-quantity', window)
    async onShopProductSetQuantity(e: Event) {
        let detail = (e as CustomEvent).detail;

        let callback = detail.callback;
        try {
            let success = await this.setCartItemQuantity(detail.variant, detail.quantity);
            if (callback) {
                callback(success);
            }

        } catch (e) {
            if (callback) {
                callback(false);
            }

            console.error('Failed updating quantity', e);
        }
    }

    @listen('shop-discount-add', window)
    async onShopDiscountAdd(e: Event) {
        let detail = (e as CustomEvent).detail;

        await this.addDiscount(detail.handler, detail.meta);
    }

    @listen('shop-discount-remove', window)
    async onShopDiscountRemove(e: Event) {
        let detail = (e as CustomEvent).detail;

        await this.removeDiscount(detail.discount);
    }

    async claimAnonymousCart() {
        let cartId = this.cartId;
        if (!cartId) return;
        if (!this.auth.user) return;

        if (!CLAIMING_CART_PROMISES[cartId]) {
            CLAIMING_CART_PROMISES[cartId] = this._claimAnonymousCart();
        }


        await CLAIMING_CART_PROMISES[cartId];
    }

    private async _claimAnonymousCart() {
        try {
            await this._cartCall('claimAnonymousCart', {});

        } catch (e: any) {
            await showToast(`Unable to claim anonymous cart: ${e.message}. Please try again or contact us if you are still having issues`);
            throw e;
        }
    }

    @observe('cart')
    async showCartMessages(cart?: SurrealDocument<ShopCartDocument>) {
        if ((cart?.data as any)?._metadata.fromCache) return;

        let messages = cart?.data?.messages;
        if (!messages?.length) return;


        await showToast(messages.join(', '), {heading: 'Cart updated'});
        await this._cartCall('dismissCartMessages', {});
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-cart-internal': ComponentShopCartInternal;
    }
}
