import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html, unsafeStatic} from 'lit/static-html.js';
import {PropertyValues} from 'lit';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {
    FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS,
    FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS_SUB_MESSAGES,
    PrizeClaimingThreadDocument,
    PrizeClaimingThreadMessageDocument,
    PrizeClaimingThreadMessageType,
} from '../../shared/helpers/FirestoreHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {GLOBAL_GRAPPYFILL} from '../../../../aspire-app';
import {repeat} from 'lit/directives/repeat.js';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {userCall} from '../../../auth/local/helpers/UserCallHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {ComponentInputSelect} from '../../../inputs/local/components/component-input-select.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';
import {RecordId} from 'surrealdb';
import Record from 'vite-plugin-mkcert/dist/mkcert/record';

let PENDING_MESSAGE_COUNT = 0;

const isInHours = () => {
    let now = new Date();

    return (now.getDay() >= 1 && now.getDay() <= 5 && now.getHours() >= 8 && now.getHours() <= 16);
};

@customElement('component-prize-claiming-messages')
class ComponentPrizeClaimingMessages extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    prizeClaimingId: string;

    @property({type: Array})
    @computed('prizeClaimingId')
    get prizeClaimingThread() {
        if (!this.prizeClaimingId) return;

        return new SurrealDocument<PrizeClaimingThreadDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS}/${this.prizeClaimingId}`],
            {
                method: FetchMethod.LIVE,
            },
        );
    }

    @property({type: Array})
    @computed('prizeClaimingId')
    get messages() {
        if (!this.prizeClaimingId) return;

        return new SurrealCollection<PrizeClaimingThreadMessageDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                `${FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS}/${this.prizeClaimingId}/${FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS_SUB_MESSAGES}`,
                {
                    orderBy: [{fieldPath: 'created', directionStr: 'asc'}],
                },
            ],
            {
                method: FetchMethod.LIVE,
            },
        );
    }

    @property({type: Array})
    sendingMessages: ({
        message: PrizeClaimingThreadMessageDocument,
        messageId: string;
        status?: string;
        sending?: boolean;
    })[] = [];

    @property({type: String})
    newMessage: string = '';

    @property({type: String})
    newMessageType: PrizeClaimingThreadMessageType = 'message';

    @property({type: String})
    winnerPicSrc = '';

    @property({type: Boolean})
    isAtBottom = true;

    @property({type: Number})
    lastScrolled = 0;

    @property({type: Boolean})
    @computed('auth')
    get isAdmin() {
        return RenderingHelper._accountHasPermission(this.auth.user, 'app.admin.aspirecompsPrizeClaiming');
    }

    @property({type: Boolean})
    @computed('isAdmin', 'prizeClaimingThread')
    get isClosed() {
        if (this.isAdmin) return false;

        let thread = this.prizeClaimingThread?.data as PrizeClaimingThreadDocument;
        if (!thread) return true;
        if (!thread.status) return false;


        return !!thread.status.complete;
    }

    @property({type: Array})
    @computed('isAdmin')
    get availableNewMessageTypes() {
        let types: PrizeClaimingThreadMessageType[] = [
            'message',
        ];

        if (this.isAdmin) {
            types.push('confirmAddressDetailsRequest', 'confirmBankDetailsRequest', 'winnerPicRequest', 'winnerQuoteRequest', 'close');
        }

        return types;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                padding: 25px !important;
                padding-bottom: 100px !important;
                padding-top: 175px !important;
                position: relative;
            }

            .item {
                & + .item {
                    margin-top: 75px;
                }

                &[data-sending-status] {
                    &:before {
                        content: '';
                        position: absolute;
                        inset: 0;
                        backdrop-filter: grayscale(1);
                    }

                    &:after {
                        content: attr(data-sending-status);
                        position: absolute;
                        left: 50%;
                        top: 100%;
                        transform: translateX(-50%);
                        padding: 3px;
                        font-size: 12px;
                        margin-top: 3px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: rgb(67, 69, 75);
                        border-radius: 15px;
                        font-weight: bold;
                        text-align: center;
                    }
                }

                &[data-sending-status~="Failed"] {
                    cursor: pointer;

                    &:after {
                        background: rgb(200, 69, 75);
                        left: 0;
                        right: 0;
                        transform: none;
                    }
                }
            }


            header {
                position: fixed;
                top: calc(137px - (58px * var(--mini-nav-percentage)));
                left: 0;
                right: 0;
                z-index: 1;
                --col-margin-scale: 0 !important;
                box-shadow: 70px -70px 0 70px white, rgba(32, 32, 32, .2) 0 0 7px;

                h1 {
                    margin: 0;
                    background: var(--primary-text-color);
                    color: white;
                    padding: 0 15px;
                }

                .outOfHoursNotice {
                    background: var(--attention-color);
                    color: white;
                    text-align: center;
                    padding: 5px;
                    line-height: 1.1;
                    font-size: 80%;
                }
            }

            footer {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                background: white;

                .footerContent {
                    background: white;
                    box-shadow: rgba(32, 32, 32, .2) 0 0 7px;
                    padding: 0;
                    margin: 0;
                    display: flex;

                    > * {
                        margin: 0;
                    }
                }
            }
        `,
    ];

    override render() {
        let availableNewMessageTypes = this.availableNewMessageTypes;

        return html`
            <header class="gridContainer">
                ${this.isAdmin ? html`
                    <div style="position: absolute; left: 0; right: 0; bottom: 100%; display: flex">
                        <div style="margin-top: auto; margin-bottom: auto">
                            <a href="/admin/auth/accounts/${this.prizeClaimingThread?.data?.owner.id}">Account</a>
                            &middot;
                            <a href="/admin/shop/orders?query=%5B%22AND%22%2C%5B%5B%22owner.id%22%2C%22%3D%3D%22%2C%22${this.prizeClaimingThread?.data?.owner.id}%22%2Cfalse%5D%5D%5D">Orders</a>
                            &middot;
                            <a href="/admin/shop/points/${this.prizeClaimingThread?.data?.owner.id}">Points</a>
                            &middot;
                            <a href="/admin/competitions/winners/${this.prizeClaimingThread?.data?.winner.id}">Winner</a>
                        </div>

                        <component-firestore-collection-list-item-cell-prize-winning-thread-status
                                .item="${this.prizeClaimingThread?.data}"
                                style="display: inline-block; margin-left: auto"></component-firestore-collection-list-item-cell-prize-winning-thread-status>
                    </div>
                ` : undefined}
                <h1 style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-left: 0!important;">
                    <a href="${this.isAdmin ? '/admin/prize-claiming?query=%5B"AND"%2C%5B%5B"status.complete.icon"%2C"%21%3D"%2C"complete"%2Cfalse%5D%5D%5D' : '/prize-claiming'}"
                       style="margin-right: 15px;">
                        <small>
                            <component-button
                                    style=" border-radius: 0; min-width: 0; transform: rotate(180deg); line-height: 1; vertical-align: top; --padding: 11px 10px 10px 10px">
                                ➜
                            </component-button>
                        </small>
                    </a>

                    <span style="vertical-align: top">
                        ${this.prizeClaimingThread?.data?.prizeName}
                    </span>
                </h1>
                ${!isInHours() ? html`
                    <div class="outOfHoursNotice">
                        <strong>The badgers are currently asleep</strong><br>
                        They should be awake between Mon-Fri, 8AM-4PM <br> and will reply to you ASAP!
                    </div>
                ` : undefined}
            </header>

            <component-loading .loading="${this.messages?.loading ?? true}">
                ${repeat(this.messages?.data || [], (item) => item._ref?.id as string, item => {
            if ((item as any)._metadata.hasPendingWrites) return;


            let messageTag = unsafeStatic(`component-prize-claiming-messages-${item.type.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)}`);

            return html`
                        <${messageTag} .item="${item}"
                                       class="item"
                                       @sendMessageRequest="${this.onSendMessageRequest}"
                                       @scrollMessagesRequest="${(_: CustomEvent) => this.scrollToBottom(true)}"
                                       .prizeClaimingThread="${this.prizeClaimingThread?.data}"
                                       .isAdmin="${this.isAdmin}">
                        </${messageTag}>
                    `;
        })}
                ${this.sendingMessages.map(item => {
            let messageTag = unsafeStatic(`component-prize-claiming-messages-${item.message.type.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)}`);

            return html`
                        <${messageTag} .item="${item.message}"
                                       class="item"
                                       data-sending-status="${item.status}"
                                       @click="${(_: MouseEvent) => this.tryMessageSend(item.messageId)}"
                                       .prizeClaimingThread="${this.prizeClaimingThread?.data}">
                        </${messageTag}>
                    `;
        })}
            </component-loading>

            ${!this.isClosed ? html`
                <footer class="gridContainer">
                    <div class="footerContent">
                        ${availableNewMessageTypes?.length > 1 ? html`
                            <component-input-select
                                    style="width: 100px; margin-right: 10px; height: 100%;"
                                    .dropDownDirection="${'up'}"
                                    .value="${this.bind.newMessageType}">
                                ${availableNewMessageTypes.map(_ => html`
                                    <component-input-select-item .value="${_}">${_}</component-input-select-item>
                                `)}
                            </component-input-select>
                        ` : undefined}
                        ${this.newMessageType === 'winnerPicRequest' ? html`
                            <component-input-select
                                    style="width: 100px; margin-right: 10px; height: 100%;"
                                    .dropDownDirection="${'up'}"
                                    .value="${this.bind.winnerPicSrc}">
                                <component-input-select-item .value="">None</component-input-select-item>
                                <component-input-select-item
                                        .value="${'/images/winnerPrizeClaiming/winner-cash-alternative.jpg'}">
                                    Cash alternative
                                </component-input-select-item>
                                <component-input-select-item
                                        .value="${'/images/winnerPrizeClaiming/winner-cash-cash.jpg'}">
                                    Cash
                                </component-input-select-item>
                                <component-input-select-item
                                        .value="${'/images/winnerPrizeClaiming/winner-cash-credit.jpg'}">
                                    Website credit
                                </component-input-select-item>
                            </component-input-select>
                        ` : undefined}
                        <component-input-textarea
                                style="overflow: hidden; flex: 1; --input-container-border: solid transparent 1px;"
                                placeholder="Write a message"
                                .value="${this.bind.newMessage}"
                                @keypress="${this.onMessageKeypress}"></component-input-textarea>
                        ${this.newMessage ? html`
                            <component-button
                                    style="vertical-align: top; margin-top: auto; margin-bottom: auto; margin-left: 10px; --padding: 0 10px; height: 100%; min-width: 0"
                                    @click="${this.doSend}">
                                Send
                            </component-button>
                        ` : undefined}
                    </div>
                </footer>
            ` : undefined}
        `;
    }


    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);

        this.onScroll();

        this.scrollToBottom(true);
    }

    override updated(changedProperties: PropertyValues) {
        super.updated(changedProperties);

        this.scrollToBottom();
    }

    private async scrollToBottom(forceScroll = false) {
        if (!forceScroll) {
            if (!this.isAtBottom) return;
            if (Date.now() - this.lastScrolled <= 250) return;
        }


        await delayPromise();

        if (scrollY < 150) {
            window.scrollTo({top: Number.MAX_SAFE_INTEGER});

        } else {
            window.scrollTo({top: Number.MAX_SAFE_INTEGER, behavior: 'smooth'});
        }
    }

    @listen('scroll', window)
    onScroll() {
        let scrollBottomRemaining = document.body.scrollHeight - (innerHeight + scrollY);
        let isAtBottom = scrollBottomRemaining < 150;
        this.lastScrolled = Date.now();

        if (this.isAtBottom !== isAtBottom) {
            this.isAtBottom = isAtBottom;
        }
    }

    onMessageKeypress(e: KeyboardEvent) {
        if (!e.shiftKey && ['Enter', 'NumpadEnter'].includes(e.code)) {
            this.doSend();

            e.preventDefault();
        }
    }

    onSendMessageRequest(e: CustomEvent) {
        let newMessageId = `pendingMessage-${PENDING_MESSAGE_COUNT++}`;
        this.sendingMessages.push({
            messageId: newMessageId,
            message: {
                owner: GLOBAL_GRAPPYFILL.account?._surrealId as any,
                ...e.detail.newMessage,
            },
        });
        this.tryMessageSend(newMessageId);
        this.scrollToBottom(true);
    }

    private async tryMessageSend(messageId: string) {
        let sendingMessage = this.sendingMessages.find(_ => _.messageId === messageId);
        if (!sendingMessage) return;
        if (sendingMessage.sending) return;


        try {
            sendingMessage.sending = true;
            sendingMessage.status = 'Sending...';
            this.requestUpdate('sendingMessages');

            await callableQuery('__internal::addDoc')(
                new RecordId(`${FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS}_${FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS_SUB_MESSAGES}`, {
                    id: '',
                    parent: new RecordId(FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS, this.prizeClaimingId),
                }),
                sendingMessage.message,
            );

            delete sendingMessage.status;


            let sendingMessageIndex = this.sendingMessages.findIndex(_ => _.messageId === messageId);
            this.sendingMessages.splice(sendingMessageIndex, 1);
            this.requestUpdate('sendingMessages');


        } catch (e: any) {
            sendingMessage.status = `Failed (${e.message}) - Click to try again. Still having issues? Please contact us at support@aspirecomps.co.uk`;
            sendingMessage.sending = false;
            this.requestUpdate('sendingMessages');
        }
    }

    doSend() {
        if (!this.newMessage) return;


        let messageMeta: Record<string, any> = {};
        if (this.newMessageType === 'confirmAddressDetailsRequest') {
            messageMeta.addressDetails = this.prizeClaimingThread?.data?.shippingAddress;
        }
        if (this.newMessageType === 'confirmBankDetailsRequest') {
            messageMeta.bankDetails = {
                accountName: `${this.prizeClaimingThread?.data?.shippingAddress.firstName} ${this.prizeClaimingThread?.data?.shippingAddress.lastName}`,
                accountNumber: '',
                sortCode: '',
            };
        }
        if (this.winnerPicSrc) {
            messageMeta.embeds = [
                {src: this.winnerPicSrc},
            ];
        }

        let newMessageId = `pendingMessage-${PENDING_MESSAGE_COUNT++}`;
        this.sendingMessages.push({
            messageId: newMessageId,
            message: {
                owner: GLOBAL_GRAPPYFILL.account?._surrealId as any,
                type: this.newMessageType,
                body: this.newMessage,
                meta: messageMeta,
            },
        });
        this.tryMessageSend(newMessageId);

        this.newMessage = '';
        this.winnerPicSrc = '';

        let newMessageSelectNode = this.shadowRoot?.querySelector<ComponentInputSelect>('.footerContent component-input-select');
        if (newMessageSelectNode) {
            newMessageSelectNode.selectingIndex = 0;
        }
        this.newMessageType = this.availableNewMessageTypes[0];

        this.scrollToBottom(true);
    }

    @observe('prizeClaimingThread')
    async markMessagesRead(prizeClaimingThread: SurrealDocument<PrizeClaimingThreadDocument>) {
        if (!prizeClaimingThread?.data) return;

        let viewerType: 'customer' | 'aspire' = prizeClaimingThread.data.owner.id === GLOBAL_GRAPPYFILL.account?._surrealId.id ? 'customer' : 'aspire';
        let unreadCount = (prizeClaimingThread.data.unreadCount || {})[viewerType] || 0;
        if (unreadCount <= 0) return;

        await userCall(`PrizeClaimingMarkRead`, {
            threadId: prizeClaimingThread.data._ref?.id,
        });
    }

    @observe('newMessageType')
    prepopulateDefaultMessageForType(newMessageType: string) {
        if (this.newMessage) return;

        if (newMessageType === 'close') {
            this.newMessage = 'This prize claiming thread is now closed, if you have any issues then dont hesitate to contact us at support@aspirecomps.co.uk';
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages': ComponentPrizeClaimingMessages;
    }
}