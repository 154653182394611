import {DateableDocument, RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';

export const FIRESTORE_COLLECTION_ACCOUNTS = 'accounts';
export const FIRESTORE_COLLECTION_ROLES = 'roles';


declare global {
    interface Collections {
        accounts: FirebaseFirestore.CollectionReference<AccountDocument>;
        roles: FirebaseFirestore.CollectionReference<RoleDocument>;
    }
}


export interface OwnableDocument {
    owner: FirestoreDocumentReference;
}


export interface AccountDocument extends RefferableDocument, DateableDocument {
    firstName?: string;
    lastName?: string;
    emailAddress: string;
    lastActive: Date;
    meta?: { [key: string]: any };
}


export interface RoleDocument extends RefferableDocument, DateableDocument {
    name: string;
    permissions: string[];
    defaultRole?: boolean;
}

export interface AccountPermissionsDocument extends RefferableDocument {
    roles?: FirestoreDocumentReference[];
    extendedPermissions?: string[];
    permissions: string[];
    _forceRebuild?: Date;
    _accountId?: string;
    updated?: Date;
}

