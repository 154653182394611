import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ShopCartDocument} from '../../../shop/shared/helpers/FirebaseHelper';
import '../../../firebase-analytics/local/components/component-firebase-remote-config';
import '../../../shop/local/components/component-shop-price';
import {ComponentShopCartInternal} from '../../../shop/local/components/component-shop-cart-internal';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {FIRESTORE_COLLECTION_SHOP_POINTS, ShopPointDocument} from '../../shared/helpers/FirebaseHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';

@customElement('component-shop-points-cart-points-display')
class ComponentShopPointsCartPointsDisplay extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Object})
    @computed('auth')
    get shopPoints() {
        if (!this.auth.user?.uid) return undefined;

        return new SurrealDocument<ShopPointDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_POINTS}/${this.auth.user?.uid}`],
            {method: FetchMethod.LIVE});
    }

    @property({type: Object})
    cart: ShopCartDocument;

    @property({type: Number})
    @computed('cart')
    get cartTotal() {
        if (!this.cart) return -1;

        return this.cart.price?.total || 0;
    };

    @property({type: Object})
    pointsConfig: any;

    @property({type: Number})
    @computed('pointsConfig', 'cartTotal')
    get pointsToEarn() {
        if (!this.pointsConfig) return -1;

        return Math.floor((this.cartTotal / 100) * this.pointsConfig.earn.spend100);
    }

    @property({type: Number})
    @computed('cart')
    get hasAspirePointsApplied() {
        if (!this.cart) return false;

        //if theres already a aspire points discount, then dont offer any
        return !!this.cart.discounts && this.cart.discounts.find(_ => _.handler === 'ShopPoints');
    }

    @property({type: Number})
    useAspirePointsDiscount: number = -1;

    @computed('shopPoints', 'cart', 'hasAspirePointsApplied')
    get maxAspirePointsDiscount() {
        if (!this.shopPoints?.data && this.shopPoints?.loading) return -1;
        if (!this.shopPoints?.data) return 0;
        if (!this.cart) return -1;

        //if theres already a aspire points discount, then dont offer any
        if (this.hasAspirePointsApplied) return 0;


        return Math.min(this.shopPoints.data.points, this.cart.price?.subTotal || 0);
    }

    @property({type: Number})
    @computed('useAspirePointsDiscount', 'pointsConfig')
    get useAspirePointsDiscountCurrency() {
        if (!this.pointsConfig) return -1;

        return this.useAspirePointsDiscount / this.pointsConfig.redeem.pointsPerPrice;
    }

    @property({type: Boolean})
    @computed('useAspirePointsDiscountCurrency', 'useAspirePointsDiscount', 'pointsToEarn', 'cartTotal', 'pendingCartUpdate')
    get loading() {
        if (this.pendingCartUpdate) return true;

        return [this.useAspirePointsDiscountCurrency, this.useAspirePointsDiscount, this.pointsToEarn, this.cartTotal].indexOf(-1) !== -1;
    }

    @property({type: Boolean})
    pendingCartUpdate: boolean = false;

    @property({type: Object})
    shopCartInternal = ComponentShopCartInternal.getInstance();

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .contentStep {
                padding: 0;
                padding-bottom: 1px;
            }

            h3 {
                background: var(--primary-text-color);
                color: white;
                padding: 15px;
                margin-bottom: 0;
            }

            p {
                padding: 0 15px;
                line-height: 1.2;
                margin-top: 15px;
                margin-bottom: 0;
            }

            p ~ p {
                border-top: solid rgba(32, 32, 32, .3) 1px;
            }

            component-input-number {
                --input-container-border-radius: 0;

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input {
                    padding: 8px 0;
                    -moz-appearance: textfield;
                }
            }
        `,
    ];

    override render() {
        return html`
            <component-firebase-remote-config key="shopPoints"
                                              .value="${this.bind.pointsConfig}"></component-firebase-remote-config>

            <div class="contentStep">
                <h3 style="position:relative;">
                    <span>Aspire points</span>

                    ${this.hasAspirePointsApplied ? html`
                        <component-button
                                style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); margin: 0; font-size: 18px; max-width: 107px; line-height: 1.1; text-align: right; --padding: 3px 7px; color: var(--primary-text-color); background: rgb(234, 234, 234); pointer-events: none">
                            Discount applied
                        </component-button>
                    ` : html`
                        <component-loading .loading="${this.loading}"
                                           style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%)"></component-loading>
                    `}
                </h3>
                <component-loading .loading="${this.loading}">
                    ${this.pointsToEarn ? html`
                        <p style="margin-bottom: 15px">
                            Complete your order and earn
                            <a href="/account/shop/points#points-history" style="font-weight: bolder">
                                ${this.pointsToEarn} Aspire Points
                            </a>
                            for a discount on a future purchase
                        </p>
                    ` : undefined}
                    ${this.maxAspirePointsDiscount > 0 ? html`
                        <p style="display: flex; align-items: center; padding-right: 0">
                            <span style="margin-right: 10px">
                                Use 
                                <component-input-number .value="${this.bind.useAspirePointsDiscount}"
                                                        .max="${this.maxAspirePointsDiscount}"
                                                        .min="${1}"
                                                        class="borderBottomOnly"
                                                        style="width: ${this.calculateInputWidth(105, 9.5, `${this.maxAspirePointsDiscount}`)}; vertical-align: -12px; display: inline-block; margin-top: 0">
                                    <span slot="suffix" style="width: 104px; font-weight: bold;">Aspire Points</span>
                                </component-input-number>
                                for a
                                <component-shop-price
                                        style="font-weight: bolder; display: inline; margin-left: 3px; margin-right: 3px"
                                        .value="${this.useAspirePointsDiscountCurrency}"></component-shop-price>
                                discount on this order!
                            </span>

                            <component-button
                                    style="background-color: var(--primary-color); border-radius: 0;  color: white; margin-left: auto; margin-right: 0; text-align: center; min-width: 97px"
                                    @click="${this.applyDiscount}">
                                Apply discount
                            </component-button>
                        </p>
                    ` : undefined}
                </component-loading>
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.shopCartInternal?.addEventListener('pending-cart-update-changed', this.reflectPendingCartUpdated);
        this.shopCartInternal?.addEventListener('cart-changed', this.reflectCart);
        this.pendingCartUpdate = this.shopCartInternal.pendingCartUpdate;
        this.cart = this.shopCartInternal.cart?.data as ShopCartDocument;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.shopCartInternal?.removeEventListener('pending-cart-update-changed', this.reflectPendingCartUpdated);
        this.shopCartInternal?.removeEventListener('cart-changed', this.reflectCart);
    }

    @bind()
    reflectPendingCartUpdated() {
        this.pendingCartUpdate = this.shopCartInternal.pendingCartUpdate;
    }

    @bind()
    reflectCart() {
        this.cart = this.shopCartInternal.cart?.data as ShopCartDocument;
    }

    applyDiscount() {
        this.dispatchEvent(new CustomEvent('shop-discount-add', {
            bubbles: true,
            detail: {handler: 'ShopPoints', meta: {points: this.useAspirePointsDiscount}},
            composed: true,
        }));
    }

    @observe('maxAspirePointsDiscount')
    async applyUseAspirePointsDiscount(maxAspirePointsDiscount: number) {
        await delayPromise();//TODO look into why this is needed(probably cus the number is applying the value after initial frame)
        this.useAspirePointsDiscount = maxAspirePointsDiscount;
    }

    calculateInputWidth(base: number, perCharacter: number, text: string) {
        return `${base + (perCharacter * text.length)}px`;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-points-cart-points-display': ComponentShopPointsCartPointsDisplay;
    }
}
