import LocalPackage from '../../__internal/local/LocalPackage.ts';
import {AspireCompsCommentPickerPackage} from '../shared';

import './components/component-promotional-draws-collection-list-item.ts';
import './components/component-promotional-draws-process-claim.ts';

export class AspireCompsCommentPickerLocalPackage extends AspireCompsCommentPickerPackage implements LocalPackage {
    constructor() {
        super();
    }
}