import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {sharedStyles} from '../shared-styles';
import {repeat} from 'lit/directives/repeat.js';
import {BunnyElement} from '../packages/__internal/local/components/bunny-element';
import {property} from '../packages/__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../packages/__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../packages/__internal/local/helpers/StyleHelper';
import {bind} from '../packages/__internal/local/helpers/decorators/BindDecoratorHelper';
import {observe} from '../packages/__internal/local/helpers/decorators/ObserveDecoratorHelper';


@customElement('component-list-view')
export class ComponentListView extends BunnyElement {

    private resizeObserver: ResizeObserver;

    @property({type: Array})
    items = [];

    @property({type: Array})
    @computed('items', 'visibleRange', 'itemsPerRow')
    get internalItems() {
        let from = this.visibleRange[0];
        let to = this.visibleRange[1];
        let itemsPerRow = this.itemsPerRow;

        return this.items?.slice(from, to)
            .map((_, index) => ({
                index: from + index,
                item: _,
                row: Math.floor((from + index) / itemsPerRow),
                column: (from + index) % itemsPerRow,
            }));
    }

    @property({type: Array})
    visibleRange = [0, 10];

    @property({type: Number})
    itemHeight: number = 0;

    @property({type: Number})
    itemWidth: number = 0;

    @property({type: Number})
    viewPortWidth = 0;

    @property({type: Number})
    @computed('itemWidth', 'viewPortWidth')
    get itemsPerRow() {
        return Math.floor(this.viewPortWidth / this.itemWidth) || 1;
    };

    @property({type: Number})
    @computed('itemsPerRow', 'items')
    get rowCount() {
        return Math.ceil(this.items?.length / this.itemsPerRow);
    };

    @property({type: Number})
    @computed('rowCount', 'itemHeight')
    get possibleScrollHeight() {
        return this.rowCount * this.itemHeight;
    };

    @property({type: String})
    injectedStyles: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                overflow: auto;
                position: relative;

                --possibleScrollHeight: 300px;
                --itemWidth: 0px;
                --itemHeight: 0px;
                --offset-x: 0px;
                --offset-y: 0px;
                height: 300px;
                max-height: 300px;

                &:after {
                    content: '';
                    width: 1px;
                    height: 1px;
                    position: absolute;
                    top: var(--possibleScrollHeight);
                }
            }

            :host > * {
                min-width: 230px;
                min-height: 100px;
                display: inline-block;
                position: absolute;
                top: calc((var(--y) * var(--itemHeight)) + var(--offset-y));
                left: calc((var(--x) * var(--itemWidth)) + var(--offset-x));
            }

        `,
    ];

    renderItem(item: any, index: number, column: number, row: number) {
        return html`
            <div style="--x: ${column}; --y: ${row}">
                ${index}(${column}-${row}) <br>
                ${JSON.stringify(item)}
            </div>
        `;
    }

    override render() {
        let renderItems = this.internalItems || [{
            index: 0,
            item: (this.items || [])[0],
            column: 0,
            row: 0,
        }];

        return html`
            ${repeat(renderItems, (_) => _.index, _ => this.renderItem(_.item, _.index, _.column, _.row))}

            ${this.injectedStyles ? html`
                <style style="display: none">${this.injectedStyles}</style>
            ` : undefined}
        `;
    }

    constructor() {
        super();

        this.addEventListener('scroll', this.onScroll);

        let resizeDebounceRequestAnimationFrameId;
        this.resizeObserver = new ResizeObserver(() => {
            cancelAnimationFrame(resizeDebounceRequestAnimationFrameId);
            resizeDebounceRequestAnimationFrameId = requestAnimationFrame(() => {
                this.updateViewportWidth();
                this.onScroll();
            });
        });
    }

    connectedCallback() {
        super.connectedCallback();

        this.resizeObserver.observe(this);
        this.updateViewportWidth();
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.resizeObserver.unobserve(this);
    }

    @observe('items')
    onFirstRenderOfItems(items: any[]) {
        if (!items?.length) return;

        let firstChild = this.shadowRoot?.firstElementChild;
        this.itemWidth = firstChild?.clientWidth || this.itemWidth || 0;
        this.itemHeight = firstChild?.clientHeight || this.itemHeight || 0;

        requestAnimationFrame(() => {
            this.updateVisibleItems();
        });
    }

    updateViewportWidth() {
        this.viewPortWidth = this.clientWidth;
    }

    @bind()
    onScroll() {
        this.updateVisibleItems();
    }

    updateVisibleItems() {
        let scrollTop = this.scrollTop;
        let scrollBottom = scrollTop + this.clientHeight;

        let rowTop = Math.floor(scrollTop / this.itemHeight);
        let rowBottom = Math.ceil(scrollBottom / this.itemHeight);

        let firstItem = rowTop * this.itemsPerRow;
        let lastItem = rowBottom * this.itemsPerRow;

        this.visibleRange = [firstItem, lastItem];
    }

    @observe('possibleScrollHeight', 'itemWidth', 'itemHeight', 'viewPortWidth', 'itemsPerRow')
    reflectToCssProperty(possibleScrollHeight: number, itemWidth: number, itemHeight: number, viewPortWidth: number, itemsPerRow: number) {
        this.style.setProperty('--possibleScrollHeight', `${possibleScrollHeight}px`);
        this.style.setProperty('--itemWidth', `${itemWidth}px`);
        this.style.setProperty('--itemHeight', `${itemHeight}px`);

        this.style.setProperty('--offset-x', `${(viewPortWidth - (itemsPerRow * itemWidth)) / 2}px`);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-list-view': ComponentListView;
    }
}