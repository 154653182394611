import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {Auth} from '../../../auth/local/controllers/Auth.ts';

@customElement('component-notifications-do-action')
class ComponentNotificationsDoAction extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    @computed('route')
    get notificationId() {
        return this.route.current.query.notificationId;
    }

    @property({type: String})
    @computed('route')
    get accountId() {
        return this.route.current.query.accountId;
    }

    @property({type: String})
    @computed('route')
    get action() {
        return this.route.current.query.action;
    }

    @property({type: String})
    @computed('route')
    get authlessActionSignature() {
        return this.route.current.query.authlessActionSignature;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <h1>Actioning: ${this.action}</h1>
        `;
    }


    @toastProgressWrapper({
        failedMessage: 'Failed applying action to notification, please try again: {{e}}',
        progressMessage: 'Actioning',
        successMessage: 'Actioned',
    })
    @observe('accountId', 'notificationId', 'action', 'authlessActionSignature')
    async doAction(accountId: string, notificationId: string, action: string, authlessActionSignature: string) {
        if (!accountId || !notificationId || !action || !authlessActionSignature) return;


        let auth = Auth.getInstance().getIdToken() as string;
        let responseData = await (await fetch('/_/notifications-DoAction', {
            headers: {
                ...(auth ? {'X-Auth': auth as string} : undefined),
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                notificationId: notificationId,
                accountId: accountId,
                action: action,
                authlessActionSignature: authlessActionSignature,
            }),
        })).json();


        if (responseData.action === 'redirect') {
            HistoryHelper.redirect(responseData.url);
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-do-action': ComponentNotificationsDoAction;
    }
}