import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {CompetitionDocument, FIRESTORE_COLLECTION_COMPETITIONS} from '../../shared/helpers/FirestoreHelper';
import {MediaReferenceField} from '../../../media/shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {ShopProductDocument} from '../../../shop/shared/helpers/FirebaseHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';

@customElement('component-aspire-comps-livestream-up-next')
class ComponentAspireCompsLivestreamUpNext extends BunnyElement {

    @property({type: Object, notify: true})
    @computed('competitions', 'currentIndex')
    get current(): CompetitionDocument | undefined {
        return (this.competitions?.data || [])[this.currentIndex];
    };

    set current(_ignore: any) {
        //IGNORE its to make lit happy
    }

    @property({type: Object, notify: true})
    @computed('competitions', 'currentIndex')
    get nextUp(): CompetitionDocument | undefined {
        return (this.competitions?.data || [])[this.currentIndex + 1];
    };

    set nextUp(_ignore: any) {
        //IGNORE its to make lit happy
    }

    @property({type: String})
    displayWinnerTickets: string = '';

    @property({type: Object})
    competitions = new SurrealCollection<CompetitionDocument>(
        this,
        '__internal::loadFirestoreCollection',
        [
            FIRESTORE_COLLECTION_COMPETITIONS,
            {
                where: [{fieldPath: 'state', opStr: '==', value: 'live'}],
                orderBy: [{fieldPath: 'sort', directionStr: 'asc'}],
            },
        ],
        {
            method: FetchMethod.LIVE,
        },
    );

    @property({type: Number})
    @computed('competitions')
    get wantedCurrentIndex() {
        let competitions = this.competitions.data;
        if (!competitions?.length) return -1;


        let lastFinished = -1;
        for (let i in competitions) {
            if (!competitions[i].finished) break;

            lastFinished = parseInt(i, 10);
        }

        return lastFinished + 1;
    };

    @property({type: Number})
    currentIndex: number;

    @property({type: Boolean})
    visible: boolean = false;

    @property({type: Object})
    @computed('current')
    get competitionProduct() {
        let shopProductPath = this.current?.shopProduct?.path;
        if (!shopProductPath) return undefined;

        return new SurrealDocument<ShopProductDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [shopProductPath],
            {method: FetchMethod.LIVE},
        );
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                overflow: hidden;
                padding-bottom: 8px;
                padding-left: 50px;
                padding-right: 50px;
            }

            @keyframes show {
                0% {
                    clip-path: polygon(-5% 0%, -0.05% 0%, -0.05% 100%, -5% 100%);
                }
                60% {
                    clip-path: polygon(-5% 0%, -0.05% 0%, -0.05% 100%, -5% 100%);
                }
                100% {
                    clip-path: polygon(-5% 0%, 120% 0%, 120% 100%, -5% 100%);
                }
            }

            .competitionBanner {
                position: relative;
                font-size: 25px;
                color: white;
                border-bottom: solid var(--attention-color) .2em;
                transform: skewX(-10deg);
                width: 50em;
                max-width: 100%;
                clip-path: polygon(-5% 0%, 120% 0%, 120% 100%, -5% 100%);
                animation: show 1s linear;
                margin: auto;
            }

            @keyframes backBar {
                0% {
                    top: 50%;
                    bottom: 50%
                }
                100% {
                    top: 0;
                    bottom: -.2em
                }
            }

            .competitionBanner:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: -.2em;
                left: -.5em;
                width: .5em;
                background-color: var(--attention-color);
                animation: backBar .25s cubic-bezier(0.35, 0.05, 0.46, 1.1);
            }


            @keyframes backBarPre {
                from {
                    top: 50%;
                    bottom: 50%;
                    left: -.7em;
                }
                80% {
                    top: 5%;
                    bottom: 5%;
                    left: -.7em;
                }
                to {
                    top: 5%;
                    bottom: 5%;
                    left: -1em;
                }
            }

            .competitionBanner:after {
                content: '';
                position: absolute;
                top: 5%;
                bottom: 5%;
                left: -1em;
                width: .25em;
                background-color: var(--attention-color);
                animation: backBarPre .5s cubic-bezier(0.35, 0.05, 0.46, 1.1);
            }

            .competitionBanner .unslope {
                transform: skewX(10deg);
            }

            .competitionBanner .current {
                background-color: var(--primary-color);
                margin-right: 5em;
                padding: .25em;
                font-weight: bold;
                padding-left: 1em;
                padding-right: 5em;
                text-transform: uppercase;
            }

            .competitionBanner .current .info {
                font-size: .75em;
            }

            .competitionBanner .upNext {
                background-color: #80a42d;
                margin-right: 8em;
                font-size: .75em;
                padding: 0.25em;
                padding-left: 1em;
                padding-right: 3em;
                color: #c9d7b0;
            }

            .competitionBanner .img {
                position: absolute;
                top: 0;
                right: .5em;
                bottom: 0;
                width: 185px;
            }

            .competitionBanner .img .unslope {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                overflow: hidden;
                background-color: #666;
                border-top-right-radius: 15px;
            }

            .competitionBanner .img .unslope .innerImg {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }


            @keyframes winnerSection {
                0% {
                    background-position-x: 0%
                }
                100% {
                    background-position-x: -200%
                }
            }

            @keyframes spin {
                0% {
                    /*transform: skewX(0deg);*/
                    transform: rotateX(0deg) skewX(0deg);
                    text-shadow: 0 0 0 transparent;
                }
                50% {
                    /*transform: skewX(180deg);*/
                    text-shadow: 0 15px 1px rgba(32, 32, 32, .5);
                    transform: rotateX(90deg) skewX(70deg);
                }
                50.1% {
                    /*transform: skewX(180deg);*/
                    text-shadow: 0 15px 1px rgba(32, 32, 32, .5);
                    transform: rotateX(90deg) skewX(140deg);
                }
                100% {
                    /*transform: skewX(180deg);*/
                    transform: rotateX(0deg) skewX(180deg);
                    text-shadow: 0 0 0 transparent;
                }
            }

            .competitionBanner .winnerSection {
                font-size: 1.5em;
                display: inline-block;

                background: linear-gradient(90deg, #33235b 0%, #D62229 25%, #E97639 50%, #792042 75%, #33235b 100%);
                background-size: 200%;
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                animation: winnerSection 2s infinite linear;
            }

            .competitionBanner .info .spin.triggerAnimation {
                animation: spin .125s linear;
            }

            @keyframes star {
                0% {
                    opacity: 0;
                    transform: skewX(15deg) scale(0);
                }
                30% {
                    opacity: .05;
                    transform: skewX(15deg) scale(0);
                }
                40% {
                    opacity: .1;
                    transform: skewX(15deg) rotate(25deg) scale(1);
                }
                90% {
                    opacity: .15;
                    transform: skewX(15deg) rotate(-145deg) scale(1.2);
                }
                100% {
                    opacity: 0;
                    transform: skewX(15deg) rotate(-145deg) scale(0);
                }
            }

            .competitionBanner .star {
                background: url(/images/aspire-star.svg#white) no-repeat center center;
                background-size: cover;
                position: absolute;
                left: 0;
                right: 0;
                width: 40px;
                height: 40px;
                animation: star 25s infinite linear;
            }

            @keyframes vanSteal {
                0% {
                    opacity: 0;
                    transform: translateX(0vw);
                }
                10% {
                    opacity: 1;
                }
                30%, 40% {
                    transform: skewX(0deg) translateX(-27vw);
                }
                75% {
                    opacity: 1;
                }
                90% {
                    opacity: 0;
                }
                100% {
                    transform: skewX(-10deg) translateX(-110vw);
                    opacity: 0;
                }
            }


            @keyframes vanStealProduct {
                0% {
                    opacity: 0;
                }
                30% {
                    opacity: 0;
                    transform: scale(3.1);
                }
                40% {
                    opacity: 1;
                    transform: scale(1);
                }
                100% {
                    opacity: 1;
                }
            }

            @keyframes competitionBannerSteal {
                0% {
                }
                30% {
                    transform: skewX(-10deg) translateX(0);
                    opacity: 1;
                }
                31% {
                    transform: skewX(-10deg) translateX(-5px) translateY(-2px);
                    opacity: 1;
                }
                32% {
                    transform: skewX(-10deg) translateX(5px) translateY(-2px);
                    opacity: 1;
                }
                33% {
                    transform: skewX(-10deg) translateX(-5px) translateY(2px);
                    opacity: 1;
                }
                33% {
                    transform: skewX(-10deg) translateX(5px) translateY(-2px);
                    opacity: 1;
                }
                35% {
                    transform: skewX(-10deg) translateY(125%);
                    opacity: 0;
                }
                100% {
                    transform: skewX(-10deg) translateY(125%);
                    opacity: 0;
                }
            }

            .vanSteal {
                position: absolute;
                animation: vanSteal 4s 1 linear;
                bottom: -25px;
                left: 100%;
                will-change: transform;
            }

            .vanSteal component-media-view-image.product {
                width: 60px;
                max-width: none;
                position: absolute;
                bottom: 28px;
                left: 40px;
                animation: vanStealProduct 4s 1 linear;
                transform-origin: 10% 100%;
            }

            .vanSteal img.van {
                width: 100px;
                max-width: none;
                position: relative;
            }

            :host(.playOut) .competitionBanner {
                animation: competitionBannerSteal 4s 1 linear;
                animation-fill-mode: forwards;
            }

            :host(:not(.playOut)) .vanSteal {
                display: none;
            }
        `,
    ];

    renderProductThumbnail(productImages: MediaReferenceField[] | undefined, _class: string) {
        return html`
            <component-media-view-image
                    type="default-small-thumbnail"
                    class="${_class}"
                    .visible="${true}"
                    .media="${this._findThumbnail(productImages)}"></component-media-view-image>
        `;
    }

    override render() {
        return html`
            ${this.visible ? html`
                <div style="position:relative;">
                    <div class="competitionBanner">
                        <div class="current">
                            <div class="unslope">
                                <div style="overflow: hidden; height: 1.5em; line-height: 1.5em;">
                                    ${this.current?.name}
                                </div>

                                <div class="info" style="overflow: hidden; height: 3em; line-height: 3em;">
                                    <div class="spin">
                                        ${this.displayWinnerTickets ? html`
                                            <div class="winnerSection">
                                                ${this.displayWinnerTickets}
                                            </div>
                                        ` : html`
                                            Tickets 1 to ${this.current?.maxTicketNumber} &middot; Best of luck everyone
                                        `}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="img">
                            <div class="unslope">
                                ${this.renderProductThumbnail(this.competitionProduct?.data?.productImages, 'innerImg')}
                            </div>
                        </div>
                        <div class="upNext">
                            <div class="unslope" style="display: flex; height: 1.25em;">
                                ${this.nextUp ? html`
                                    <div style="height: 1em; overflow: hidden; padding-right: 50px">
                                        Next up &middot; ${this.nextUp.name}
                                    </div>
                                    <div style="margin-left: auto; flex-shrink: 0">
                                        ${this.currentIndex + 1} of ${this.competitions.data.length}
                                    </div>
                                ` : undefined}
                            </div>
                        </div>

                        <div class="star"
                             style="left: 33%; top: 21%; width: 20px; height: 20px; animation-delay: -1s"></div>
                        <div class="star"
                             style="left: 49%; top: 65%; width: 30px; height: 30px; animation-delay: -4s"></div>
                        <div class="star"
                             style="left: 70%; top: 22%; width: 40px; height: 40px; animation-delay: -6s"></div>
                        <div class="star"
                             style="left: 05%; top: 33%; width: 50px; height: 50px; animation-delay: -9s"></div>
                        <div class="star"
                             style="left: 40%; top: -20%; width: 60px; height: 60px; animation-delay: -13s"></div>
                    </div>

                    <div class="vanSteal">
                        ${this.renderProductThumbnail(this.competitionProduct?.data?.productImages, 'product')}
                        <img class="van" src="/images/aspire-van.svg">
                    </div>
                </div>
            ` : undefined}
        `;
    }

    constructor() {
        super();

        document.documentElement.classList.remove('initiallyHide');
        localStorage.hideDevTools = '1';
    }

    _findThumbnail(productImages: MediaReferenceField[] | undefined) {
        return (productImages || []).find(_ => _.usages?.includes('thumbnail'));
    }

    @observe('current')
    async updateWinner(current: CompetitionDocument | undefined) {
        if (!current) return;


        let container = this.shadowRoot?.querySelector('.competitionBanner .info .spin');
        let winningTickets = Object.values(current.winningTickets || {});
        let displayWinnerTickets = winningTickets.length ? `Winning ticket${winningTickets.length === 1 ? '' : 's'} ${winningTickets.map(_ => _.number).join(', ')}` : '';
        if (this.displayWinnerTickets === displayWinnerTickets) return;

        if (container) {
            container.classList.remove('triggerAnimation');
            await delayPromise(125 / 2);
        }

        this.displayWinnerTickets = displayWinnerTickets;

        if (container) {
            container.classList.add('triggerAnimation');
        }
    }


    _loadedCompetitionId: string | undefined;

    @observe('current')
    async show(current: CompetitionDocument | undefined) {
        if (this._loadedCompetitionId === current?._ref?.id) return;
        this._loadedCompetitionId = current?._ref?.id;

        this.visible = false;

        if (!current) return;

        await delayPromise();
        this.visible = true;
    }

    @observe('wantedCurrentIndex')
    async loadNext(wantedCurrentIndex: number) {
        if (this.current) {
            this.classList.add('playOut');
            await delayPromise(4000);

            this.classList.remove('playOut');
        }

        await delayPromise();
        this.currentIndex = wantedCurrentIndex;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-livestream-up-next': ComponentAspireCompsLivestreamUpNext;
    }
}