import {customElement} from 'lit/decorators.js';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {ComponentAnalyticsDashboardPanel} from './component-analytics-dashboard-panel.ts';

@customElement('component-analytics-dashboard-panel-value')
class ComponentAnalyticsDashboardPanelValue extends ComponentAnalyticsDashboardPanel {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    renderPanel() {
        return html`
            ${JSON.stringify(this.data)}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-dashboard-panel-value': ComponentAnalyticsDashboardPanelValue;
    }
}