import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import '../../../firebase-analytics/local/components/component-firebase-remote-config';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {FIRESTORE_COLLECTION_SHOP_POINTS, ShopPointDocument} from '../../shared/helpers/FirebaseHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {ComponentInput} from '../../../inputs/local/components/component-input';

@customElement('component-shop-points-panel')
class ComponentShopPointsPanel extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Object})
    pointsConfig: any;

    @property({type: Object, notify: true})
    @computed('auth')
    get shopPointsInternal() {
        if (!this.auth.user?.uid) return;

        return new SurrealDocument<ShopPointDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_POINTS}/${this.auth.user?.uid}`],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: Object, notify: true})
    @computed('shopPointsInternal')
    get shopPoints() {
        return this.shopPointsInternal?.data as ShopPointDocument | undefined;
    };

    set shopPoints(_ignore: any) {
        //TODO ignored cus of lit needing it from event binding
    }

    @property({type: Boolean})
    showButtons: boolean = true;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .gridContainer {
                text-align: center;
            }

            p {
                margin: 0;
            }

            .item {
                height: 70px;
                align-items: center;
                display: flex;
                text-align: left;
            }

            .item p {
                flex: 1;
            }

            component-icon {
                color: var(--primary-color);
                width: 40px;
                height: 40px;
                margin-right: 10px;
            }

            component-input {
                --primary-text-color: var(--attention-color);
            }

            @media screen and (min-width: 768px) {
                .pointDescriptors {
                    position: relative;
                }

                .pointDescriptors:before {
                    content: 'AND';
                    font-family: "proxima-nova", serif;
                    position: absolute;
                    top: 0px;
                    font-style: italic;
                    opacity: .8;
                    left: 100%;
                    transform: translateX(-50%);
                }

                .pointDescriptors:after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    bottom: 15px;
                    top: 50px;
                    left: 100%;
                    margin-left: -1px;
                    background: rgba(32, 32, 32, .1);
                    transform: translateX(-50%);
                }
            }
        `,
    ];

    override render() {
        let earn = this.pointsConfig?.earn;
        let pointsPerPrice = this.pointsConfig?.redeem?.pointsPerPrice;

        return html`
            <component-firebase-remote-config key="shopPoints"
                                              .value="${this.bind.pointsConfig}"></component-firebase-remote-config>


            <div class="gridContainer">
                <div style="--col-md: 6; margin-top: 25px" class="pointDescriptors">
                    <p style="font-size: 150%; text-align: center; width: 100%; font-family: 'proxima-nova', serif; margin-top: 0">
                        <strong>EARN</strong>
                    </p>

                    <div class="item">
                        <component-icon icon="app:users"></component-icon>
                        <p><strong>Refer others</strong> and get
                            <strong>${earn?.referral} aspire points</strong>
                            when they make a purchase!</p>
                    </div>
                    <div class="item">
                        <component-icon icon="app:ticket-alt"></component-icon>
                        <p><strong>Buy tickets</strong> and get
                            <strong>${earn?.spend100} aspire points</strong> for
                            every £1 spent!</p>
                    </div>
                </div>

                <div style="--col-md: 6; margin-top: 25px">
                    <p style="font-size: 150%; text-align: center; width: 100%; font-family: 'proxima-nova', serif; margin-top: 0">
                        <strong>REDEEM</strong>
                    </p>

                    <div class="item">
                        <component-icon icon="app:money-bill-wave"></component-icon>
                        <p><strong>Exchange</strong> your points to get a discount at checkout!</p>
                    </div>
                    <div class="item">
                        <component-icon icon="app:coins"></component-icon>
                        <p>Get <strong>£1</strong> for every
                            <strong>${pointsPerPrice * 100} points</strong>!</p>
                    </div>
                </div>


                <p style="--col: 6; background-color: var(--primary-text-color); color: white; padding: 10px 5px; margin-bottom: 0">
                    Use <strong>${pointsPerPrice * 500}</strong> points for a
                    <strong>£5</strong> discount
                </p>

                <p style="--col: 6; background-color: var(--primary-text-color); color: white; padding: 10px 5px; margin-bottom: 0">
                    Use <strong>${pointsPerPrice * 1000}</strong> points for a
                    <strong>£10</strong> discount
                </p>

                <div class="bottom" style="margin-top: 40px; margin-bottom: 40px">
                    <component-loading .loading="${this.shopPointsInternal?.loading ?? !!this.auth.user}">
                        <h3 style="font-size: 25px; margin-bottom: 0">
                            Your referral link
                        </h3>

                        ${this.auth.user ? html`
                            <component-input type="text"
                                             .value="${location.protocol}//${location.host}?referer=${this.auth.user?.uid}"
                                             readonly
                                             @focus="${this.copyText}"></component-input>

                            <p style="margin-top: 25px">
                                ${this.shopPoints?.points ? html`
                                    <span style="font-size: 25px; line-height: 1.2">
                                        You have <br>
                                        <span style="font-size: 125%">${this.shopPoints.points}</span> <br>
                                        points
                                    </span>
                                ` : html`
                                    You have no points yet, but by referring a friend you can get some quick points.
                                `}
                            </p>

                            ${this.showButtons ? html`
                                <div style="margin: 0 -15px">
                                    <a href="/account/shop/points#points-history" @click="${this.triggerLinkClicked}">
                                        <component-button style="margin: 25px 1px 1px 1px;">
                                            Points history
                                        </component-button>
                                    </a>
                                    <a href="/shop/products" @click="${this.triggerLinkClicked}">
                                        <component-button
                                                style="margin: 25px 1px 1px 1px; background: var(--attention-color)">
                                            View competitions
                                        </component-button>
                                    </a>
                                </div>

                                <div style="margin-top: 25px; font-size: 80%" class="contentContainer">
                                    <a href="/account/shop/points" @click="${this.triggerLinkClicked}">
                                        Learn more
                                    </a>
                                </div>
                            ` : undefined}
                        ` : html`
                            <p style="margin-top: 25px">
                                You need to be logged in to view your points/referral code
                            </p>

                            <a href="/login" @click="${this.triggerLinkClicked}">
                                <component-button style="margin-top: 25px">
                                    Login / Register
                                </component-button>
                            </a>

                            <div style="margin-top: 25px; font-size: 80%" class="contentContainer">
                                <a href="/account/shop/points" @click="${this.triggerLinkClicked}">
                                    Learn more
                                </a>
                            </div>
                        `}
                    </component-loading>
                </div>
            </div>
        `;
    }


    copyText(e: MouseEvent) {
        (e.currentTarget as ComponentInput).copyText();

        showToast('Referral link copied');
    }

    triggerLinkClicked() {
        this.dispatchEvent(new CustomEvent('link-clicked'));
    }

    @observe('shopPoints')
    triggerResizeOnShopPointsChanged(_shopPoints: any) {
        window.dispatchEvent(new CustomEvent('resize'));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-points-panel': ComponentShopPointsPanel;
    }
}