import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {
    FIRESTORE_COLLECTION_SHOP_PRODUCTS,
    FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS,
    ShopProductDocument,
    ShopProductVariantDocument,
} from '../../../shop/shared/helpers/FirebaseHelper';
import {storageBoundSessionStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {choose} from 'lit/directives/choose.js';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';

@customElement('component-aspire-comps-product-tab-competition-details')
class ComponentAspireCompsProductTabCompetitionDetails extends BunnyElement {

    @property({type: String})
    productId: string;

    @property({type: Object})
    @computed('productId')
    get product() {
        if (!this.productId) return undefined;

        return new SurrealDocument<ShopProductDocument>(this, '__internal::loadFirestoreDocument', [`${FIRESTORE_COLLECTION_SHOP_PRODUCTS}/${this.productId}`], {method: FetchMethod.CACHE_FIRST});
    };

    @property({type: String})
    @storageBoundSessionStorage('shop-product-selected-variant')
    selectedVariantDocPath: string;

    @property({type: Object, notify: true})
    @computed('productId', 'selectedVariantDocPath')
    get selectedVariant() {
        if (!this.productId) return undefined;
        if (!this.selectedVariantDocPath) return undefined;

        return new SurrealDocument<ShopProductVariantDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_PRODUCTS}/${this.productId}/${FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS}/${this.selectedVariantDocPath}`],
            {method: FetchMethod.CACHE_FIRST},
        );
    };

    @property({type: Object})
    @computed('product')
    get drawAt() {
        return this.product?.data?.drawDate;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let loading = !(this.product?.data && this.selectedVariant?.data);

        return html`
            <component-loading .loading="${loading}">
                ${!loading ? html`
                    <h1>How to enter</h1>
                    <p>
                        Choose how many tickets you want at the shopping cart, then submit payment
                        and receive email confirmation. Your ticket numbers will be assigned daily by 10pm and
                        will be posted on our website for full transparency (Name, Ticket number, Order number only).
                    </p>
                    <h1>Competition Information</h1>

                    ${choose(this.product?.data.type, [
                        ['autoDrawCompetition', () => html`
                            <ul style="list-style: disc" class="contentContainer">
                                <li>
                                    <strong>This is an auto draw!</strong>
                                </li>
                                <li>
                                    <strong>Max tickets</strong> &middot;
                                    ${this.selectedVariant?.data.maxStock}
                                </li>
                                <li>
                                    <strong>Sales close date</strong> &middot;
                                    ${RenderingHelper._dateFormat(this.product?.data.finished, 'EEEE do MMM \'at\' hh:mma')}
                                </li>
                                <li>
                                    <strong>Draw date</strong> &middot;
                                    ${RenderingHelper._dateFormat(this.drawAt, 'EEEE do MMM \'at\' hh:mma')} or as soon
                                    as
                                    tickets sell out!
                                </li>
                                <li>
                                    Guaranteed draw date regardless of ticket sales! No Rollovers – No Extensions!
                                </li>
                                <li>
                                    We will hold the draw automatically using the same method as the Google random
                                    number generator to determine the winner.
                                </li>

                                <li>
                                    By Entering the competition you are agreeing to our
                                    <a href="/terms-conditions/">terms and conditions</a>, Any Entries that do not
                                    comply
                                    with
                                    all of our <a href="/terms-conditions/">terms and conditions</a> will not be entered
                                    into
                                    the draw.
                                </li>

                                <li>
                                    For free entry route please see our Terms and Conditions.
                                </li>

                                <li>
                                    The best way to hear about all upcoming exciting new competitions is to
                                    Like and Follow our Facebook page Aspire competitions and if you want to spread the
                                    love
                                    and
                                    help our competitions sell out faster then please share our page.
                                </li>

                                <li>
                                    Early bird for 48 hours or 50% of sales.
                                </li>
                            </ul>
                        `],
                        ['competition', () => html`
                            <ul style="list-style: disc" class="contentContainer">
                                <li>
                                    <strong>Max tickets</strong> &middot;
                                    ${this.selectedVariant?.data.maxStock}
                                </li>
                                <li>
                                    <strong>Sales close date</strong> &middot;
                                    ${RenderingHelper._dateFormat(this.product?.data.finished, 'EEEE do MMM \'at\' hh:mma')}
                                </li>
                                <li>
                                    <strong>Draw date</strong> &middot;
                                    ${RenderingHelper._dateFormat(this.drawAt, 'EEEE do MMM \'at\' hh:mma')}
                                </li>
                                <li>
                                    Guaranteed draw date regardless of ticket sales! No Rollovers – No Extensions!
                                </li>
                                <li>
                                    We will hold the draw live on Facebook and YouTube using a Google random number
                                    generator to
                                    determine the winner.
                                </li>

                                <li>
                                    By Entering the competition you are agreeing to our
                                    <a href="/terms-conditions/">terms and conditions</a>, Any Entries that do not
                                    comply
                                    with
                                    all of our <a href="/terms-conditions/">terms and conditions</a> will not be entered
                                    into
                                    the draw.
                                </li>

                                <li>
                                    For free entry route please see our Terms and Conditions.
                                </li>

                                <li>
                                    All of our draws can be watched LIVE on our
                                    <a href="https://www.facebook.com/aspirecompetitions/live_videos">Facebook page</a>
                                    and
                                    <a href="https://www.youtube.com/c/AspireCompetitions">YouTube channel</a>.
                                </li>

                                <li>
                                    The best way to hear about all upcoming exciting new competitions is to
                                    Like and Follow our Facebook page Aspire competitions and if you want to spread the
                                    love
                                    and
                                    help our competitions sell out faster then please share our page.
                                </li>

                                <li>
                                    Early bird for 48 hours or 50% of sales.
                                </li>
                            </ul>
                        `],
                    ])}


                ` : ''}
            </component-loading>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-product-tab-competition-details': ComponentAspireCompsProductTabCompetitionDetails;
    }
}