import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-data-collection-list-item-cell-shop-product-variant')
class ComponentDataCollectionListItemCellShopProductVariant extends ComponentFirestoreCollectionListItemCell {

    @property({type: Object})
    @computed('field', 'item')
    get fieldValue(): any {
        return this.getContent(this.field, this.item);
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            ${Object.values(this.fieldValue || {}).map((item: any) => html`
                Price: ${this._currency(item.price)}<br>
                Stock: ${item.stockLock?.stock}
                ${item.stockLock?.stockLock ? html`
                    <span title="${item.stockLock.stockLock} Locked by pending transactions">
                        (-${item.stockLock.stockLock})
                    </span>
                ` : undefined}
                <br>
            `)}
        `;
    }

    _currency(value: number) {
        return new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(value / 100);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-shop-product-variant': ComponentDataCollectionListItemCellShopProductVariant;
    }
}