import {customElement} from 'lit/decorators.js';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {ComponentAnalyticsDashboardPanel} from './component-analytics-dashboard-panel.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';

@customElement('component-analytics-dashboard-panel-chart')
class ComponentAnalyticsDashboardPanelChart extends ComponentAnalyticsDashboardPanel {

    @property({type: Object})
    chartOptions: any = {};

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .gridContainer > * {
                --col: 1;
            }
        `,
    ];

    renderPanel() {
        let data = this.data.map((_: any) => ([
            new Date(_.created.replace(/;/g, ':')).getTime(),
            _.count,
        ]));

        return html`
            <component-highcharts .data="${{
                series: [
                    {
                        name: this.heading,
                        turboThreshold: 1,
                        boost: true,
                        data: data,
                    },
                ],
            }}"
                                  .chartOptions="${this.chartOptions}"></component-highcharts>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-dashboard-panel-chart': ComponentAnalyticsDashboardPanelChart;
    }
}