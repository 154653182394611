import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
// import {User} from 'firebase/auth';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {html} from 'lit';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {showToast} from '../../../__internal/local/helpers/ToastHelper.ts';

@customElement('component-login-alt')
class ComponentLoginAlt extends BunnyElement {

    //@ts-ignore
    auth = Auth.getInstance(this);

    @property({notify: true})
    route = Route.getInstance(this);

    private attempts = 0;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-button {
                background-color: white;
                margin: auto;
                color: var(--primary-text-color);

                component-icon {
                    margin-right: 5px;
                }

                @media screen and (max-width: 400px) {
                    & {
                        flex-direction: column;
                    }
                }
            }
        `,
    ];

    override render() {
        return html`
            <component-button @click="${(_: MouseEvent) => this.login('google')}">
                <component-icon icon="app:google"></component-icon>
                Google
            </component-button>
            <component-button @click="${(_: MouseEvent) => this.login('facebook')}">
                <component-icon icon="app:facebook"></component-icon>
                Facebook
            </component-button>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Logging in',
        successMessage: 'Logged in successfully',
        failedMessage: 'Failed logging in: {{e}}',
    })
    async login(altLoginMethod: 'google' | 'facebook') {
        this.attempts++;

        let htmlElementClassList = document.body.parentElement.classList;
        try {
            htmlElementClassList.add('blackout');
            let user = await this.auth.signInWithPopup(altLoginMethod);
            htmlElementClassList.remove('blackout');


            //TODO check this against timezones;
            let accountAge = Date.now() - user?.created?.getTime();
            let newAccount = accountAge < 30000;

            track(newAccount ? 'accountRegister' : 'accountLogin', {
                path: location.href.replace(location.origin, ''),
                method: altLoginMethod,
                attempts: this.attempts,
            });
            setTimeout(() => {
                showToast(`Welcome ${!newAccount ? 'back ' : ''}${user?.firstName}`, {autoDismiss: 2000});
            });

        } finally {
            htmlElementClassList.remove('blackout');
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-login-alt': ComponentLoginAlt;
    }
}