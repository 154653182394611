import {DateableDocument, RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {MediaReferenceField} from '../../../media/shared/helpers/FirebaseHelper';
import {ShopOrderAddress} from '../../../shop/shared/helpers/FirebaseHelper';

export const FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS = 'prizeClaimingThreads';
export const FIRESTORE_COLLECTION_PRIZE_CLAIMING_THREADS_SUB_MESSAGES = 'messages';


declare global {
    interface Collections {
        prizeClaimingThreads: FirebaseFirestore.CollectionReference<PrizeClaimingThreadDocument>;
    }
}

export interface PrizeClaimingThreadStatus {
    icon: string;
    iconStyle: string;
    textContent: string;
    updated: Date;
}

export interface PrizeClaimingThreadDocument extends RefferableDocument, DateableDocument {
    owner: FirestoreDocumentReference;
    prizeName: string;
    prizeImage?: MediaReferenceField | null;
    lastMessage?: string;
    lastMessaged?: Date;
    assignees?: FirestoreDocumentReference[];
    winner: FirestoreDocumentReference;
    shippingAddress: ShopOrderAddress;
    unreadCount?: {
        aspire: number;
        customer: number;
    };
    status?: {
        [key: string]: PrizeClaimingThreadStatus;
    };
}

export type PrizeClaimingThreadMessageType =
    'message'
    | 'confirmAddressDetailsRequest'
    | 'confirmAddressDetailsResponse'
    | 'deliverySent'
    | 'confirmBankDetailsRequest'
    | 'confirmBankDetailsResponse'
    | 'bankSent'
    | 'winnerPicRequest'
    | 'winnerPicResponse'
    | 'winnerQuoteRequest'
    | 'winnerQuoteResponse'
    | 'close';

export interface PrizeClaimingThreadMessageDocument extends RefferableDocument, DateableDocument {
    owner?: FirestoreDocumentReference;
    body: string;
    meta?: {
        [key: string]: any;
        attachments?: MediaReferenceField[];
        addressDetails?: ShopOrderAddress;
        bankDetails?: {
            accountName: string;
            accountNumber: string;
            sortCode: string;
        };
        embeds?: {
            src: string;
        }[]
    };
    type: PrizeClaimingThreadMessageType;
}