declare global {
    export interface Listeners {
        [key: string]: EventCallbackFunction<any> | undefined;
    }
}

export interface EventCallbackFunction<T> {
    (e: T): void;
}

export default abstract class Package {
    abstract name: string;
    abstract id: string;
    abstract dependencies: string[];
    listen?: Listeners;
}
