import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {CompetitionDocument} from '../../shared/helpers/FirestoreHelper';
import {
    FIRESTORE_COLLECTION_SHOP_ORDERS,
    ShopOrderDocument,
    ShopOrderItem,
} from '../../../shop/shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';

@customElement('component-aspire-comps-livestream-overview-item')
export class ComponentAspireCompsLivestreamOverviewItem extends ComponentFirestoreCollectionListItemCell {

    @property({type: Boolean})
    allowUnfinish: boolean = false;

    @property({type: Number, reflect: true})
    @computed('item')
    get winnersCount() {
        return this.item.winningTickets?.length || 0;
    }

    @property({type: Boolean, reflect: true})
    @computed('item')
    get finished() {
        return !!this.item.finished;
    }

    @property({type: Number})
    @computed('item', 'list')
    get index() {
        return this.list.items.indexOf(this.item);
    }

    @property({type: Array})
    winnerOrders: ShopOrderDocument[];

    @property({type: Boolean, reflect: true})
    isTouchDevice = navigator.maxTouchPoints > 0;

    @property({type: Boolean, reflect: true})
    forceShow: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                font-size: 18px;
                border-top: solid var(--primary-text-color) 3px;
                --square-color: var(--primary-text-color);
            }

            .title {
                background: var(--primary-color);
                display: flex;
                padding: 15px;
                margin-top: -1px;
                box-shadow: 0 0 10px rgba(64, 64, 64, .3);
            }

            .title h1 {
                margin-top: -15px;
                margin-left: -15px;
                margin-bottom: -15px;
                width: 60px;
                line-height: 60px;
                text-align: center;
                background: var(--square-color);
                border-right: solid var(--primary-text-color) 3px;
                color: white;
                margin-right: 15px;
            }

            .title h2 {
                flex: 1;
                margin: 0 auto;
                line-height: 1;
                color: white;
            }

            .title div {
                color: var(--attention-color);
                font-size: 150%;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 15px;
            }

            .content {
                padding: 15px;
                position: relative;
            }

            .content > div {
                margin-bottom: 15px;
            }

            :host([finished]) {
                --square-color: transparent;
            }

            :host([finished][is-touch-device]:not([force-show])) .content {
                display: none;
            }
        `,
    ];

    override render() {
        return html`
            <div class="title">
                <h1>
                    ${this.index + 1}
                </h1>
                <h2>
                    ${this.item.name}
                </h2>
                <div>
                    ${this.item.totalTickets}
                </div>

                ${this.finished && this.isTouchDevice ? html`
                    <component-button-toggle .active="${this.bind.forceShow}"
                                             style="margin-left: 15px; margin-top: auto; margin-bottom: auto; background-color: var(--primary-text-color); color: white; font-size: 70%">
                        ${this.forceShow ? 'Hide' : 'Show'}
                        </component-button->
                ` : undefined}
            </div>
            <div class="content">
                ${this.finished && this.allowUnfinish ? html`
                    <component-button @click="${this.unfinishCompetition}"
                                      style="position: absolute; top: 0; right: 0; background-color: var(--attention-color); color: white; margin: 0; font-size: 70%">
                        Unfinish
                    </component-button>
                ` : undefined}


                ${this.item?.winningTickets?.map((item: any, index: number) => {
                    let winnerOrder = this._getWinnerOrder(this.winnerOrders, index);

                    return html`
                        <div style="font-size: 23px; line-height: 1.5">
                            Ticket #${item.number} &middot; ${item.name} <small>(#${item.orderId})</small> &middot;
                            ${winnerOrder?.tickets} tickets <br>
                            <a href="tel: ${winnerOrder?.phoneNumber}">
                                ${winnerOrder?.phoneNumber}
                            </a> &middot; <a
                                href="mailto: ${winnerOrder?.emailAddress}">
                            ${winnerOrder?.emailAddress}
                        </a> &middot; ${winnerOrder?.location}
                        </div>
                    `;
                })}
                ${!this.winnersCount ? html`
                    <div style="opacity: .6;">
                        No winners yet
                    </div>
                ` : undefined}
            </div>
        `;
    }


    @observe('item')
    async loadWinnerOrders(competition: CompetitionDocument) {
        let winningTickets = competition.winningTickets;
        if (!winningTickets) return;

        let winnerOrdersUnsorted = (await callableQuery('__internal::loadFirestoreCollection')(
            FIRESTORE_COLLECTION_SHOP_ORDERS,
            {
                where: [{
                    fieldPath: 'orderNumber',
                    opStr: 'IN',
                    value: winningTickets.map(_ => _.orderId).filter(_ => _),
                }],
            },
        )).data.docs as any;

        this.winnerOrders = winningTickets.map(winningTicket => winnerOrdersUnsorted.find(_ => _.orderNumber === winningTicket.orderId));
    }

    _getWinnerOrder(winnerOrders: ShopOrderDocument[], index: number) {
        if (!winnerOrders) return undefined;

        let winnerOrder = winnerOrders[index];
        if (!winnerOrder) return undefined;

        let billingAddress = winnerOrder.billingAddress;
        return {
            phoneNumber: winnerOrder.contact?.phone,
            emailAddress: winnerOrder.contact?.emailAddress,
            location: [billingAddress.address1, billingAddress.address2, billingAddress.city, billingAddress.province, billingAddress.zip].filter(_ => _).join(' '),
            orderTickets: winnerOrder.itemsTotal.quantity,
            tickets: Object.values(winnerOrder.items as {
                [key: string]: ShopOrderItem
            }).find(_ => _.productVariant && _.productVariant?.path === (this.item as CompetitionDocument).shopVariant?.path)?.quantity,
        };
    }

    winnerPostText() {
        if (!this.item.winningTickets?.length) return ` 👉 ${this.item.name} · !!No Winners yet!!`;

        return ` 👉 ${this.item.name} · ${this.item.winningTickets.map((_: any) => `${_.name} with ticket #${_.number}`).join(', ')}`;
    }

    @toastProgressWrapper()
    async unfinishCompetition() {
        if (!this.item?._ref) throw new FriendlyMessage('No competition');
        if (!confirm(`Are you sure you want to finish this comp`)) return;

        await callableQuery('__internal::updateDoc')(this.item?._ref.surrealId, {
            finished: false,
        } as Partial<CompetitionDocument>);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-livestream-overview-item': ComponentAspireCompsLivestreamOverviewItem;
    }
}