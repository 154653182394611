import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {createComponent} from '../../../routing/local/helpers/DomHelper.ts';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';
import {
    AnalyticsDataDashboardDocument,
    AnalyticsDataPanelDocument,
    FIRESTORE_COLLECTION_ANALYTICS_DATA_DASHBOARDS,
} from '../../../../utils/DatabaseTypes.ts';
import {mergeDeep} from '../../../../helpers/ObjectHelper.ts';

@customElement('component-analytics-dashboard')
class ComponentAnalyticsDashboard extends BunnyElement {

    @property({type: String})
    dashboardId: string;

    @property({type: Object})
    @computed('dashboardId')
    get dashboard() {
        return new SurrealDocument<AnalyticsDataDashboardDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_ANALYTICS_DATA_DASHBOARDS}/${this.dashboardId}`],
            {method: FetchMethod.NETWORK_ONLY},
        );
    }


    @property({type: Object})
    @computed('dashboard')
    get rawPanels() {
        if (!this.dashboard.data) return;

        return new SurrealCollection<AnalyticsDataPanelDocument>(
            this,
            'analytics::fetchPanels',
            [
                this.dashboard.data.panels.map(_ => _.panel),
            ],
            {method: FetchMethod.NETWORK_ONLY},
        );
    }

    @property({type: Object})
    @computed('dashboard', 'rawPanels')
    get panels() {
        let rawPanelsData = this.rawPanels?.data;
        if (!rawPanelsData?.length) return;

        let dashboardPanels = this.dashboard.data.panels;
        for (let i in dashboardPanels) {
            mergeDeep(rawPanelsData[i].renderer.properties, dashboardPanels[i].properties);
        }

        return rawPanelsData;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <h1>${this.dashboard?.data?.name}</h1>

            <component-loading .loading="${this.rawPanels?.loading ?? true}">
                <div class="gridContainer row">
                    ${this.panels?.map(panel => createComponent(panel.renderer, {}))}
                </div>
            </component-loading>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-dashboard': ComponentAnalyticsDashboard;
    }
}