import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import './component-aspire-comps-tickets-renderer';
import {CompetitionDocument, FIRESTORE_COLLECTION_COMPETITIONS} from '../../shared/helpers/FirestoreHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {JSONParseLocal} from '../../../__internal/local/helpers/DataHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route.ts';

@customElement('component-aspire-comps-entry-list')
class ComponentAspireCompsEntryList extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Boolean})
    displayDrawDate: boolean = false;

    @property({type: String, notify: true})
    @storageBoundQueryString('competition')
    viewFullCompetition: string;

    @property({type: Array})
    @computed('displayDrawDate', 'myTicketsAll', 'myTicketsLoading')
    get fields() {
        return [
            {
                cellProperties: {
                    field: 'name',
                    type: 'text',
                },
                header: false,
                cell: {
                    component: 'component-aspire-comps-entry-list-item',
                    properties: {
                        displayDrawDate: this.displayDrawDate,
                        myTicketsAll: this.myTicketsAll,
                        myTicketsLoading: this.myTicketsLoading,
                    },
                },
            },
        ];
    }

    @property({type: Array})
    order = ['sort', 'asc'];

    @property({type: Array})
    filters = [];

    @property({type: Array})
    items: CompetitionDocument[] = [];

    @property({type: Object})
    @computed('items', 'viewFullCompetition')
    get viewFullCompetitionDetails() {
        if (!this.items) return undefined;

        return this.items.find(_ => _._ref?.id === this.viewFullCompetition);
    }

    @property({type: Boolean})
    shouldRenderFullCompetitionTickets: boolean = false;

    @property({type: String})
    fullCompetitionTicketSearch: string;

    @property({type: String})
    fullCompetitionTicketSearchDebounced: string;

    private fullCompetitionTicketSearchDebounceId: number;

    @property({type: Object})
    myTicketsAll: {
        [key: string]: any[]
    };

    @property({type: Boolean})
    myTicketsLoading: boolean = true;

    @property({type: Array})
    fullCompetitionTickets: any[];

    @property({type: Array})
    @computed('fullCompetitionTickets', 'fullCompetitionTicketSearchDebounced')
    get fullCompetitionTicketsFiltered() {
        this.shouldRenderFullCompetitionTickets = true;
        let search = (this.fullCompetitionTicketSearchDebounced || '').toLowerCase();

        this.shouldRenderFullCompetitionTickets = false;

        let ret = this.fullCompetitionTickets;
        if (search) {
            ret = this.fullCompetitionTickets.filter(_ => _.__search.includes(search));
        }


        ((window.requestAnimationFrame || setTimeout) as any)(() => {
            this.shouldRenderFullCompetitionTickets = true;
        }, 16);
        return ret;
    }

    @property({type: Number, reflect: true})
    ticketLoadingState: 'error' | 'waiting' | 'starting' | 'loadingFile' | 'parsingFile' | 'loadingTickets' | 'finishing' | 'loaded' = 'waiting';


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-dialog {
                --footer-display: none;
                --content-padding: 0;
                --width: 100vw;
            }
        `,
    ];

    override render() {
        //TODO add where for each version, live, past
        return html`
            <component-firestore-collection-list .items="${this.bind.items}"
                                                 .path="${FIRESTORE_COLLECTION_COMPETITIONS}"
                                                 .defaultOrder="${this.order}"
                                                 .filters="${this.filters}"
                                                 .fields="${this.fields || []}"
                                                 .fetchMethod="${'live'}"
                                                 .contentClass="${''}"
                                                 no-items-message="Competitions will be available on Wednesday at 5pm"></component-firestore-collection-list>

            ${this.viewFullCompetitionDetails ? html`
                <component-dialog opened
                                  @opened-changed="${(e: CustomEvent) => {
                                      if (e.detail.oldValue && !e.detail.value) {
                                          this.viewFullCompetition = '';
                                      }
                                  }}">
                    <h3 slot="heading">
                        ${this.generateViewFullCompetitionDetailsTitle(this.viewFullCompetitionDetails, this.fullCompetitionTickets)}
                    </h3>
                    <div style="margin-top: 0; padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px;">
                        <component-input label="Search" placeholder="by name, ticket or order #"
                                         .value="${this.bind.fullCompetitionTicketSearch}"></component-input>
                    </div>
                    ${this.shouldRenderFullCompetitionTickets ? html`
                        ${this.ticketLoadingState === 'loaded' ? html`
                            <component-aspire-comps-tickets-renderer .performanceMode="${true}"
                                                                     style="width: 100%; padding: 0; margin: 0"
                                                                     .items="${this.fullCompetitionTicketsFiltered}"
                                                                     .competitionId="${this.viewFullCompetition}"
                                                                     .noItemsMessage="Your search (${this.fullCompetitionTicketSearchDebounced}) has returned no matches, or those were some hungry badgers"
                                                                     .displayWinner="${this.canDisplayWinner(!!this.viewFullCompetitionDetails.finished, this.auth)}"></component-aspire-comps-tickets-renderer>

                        ` : html`
                            <div class="loadingProgress" style="padding: 15px; text-align: center">
                                ${this.ticketLoadingState === 'error' ? 'Failed loading tickets - Please check your internet connection and try again' : 'Loading tickets'}
                            </div>
                        `}
                    ` : html`
                        <div class="loadingProgress" style="padding: 15px; text-align: center">
                            ${this.ticketLoadingState === 'error' ? 'Failed loading tickets - Please check your internet connection and try again' : 'Loading tickets'}
                        </div>
                    `}
                </component-dialog>
            ` : undefined}
        `;
    }


    @observe('fullCompetitionTicketSearch')
    debounceFullCompetitionTicketSearch(fullCompetitionTicketSearch: string) {
        clearTimeout(this.fullCompetitionTicketSearchDebounceId);
        this.fullCompetitionTicketSearchDebounceId = window.setTimeout((_: any) => {
            this.fullCompetitionTicketSearchDebounced = fullCompetitionTicketSearch;
        }, 100);
    }

    generateViewFullCompetitionDetailsTitle(viewFullCompetitionDetails: CompetitionDocument, fullCompetitionTickets: any[]) {
        if (viewFullCompetitionDetails.maxTicketNumber) {
            return `Tickets 1 - ${viewFullCompetitionDetails.maxTicketNumber} for ${viewFullCompetitionDetails.name}`;
        }

        // TODO remove everything below this comment once old competitions updated to have maxTicketNumber
        let title = ` for ${viewFullCompetitionDetails.name}`;
        if (fullCompetitionTickets && fullCompetitionTickets[0]) {
            title = `Tickets ${fullCompetitionTickets[0].number} - ${fullCompetitionTickets[fullCompetitionTickets.length - 1].number} ${title}`;

        } else {
            title = `All ${viewFullCompetitionDetails.totalTickets} tickets ${title}`;
        }

        return title;
    }

    @observe('viewFullCompetitionDetails', 'fullCompetitionTickets')
    updateTitle(viewFullCompetitionDetails: any, fullCompetitionTickets: any[]) {
        let title = 'Entry list · Aspire competitions';
        if (viewFullCompetitionDetails) {
            title = `${this.generateViewFullCompetitionDetailsTitle(viewFullCompetitionDetails, fullCompetitionTickets)} · ${title}`;
        }

        document.title = title;
    }

    @observe('viewFullCompetitionDetails', 'fullCompetitionTickets')
    clearSearch(viewFullCompetitionDetails: any) {
        if (viewFullCompetitionDetails) return;

        this.fullCompetitionTicketSearch = '';
    }


    @observe('viewFullCompetitionDetails')
    async loadFlatTickets(competition: CompetitionDocument) {
        if (!competition) {
            this.ticketLoadingState = 'waiting';
            return;
        }


        let flatTicketsSrc = competition.flatTicketsSrc;
        if (!flatTicketsSrc) return;

        flatTicketsSrc = flatTicketsSrc.replace(/&#38;/g, '&');
        if (flatTicketsSrc.startsWith('https://staging.aspirecomps.co.uk')) {
            flatTicketsSrc = flatTicketsSrc.replace('https://staging.aspirecomps.co.uk', location.origin); //for staging urls it can be loaded from a different staging domain, used to patch away cors issues
        }

        console.log('flatTicketsSrc', flatTicketsSrc);

        if (!['error', 'waiting'].includes(this.ticketLoadingState)) return;


        try {
            this.ticketLoadingState = 'starting';
            await delayPromise();


            this.ticketLoadingState = 'loadingFile';
            let response = await fetch(flatTicketsSrc);


            this.ticketLoadingState = 'parsingFile';
            let data = await response.json();
            console.log('data', data);


            this.ticketLoadingState = 'loadingTickets';
            this.fullCompetitionTickets = data.tickets.map((_: any, index: number) => {
                if (_ === null) return null;

                let owner = data.owners[_];
                return {
                    number: index,
                    name: owner.name,
                    orderId: owner.order,
                    owner: '',//owner id stripped
                    __search: `${index} ${owner.name} ${owner.order}`.toLowerCase(),
                };
            })
                .filter((_: any) => _);

            this.ticketLoadingState = 'finishing';
            await delayPromise(175);


            this.ticketLoadingState = 'loaded';

        } catch (e) {
            this.ticketLoadingState = 'error';
            throw e;
        }
    }

    canDisplayWinner(finished: boolean, auth: Auth) {
        if (finished) return true;

        return RenderingHelper._accountHasPermission(auth.user, 'aspirecomps.competitions.viewActiveWinner');
    }

    private async loadMyTicketsChunk(auth: Auth, items: CompetitionDocument[]) {
        let myCompetitionsQueryString = items.map(_ => `competitions[${_._ref?.id}]=${_.updated?.getTime()}`).join('&');
        let myTicketsUrl = `/_/accountTickets?accountId=${auth.user?.uid}&${myCompetitionsQueryString}`;

        let response = await fetch(myTicketsUrl);
        let data = JSONParseLocal(JSON.stringify(await response.json()));

        for (let competitionId in data.competitions) {
            //hard cap the my tickets per competitions to 5k each for performance
            data.competitions[competitionId] = (data.competitions[competitionId] as []).slice(0, 5000);
        }

        return data.competitions;
    }

    @observe('auth', 'items')
    async loadMyTickets(auth: Auth, items: CompetitionDocument[]) {
        if (!auth?.user) return;
        if (!items.length) return;

        this.myTicketsLoading = true;
        let myTicketsAll = {};

        let competitionChunks: Record<string, CompetitionDocument[]> = {};
        for (let item of items) {
            let chunkId = Math.floor((item?.drawn?.getTime() || 0) / (86400000 * 7));
            let competitionChunk = competitionChunks[chunkId] ??= [];
            competitionChunk.push(item);
        }
        for (let itemsChunk of Object.values(competitionChunks)) {
            let myChunkTickets = await this.loadMyTicketsChunk(auth, itemsChunk);
            Object.assign(myTicketsAll, myChunkTickets);
        }

        this.myTicketsAll = myTicketsAll;
        console.log('my tickets', myTicketsAll);
        this.myTicketsLoading = false;
    }

    @observe('route')
    loadViewFullCompetitionFromUrl(route: Route) {
        this.viewFullCompetition = route.current.query.competition;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-entry-list': ComponentAspireCompsEntryList;
    }
}