import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {PrizeClaimingThreadDocument, PrizeClaimingThreadMessageDocument} from '../../shared/helpers/FirestoreHelper';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import {calculateDocumentPath} from '@lupimedia/firebase-polyfill/src/firestore/helpers/FirestoreHelper.ts';

let htmlSanatizer: HTMLElement;
const sanatizeHTML = (body: string) => {
    htmlSanatizer ??= document.createElement('div');

    htmlSanatizer.textContent = body;
    return htmlSanatizer.innerHTML;
};

@customElement('component-prize-claiming-messages-message')
export class ComponentPrizeClaimingMessagesMessage extends BunnyElement {

    @property({type: Object})
    prizeClaimingThread: PrizeClaimingThreadDocument;

    @property({type: Object})
    item: PrizeClaimingThreadMessageDocument;

    @property({type: Boolean})
    isAdmin = false;

    @property({type: String, reflect: true})
    @computed('item')
    get ownerType() {
        if (!this.item.owner) return 'aspire';

        return this.item.owner.path === this.prizeClaimingThread.owner.path ? 'customer' : 'aspire';
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;
                display: block;
                background: var(--attention-color);
                border-radius: 15px;
                padding: 15px;
                color: white;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                width: 100%;
                width: fit-content;
                max-width: 75%;
                min-width: 250px;
            }

            :host([owner-type="customer"]) {
                margin-left: auto;
            }

            :host([owner-type="aspire"]) {
                margin-right: auto;
                background: var(--primary-color);
            }

            .header {
                position: absolute;
                bottom: 100%;
                margin-bottom: 5px;
                left: 10px;
                right: 10px;
                color: var(--primary-text-color);
                text-transform: uppercase;
                display: flex;
                font-weight: bold;

                small {
                    margin-left: auto;
                    margin-top: auto;
                    font-weight: normal;
                }
            }

            .footer {
                margin: -15px;
                margin-top: 15px;
                padding: 15px;
                overflow: hidden;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
                background: white;
                color: var(--primary-text-color)
            }

            hr {
                border-top: solid rgba(255, 255, 255, .3) 1px;
                border-bottom: solid rgba(255, 255, 255, .6) 1px;
                margin-top: 25px;
                margin-bottom: 10px;
                margin-left: -15px;
                margin-right: -15px;
            }

            a {
                font-weight: bold;
            }
        `,
    ];

    override render() {
        return html`
            <div class="header">
                ${this.item.owner ? html`
                    <resolve-firestore-document-name .path="${calculateDocumentPath(this.item.owner)}"
                                                     format="::firstName::"></resolve-firestore-document-name>
                ` : html`
                    Aspire
                `}


                <small style="font-size: 65%"
                       title="${RenderingHelper._dateFormat(this.item.created, 'dd/MM/yyyy HH:mm')}">
                    ${RenderingHelper._dateFromNow(this.item.created)}
                </small>
            </div>

            ${this.formatBody(this.item.body)}

            ${this.item.meta?.embeds?.length ? html`
                <hr>
                ${this.item.meta.embeds.map(item => html`
                    <a target="_blank" href="${item.src}">
                        <img src="${item.src}" style="width: 400px"><br>
                        (Click to open in new tab)
                    </a>
                `)}
            ` : undefined}
        `;
    }

    formatBody(body: string) {
        body = sanatizeHTML(body)
            .replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=\*]*)/g, (m) => {
                let link = document.createElement('a');
                link.href = m;
                link.textContent = m;
                link.target = '_BLANK';

                return link.outerHTML;
            })
            .trim()
            .replace(/\n/g, '<br>');

        return unsafeHTML(body);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-message': ComponentPrizeClaimingMessagesMessage;
    }
}