import {callableQuery} from '../helpers/SurrealHelper.ts';
import {customElement} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../helpers/decorators/ComputedDecotratorHelper';
import {ENV} from '../../../../config';
import {Auth} from '../../../auth/local/controllers/Auth';

@customElement('firebase-storage-file')
export class FirebaseStorageFile extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Object, notify: true})
    uploadReference: any;

    @property({type: String})
    type: string;

    @property({type: Object})
    meta: any;

    @property({type: String})
    access: 'public' | 'private' = 'private';

    @property({type: Object})
    @computed('uploadReference')
    get _uploadFile() {
        return this.uploadReference._uploadFile;
    }

    static override styles = [];

    override render() {
        return undefined;
    }


    async createMediaReference(file: File) {
        if (this.uploadReference.media) {
            debugger; //Add overwriting if still required???
        }


        let prepareUpload = callableQuery('media::prepare');
        let reference = (await prepareUpload(
            file.name,
            {
                ...this.meta,
                contentType: file.type,
                fileType: file.name.split('.').at(-1),
                size: file.size,
            },
            this.access,
            this.type,
        )).data._ref.surrealId;

        this.uploadReference.media = reference;
        this.requestUpdate('uploadReference');
        return reference;
    }

    async upload(file: File) {
        try {
            await this._upload(file);

        } catch (e: any) {
            let _uploadFile = this.uploadReference._uploadFile;
            _uploadFile.uploading = false;
            _uploadFile.error = true;
            _uploadFile.status = 'Error: ' + e.message + (ENV === 'local' ? e.stack : '');
            this.uploadReference._notifyFileChanges();
        }
    }

    async _upload(file: File) {
        let mediaKey = await this.createMediaReference(file);
        let _uploadFile = this.uploadReference._uploadFile;
        _uploadFile.status = 'Uploading';
        _uploadFile.loaded = 0;
        this.uploadReference._notifyFileChanges();


        // _uploadFile.loaded = 0; //TODO replace with streamable uploads
        // this.uploadReference._notifyFileChanges();

        try {
            let upload = callableQuery('media::upload');
            await upload(mediaKey, await file.arrayBuffer());
            this.requestUpdate('uploadReference');

            _uploadFile.status = 'Uploaded';
            _uploadFile.uploading = false;
            _uploadFile.complete = true;
            this.uploadReference._notifyFileChanges();

        } catch (e) {
            console.error('Failed uploading storage file', e, this.uploadReference);
            //TODO cleanup failed upload
            throw e;
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'firebase-storage-file': FirebaseStorageFile;
    }
}