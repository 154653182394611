import {AnalyticsPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-highcharts';
import './components/component-analytics-cookie-banner';
import './components/component-analytics-explore.ts';
import './components/component-analytics-dashboard.ts';
import './components/component-analytics-dashboard-panel-json.ts';
import './components/component-analytics-dashboard-panel-value.ts';
import './components/component-analytics-dashboard-panel-table.ts';
import './components/component-analytics-dashboard-panel-chart.ts';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';

export class AnalyticsLocalPackage extends AnalyticsPackage implements LocalPackage {
    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            e.registerPostComponent({
                component: 'component-analytics-cookie-banner',
            });
        },
    };
}