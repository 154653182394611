import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-analytics-dashboard-panel')
export class ComponentAnalyticsDashboardPanel extends BunnyElement {

    @property({type: String})
    heading: string;

    @property({type: Object})
    data: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <div class="contentStep">
                ${this.heading ? html`
                    <h3>${this.heading}</h3>
                ` : undefined}
                ${this.renderPanel()}
            </div>
        `;
    }

    renderPanel() {
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-dashboard-panel': ComponentAnalyticsDashboardPanel;
    }
}