import {RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';

export const FIRESTORE_COLLECTION_PAGES = 'pages';

declare global {
    interface Collections {
        pages?: FirebaseFirestore.CollectionReference<PageDocument>;
    }
}


export interface PageContentComponent {
    component: string;
    properties?: {
        [key: string]: any;
    };
    attributes?: {
        [key: string]: any;
    };
    events?: {
        [key: string]: any;
    }[];
}

export interface PageContent {
    _layout?: string;
    _layoutProperties?: {
        [key: string]: any;
    };

    [key: string]: string | any | undefined | PageContentComponent[];
}

export interface RedirectContent {
    url: string;
    httpStatusCode: 301 | 302;
    persistentSearchParams?: string[];
}


export interface PageDocument extends RefferableDocument {
    content: PageContent | RedirectContent;
    type?: 'page' | 'redirect' | 'internalRedirect';
    routeFieldMatcher?: string;
    routeLength?: number;
    route?: string;
    resolvers?: {
        firestore?: {
            [key: string]: string | null;
        };
        postProcessors?: {
            processor: string;
            args: string[];
        }[];
        metadata?: {
            [key: string]: string | number;
        };
        routeFields?: string[];
    };
    created?: any;
    updated?: any;
    permissions?: string[];
}
