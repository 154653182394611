import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

@customElement('component-aspire-comps-review-slider')
class ComponentAspireCompsReviewSlider extends BunnyElement {
    @property({type: Number})
    selectedItem = 2;

    @property({type: Object})
    reviews: {
        name: string,
        cite: string,
        textContent: string
    }[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                overflow: hidden;

                --item-width: 100%;
                --selected-index: 0;
            }

            #items {
                display: flex;
                transition: .375s;
                transform: translateX(calc(var(--item-width) * var(--selected-index) * -1));
                margin-bottom: 25px;
            }

            #items > * {
                flex-shrink: 0;
                width: var(--item-width);
            }

            component-input-radio-group component-input-radio-item + component-input-radio-item {
                margin-left: 15px;
            }

            @media screen and (min-width: 768px) {
                :host {
                    --item-width: 70%;
                    position: relative;
                }

                :host:after {
                    content: '';
                    background: linear-gradient(90deg, #f9f9f9 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, #f9f9f9 100%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    pointer-events: none;
                }

                #items > *:first-child {
                    margin-left: 15%;
                }

                #items > *:last-child {
                    margin-right: 15%;
                }
            }
        `,
    ];

    override render() {
        return html`
            <component-firebase-remote-config key="reviews"
                                              .value="${this.bind.reviews}"></component-firebase-remote-config>

            <div id="items">
                ${this.reviews?.map(item => html`
                    <component-aspire-comps-review-item name="${item.name}" cite="${item.cite}">
                        ${item.textContent}
                    </component-aspire-comps-review-item>
                `)}
            </div>

            <component-input-radio-group .value="${this.bind.selectedItem}">
                ${this.reviews?.map((_item, index) => html`
                    <component-input-radio-item style="display: inline-block"
                                                .name="${index}"></component-input-radio-item>
                `)}
            </component-input-radio-group>
        `;
    }

    @observe('selectedItem')
    loadSelectedIndex(selectedItem: string) {
        this.style.setProperty('--selected-index', selectedItem);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-review-slider': ComponentAspireCompsReviewSlider;
    }
}