import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {Route} from '../../../routing/local/controllers/Route.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';
import {storageBoundLocalStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper.ts';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import {userCall} from '../../../auth/local/helpers/UserCallHelper.ts';

@customElement('component-promotional-draws-process-claim')
class ComponentPromotionalDrawsProcessClaim extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String, notify: true})
    @storageBoundLocalStorage('process-comment-draw-claim-access-token', '', undefined, true)
    receivedClaimToken: any = null;

    @observe('route')
    async doProcess(route: Route) {
        if (!route.current.query.doClaim) return;

        try {
            debugger;
            let params = new URLSearchParams(this.route.current.hash?.substring(1));
            let accessToken = params.get('access_token');
            let promotionalDrawId = params.get('state');

            this.receivedClaimToken = {accessToken: accessToken, promotionalDrawId: promotionalDrawId};

            window.close(); // Will not close in facebook browser
            HistoryHelper.redirect(`/promotional-draws?accessToken=${accessToken}&promotionalDrawId=${promotionalDrawId}`);

        } catch (e) {
            console.error(e);

            window.close(); // Will not close in facebook browser
            HistoryHelper.redirect(`/promotional-draws`);
        }
    }


    @observe('receivedClaimToken')
    async processReceivedClaimToken() {
        let receivedClaimToken = this.receivedClaimToken;
        if (!receivedClaimToken) return;

        this.receivedClaimToken = null;
        await this.processClaim(receivedClaimToken.accessToken, receivedClaimToken.promotionalDrawId);
    }

    @observe('route')
    async processBySearchParams(route: Route) {
        let routeCurrentQuery = route.current.query;
        let accessToken = routeCurrentQuery.accessToken;
        let promotionalDrawId = routeCurrentQuery.promotionalDrawId;
        if (!accessToken || !promotionalDrawId) return;

        HistoryHelper.replaceState('/promotional-draws');
        await this.processClaim(accessToken, promotionalDrawId);
    }

    @toastProgressWrapper({
        progressMessage: 'Processing claim',
        successMessage: 'Congrats! Successfully claimed your prize',
        failedMessage: 'Failed claiming: {{e}}',
    })
    async processClaim(accessToken: string, promotionalDrawId: string) {
        let returnUrl = await userCall(`PromotionalDrawClaim`, {
            accessToken: accessToken,
            promotionalDrawId: promotionalDrawId,
        });


        HistoryHelper.redirect(returnUrl);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-promotional-draws-process-claim': ComponentPromotionalDrawsProcessClaim;
    }
}