import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {storageBoundLocalStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {Auth} from '../../../auth/local/controllers/Auth';

@customElement('component-aspire-comps-entry-list-item')
class ComponentAspireCompsEntryListItem extends ComponentFirestoreCollectionListItemCell {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Boolean})
    displayDrawDate: boolean = false;

    @property({type: Boolean})
    @computed('displayDrawDate', 'item')
    get displayDrawDateHeading() {
        if (!this.displayDrawDate) return false;

        let currentItemIndex = this.list.items.indexOf(this.item);
        if (currentItemIndex === 0) return true;

        let prevItem = this.list.items[currentItemIndex - 1];
        if (prevItem.drawn?.toString() !== this.item.drawn?.toString()) return true;


        return false;
    };

    @property({type: Boolean})
    opened: boolean = false;

    @property({type: Array})
    tickets: any[] = [];

    @property({type: String})
    @computed('item')
    get drawStatus() {
        if (this.item.finished) return 'drawn';

        let currentItemIndex = this.list.items.indexOf(this.item);
        if (currentItemIndex !== 0) {
            let prevItem = this.list.items[currentItemIndex - 1];

            if (prevItem.finished) return 'drawing';
        }


        return 'waiting';
    }

    @property({type: Boolean})
    @storageBoundLocalStorage('aspire-comps-competitions-entryList-displayDrawStatus', 'true', _ => _ === 'true')
    displayDrawStatus: boolean = true;

    @property({type: Object})
    myTicketsAll: {
        [key: string]: any[]
    };

    @property({type: Array})
    @computed('myTicketsAll')
    get myTickets() {
        return this.myTicketsAll[this.item._ref.id];
    }

    @property({type: Boolean})
    myTicketsLoading: boolean = true;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                margin-bottom: 5px;
            }

            component-button-toggle {
                background: var(--primary-color);
                position: relative;

                color: white;
                min-width: 0;
                margin: 0;
                display: flex;
                justify-content: start;
                --padding: 0;
                overflow: hidden;
                line-height: 1.2;
                text-align: left;

                &[active] {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }

                span {
                    font-size: 25px;
                    padding: 10px;
                    flex: 1;

                    small {
                        vertical-align: middle;
                        background-color: var(--primary-text-color);
                        font-size: 50%;
                        border-radius: 3px;
                        padding: 2px 5px;
                        color: white;
                        text-transform: uppercase;
                    }
                }
            }

            component-button.innerButton {
                min-width: 0;
                margin: 0;
                background: var(--primary-text-color);
                border-radius: 0;
                margin-bottom: auto;
                margin-left: 5px;
                height: 50px;
            }

            .competitionTickets {
                overflow: hidden;
                border: solid rgba(32, 32, 32, .3) 1px;
                border-top: none;
                padding: 0 15px;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            component-aspire-comps-items-section {
                padding-bottom: 25px;
                overflow: visible;
                --title-padding: 5px;
            }
        `,
    ];

    override render() {
        let canDisplayWinner = this.canDisplayWinner(this.item.finished, this.auth);

        return html`
            ${this.displayDrawDateHeading ? html`
                <h3>
                    ${RenderingHelper._dateFormat(this.item.drawn, 'dd/MM/yyyy')} competitions
                </h3>
            ` : undefined}

            <component-button-toggle .active="${this.bind.opened}">
                <span style="flex: 1; text-transform: none">
                    ${this.displayDrawDate ? html`
                        ${RenderingHelper._dateFormat(this.item.drawn, 'dd/MM/yyyy')} &middot;
                    ` : undefined}
                    
                    ${this.item.name}
                    ${this.displayDrawStatus ? html`
                        <small>${this.drawStatus}</small>
                    ` : undefined}
                    
                    ${this.myTickets?.length ? html`
                        <small style="background-color:var(--attention-color);">You have ${this.myTickets.length}
                                ticket${this.myTickets.length === 1 ? '' : 's'}</small>
                    ` : undefined}
                </span>
                ${!this.opened ? html`
                    <component-button class="innerButton">
                        View
                    </component-button>
                ` : undefined}
            </component-button-toggle>

            ${this.opened ? html`
                <div class="competitionTickets">
                    <component-aspire-comps-items-section .title="${'Winners'}">
                        <component-aspire-comps-tickets-renderer
                                .items="${canDisplayWinner ? this._processTickets(this.item.winningTickets, this.item.winningTickets) : []}"
                                .displayWinner="${canDisplayWinner}"
                                .noItemsMessage="${'A winner has not yet been chosen! Stay tuned.'}"></component-aspire-comps-tickets-renderer>

                        <component-aspire-comps-tickets-renderer
                                disable-animation
                                style="overflow: hidden; zoom: .5"
                                .items="${canDisplayWinner ? this._processRippleTickets(this.item.rippleWinners) : []}"
                                .rippleWinners="${this.item.rippleWinners}"
                                .noItemsMessage="${''}"></component-aspire-comps-tickets-renderer>


                    </component-aspire-comps-items-section>

                    <component-aspire-comps-items-section .title="${'Your tickets'}">
                        ${this.auth.user ? html`
                            <component-aspire-comps-tickets-renderer
                                    .items="${this.myTickets || []}"
                                    .noItemsMessage="${this.myTicketsLoading ? 'Loading' : 'You do not have any tickets for this competition'}"
                                    .displayWinner="${canDisplayWinner}"></component-aspire-comps-tickets-renderer>
                        ` : html`
                            <p style="text-align: center">
                                Please login to view your tickets
                            </p>
                            <a href="/login" style="display: block; text-align: center">
                                <component-button>
                                    Login
                                </component-button>
                            </a>
                        `}
                    </component-aspire-comps-items-section>

                    <component-aspire-comps-items-section .title="Tickets 1 - ${this.item.maxTicketNumber}"
                                                          class="allTickets">
                        <component-aspire-comps-tickets-renderer
                                .items="${this._processTickets(this.item.first10Tickets, this.item.winningTickets)}"
                                limit="7"
                                .displayWinner="${canDisplayWinner}"></component-aspire-comps-tickets-renderer>

                        <p style="text-align: center" class="contentContainer">
                            <a href="?competition=${this.item._ref.id}">
                                Click to view tickets 1 to ${this.item.maxTicketNumber}
                            </a>
                            <br>
                            <small style="opacity: .6">Total tickets entered: ${this.item.totalTickets}</small>
                        </p>
                    </component-aspire-comps-items-section>
                </div>
            ` : undefined}
        `;
    }

    canDisplayWinner(finished: boolean, auth: Auth) {
        if (finished) return true;

        return RenderingHelper._accountHasPermission(auth.user, 'aspirecomps.competitions.viewActiveWinner');
    }

    _processTickets(tickets: any[], winningTickets: any[]) {
        tickets = tickets || [];
        winningTickets = winningTickets || [];
        let winningTicketNumbers = winningTickets.map((_: any) => _.number);

        return tickets.filter(_ => _)
            .map((_: any) => ({
                ..._,
                winner: winningTicketNumbers.includes(_.number),
            }));
    }

    _processRippleTickets(rippleTickets: any[]) {
        rippleTickets = rippleTickets || [];

        return Object.entries(rippleTickets)
            .map(([number, ticket]) => ({
                name: ticket.winnerName,
                number: parseInt(number),
            }));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-entry-list-item': ComponentAspireCompsEntryListItem;
    }
}