import {customElement} from 'lit/decorators.js';
import {
    HISTORY_STATE_KEY_CURRENT_PAGE_SCROLL,
    HISTORY_STATE_KEY_LAST_PAGE_SCROLL,
    HistoryStatePageScroll,
    Route,
} from '../controllers/Route';
import {createComponent} from '../helpers/DomHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {UnshadowStyles} from '../../../__internal/local/controllers/UnshadowStyles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {DEFAULT_PAGE_LAYOUT} from '../../shared/index';
import {injectData} from '../helpers/DataHelper';
import {config} from '../../../../config';
import {GLOBAL_GRAPPYFILL} from '../../../../aspire-app';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {html} from 'lit';
import {RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {Auth} from '../../../auth/local/controllers/Auth.ts';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection';


export interface PageContentComponent {
    component: string;
    properties?: {
        [key: string]: any;
    };
    events?: {
        [key: string]: any;
    };
}

export interface PageContent {
    _layout?: string;
    _layoutProperties?: {
        [key: string]: any;
    };

    [key: string]: string | any | undefined | PageContentComponent[];
}

export interface RedirectContent {
    url: string;
    httpStatusCode: 301 | 302;
}

export interface PageDocument extends RefferableDocument {
    content: PageContent | RedirectContent;
    type?: 'page' | 'redirect' | 'internalRedirect';
    routeFieldMatcher?: string;
    routeLength?: number;
    route?: string;
    resolvers?: {
        firestore?: {
            [key: string]: string | null;
        };
        postProcessors?: {
            processor: string;
            args: string[];
        }[];
        metadata?: {
            [key: string]: string | number;
        };
        routeFields?: string[];
    };
    created?: any;
    updated?: any;
    permissions?: string[];
}

enum LOADING_STATE {
    READY = 'ready',
    LOADING = 'loading',
    LOADED = 'loaded',
}

const INTERNAL_LOADING_PAGE = {
    route: '/_/loading',
    routeLength: 1,
    routeFieldMatcher: '/_/loading',
    content: {
        _layout: 'page-layout-aspire-comps',
        _layoutProperties: {
            renderFooter: false,
            style: '--header-spacing: 25px;',
        },
        main: [
            {
                component: 'component-routing-loading',
            },
        ],
    },
    type: 'page',
} as PageDocument;

const INTERNAL_NOT_FOUND_PAGE = {
    route: '/_/404',
    routeLength: 1,
    routeFieldMatcher: '/_/404',
    content: {
        _layout: 'page-layout-aspire-comps',
        _layoutProperties: {
            renderFooter: false,
            style: '--header-spacing: 0px;',
        },
        main: [
            {
                component: 'div',
                properties: {
                    textContent: 'Page not found',
                },
            },
        ],
    },
    type: 'page',
} as PageDocument;

const INTERNAL_ERROR_PAGE = {
    route: '/_/0',
    routeLength: 1,
    routeFieldMatcher: '/_/0',
    content: {
        _layout: 'page-layout-aspire-comps',
        main: [
            {
                component: 'component-routing-error',
            },
        ],
    },
    type: 'page',
} as PageDocument;

const INTERNAL_INSTALL_PAGE = {
    route: '/__install',
    routeLength: 1,
    routeFieldMatcher: '/__install',
    content: {
        _layout: 'page-install',
        _layoutProperties: {
            renderFooter: false,
            style: '--header-spacing: 0px;',
            get packages() {
                return GLOBAL_GRAPPYFILL.packages;
            },
        },
    },
    type: 'page',
} as PageDocument;

const INTERNAL_RENDER_ELEMENT_PAGE = {
    route: '/__render-element',
    routeLength: 1,
    routeFieldMatcher: '/__render-element',
    content: {
        _layout: 'page-render-element',
    },
    type: 'page',
} as PageDocument;

export const REGISTERED_SCROLL_POSITIONS: {
    [key: string]: HTMLElement
} = {};

const getLoadingPage = (lastPage?: PageDocument, forcefulLoadingPageHeight?: number) => {
    let internalLoadingPage = JSON.parse(JSON.stringify(INTERNAL_LOADING_PAGE)) as PageDocument;

    if (lastPage) {
        //perciest the last layout so it doesnt update between nevigations of the same layout types
        let lastLayout = (lastPage.content as PageContent)._layout;
        (internalLoadingPage.content as PageContent)._layout = lastLayout;
    }

    if (forcefulLoadingPageHeight) {
        let _layoutProperties: Record<string, any> = (internalLoadingPage.content as PageContent)._layoutProperties ??= {};
        _layoutProperties.style ??= '';
        _layoutProperties.style += ` min-height: ${forcefulLoadingPageHeight}px;`;
    }

    return internalLoadingPage;
};

const getErrorPage = (loadingError: any) => {
    let internalErrorPage = JSON.parse(JSON.stringify(INTERNAL_ERROR_PAGE)) as PageDocument;

    let routingErrorComponent = (internalErrorPage.content as PageContent).main[0];
    routingErrorComponent.properties ??= {};
    routingErrorComponent.properties.loadingError = loadingError;

    return internalErrorPage;
};

@customElement('routing-page')
export class RoutingPage extends BunnyElement {

    //@ts-ignore
    private unshadowStyles = new UnshadowStyles(this);

    @property({notify: true})
    route = Route.getInstance(this);

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({notify: true})
    routeMatch!: string;

    @property({notify: true})
    loadingState = LOADING_STATE.READY;

    @property({type: Number})
    minimumScrollHeight = 0;

    @property()
    @computed('routeMatch')
    get pagesResolved(): FirestoreCollection<PageDocument> {
        let routeMatch = this.routeMatch;
        this.loadingState = LOADING_STATE.LOADING;

        if (routeMatch === INTERNAL_INSTALL_PAGE.route) {
            return {
                data: [
                    INTERNAL_INSTALL_PAGE,
                ],
            } as any;

        } else if (routeMatch === INTERNAL_RENDER_ELEMENT_PAGE.route) {
            return {
                data: [
                    INTERNAL_RENDER_ELEMENT_PAGE,
                ],
            } as any;

        } else if (routeMatch === INTERNAL_ERROR_PAGE.route) {
            return {
                data: [
                    INTERNAL_ERROR_PAGE,
                ],
            } as any;
        }


        return new SurrealCollection<PageDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                'pages',
                {where: [{fieldPath: 'route', opStr: '==', value: routeMatch}]},
            ],
            {
                method: FetchMethod.FASTEST_THEN_CLEAN,
                suppressLoadingError: true,
                measurePerformance: true,
            },
        );
    }

    @property()
    @computed('pagesResolved')
    get page(): PageDocument {
        let page = (this.pagesResolved?.data || [])[0] as (PageDocument | undefined);
        let lastRoutePageScroll = (this.route.current?.data || {})[HISTORY_STATE_KEY_LAST_PAGE_SCROLL] as HistoryStatePageScroll | undefined;

        if (!page && this.pagesResolved.loadingError) {
            return getErrorPage(this.pagesResolved.loadingError);
        }

        if (!page) {
            if (this.pagesResolved.data) {
                if (!(this.pagesResolved.data as any)._metadata.fromCache) return INTERNAL_NOT_FOUND_PAGE;
            }


            return getLoadingPage(this.page, lastRoutePageScroll?.height);
        }


        switch (page.type) {
            case undefined:
            case 'page':
                this.loadingState = LOADING_STATE.LOADED;
                return page;

            case 'redirect':
            case 'internalRedirect':
                if (page.type === 'redirect') {
                    HistoryHelper.replaceState(page.content.url);

                } else if (page.type === 'internalRedirect') {
                    HistoryHelper.internalLocationChange(page.content.url);
                }

                return getLoadingPage(this.page, lastRoutePageScroll?.height);
        }
    }


    // language=SCSS
    static override styles = scss`
        routing-page {
            display: block;
            --minimum-scroll-height: 0;
            min-height: var(--minimum-scroll-height);
        }
    `;

    override render() {
        if (!this.page || ![undefined, 'page'].includes(this.page.type)) return;

        let data = injectData(this.route.current.path, this.page, {
            route: this.route,
            global: GLOBAL_GRAPPYFILL,
        });

        let content = this.page.content as PageContent;
        let layout = content._layout || DEFAULT_PAGE_LAYOUT;
        let layoutProperties = content._layoutProperties || {};
        if (!('renderFooter' in layoutProperties)) {
            //TODO kinda a hack to unset the default rendering templates styles /prevous page
            (layoutProperties as any).renderFooter = true;
        }
        if (!('style' in layoutProperties)) {
            //TODO kinda a hack to unset the default rendering templates styles /prevous page
            (layoutProperties as any).style = '';
        }


        let pageTitle = (this.page.resolvers?.metadata as any)?.title;
        if (pageTitle && pageTitle.includes(':')) {
            pageTitle = undefined;
        }
        if (pageTitle) {
            document.title = [
                pageTitle,
                config.title,
            ].filter(_ => _).join(' · ');
        }

        return html`
            <component-page-preloader></component-page-preloader>
            ${createComponent(
                    {
                        component: layout,
                        properties: {
                            ...layoutProperties,
                            page: this.page,
                            content: this.page?.content,
                            data: data,
                        },
                    },
                    data,
            )}
        `;
    }


    @observe('page')
    _parsePage(page: PageDocument) {
        if (!page) return;

        switch (page.type) {
            case undefined:
            case 'page':
                return;

            case 'redirect':
                HistoryHelper.replaceState((page.content as RedirectContent).url);
                return;

            case 'internalRedirect':
                HistoryHelper.internalLocationChange((page.content as RedirectContent).url);
                return;
        }
    }

    @observe('route')
    onRouteUpdated(route: Route) {
        let uri = route.current.path;
        if (uri.length > 1) {
            uri = uri.replace(/\/$/, '');
        }

        // this.allowLoadingOfUriFull = uri;
        uri = uri
            .replace(/\/[0-9A-Za-f]{40}/g, '/*')//sha1
            .replace(/\/[0-9A-Za-z]{28}/g, '/*') //firebase user id
            .replace(/\/[0-9A-Za-z]{20}/g, '/*') //firestore doc id
        ;

        // this.allowLoadingOfUri = uri;

        this.routeMatch = uri;
    }


    @observe('page', 'auth')
    validatePagePermissions(page: PageDocument, auth: Auth) {
        if (!page?.permissions) return;
        if (RenderingHelper._accountHasPermissions(auth.user, page.permissions)) return;


        if (auth.user) {
            HistoryHelper.internalLocationChange('/error/403');

        } else {
            HistoryHelper.replaceState(`/login?returnUrl=${encodeURIComponent(location.href.substr(location.origin.length))}`);
        }
    }

    createRenderRoot() {
        return this;
    }

    @observe('route')
    async scrollOnPageChange(route: Route) {
        let currentRoutePageScroll = (route.current?.data || {})[HISTORY_STATE_KEY_CURRENT_PAGE_SCROLL] as HistoryStatePageScroll | undefined;
        let scrollY = currentRoutePageScroll?.y || 0;
        let scrollHeight = currentRoutePageScroll?.height || 1;
        window.scrollTo(0, scrollY);
        this.minimumScrollHeight = scrollHeight;


        let startingPageUrl = location.href;
        //wait 500ms for the hash to exist in the REGISTERED_SCROLL_POSITIONS
        for (let i = 0; i < 50; i++) {
            //if the url changes then bail
            if (startingPageUrl !== location.href) break;

            let element = REGISTERED_SCROLL_POSITIONS[location.hash.substr(1)];
            if (element) {
                let position = element.getBoundingClientRect();
                window.scrollTo(0, position.y - 75);
                break;
            }

            await delayPromise(10);
        }
    }

    private __lastTrackUrl: string;

    @observe('page')
    trackPageChange(page: PageDocument) {
        if (!page._ref) return;

        let trackUrl = location.href.replace(location.origin, '');
        if (this.__lastTrackUrl !== trackUrl) {
            this.__lastTrackUrl = trackUrl;
            track('pageView', {
                path: trackUrl,
                title: document.title,
            });
        }
    }

    @observe('minimumScrollHeight')
    reflectMinimumScrollHeightToCSSProperty(minimumScrollHeight: number) {
        if (!minimumScrollHeight) return;

        this.style.setProperty('--minimum-scroll-height', `${minimumScrollHeight}px`);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'routing-page': RoutingPage;
    }
}