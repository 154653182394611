import {customElement} from 'lit/decorators.js';
import {ComponentInput} from './component-input';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-input-password')
export class ComponentInputPassword extends ComponentInput {

    @property()
    type = 'password';

}

declare global {
    interface HTMLElementTagNameMap {
        'component-input-password': ComponentInputPassword;
    }
}
