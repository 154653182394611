import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {createComponent} from '../../../routing/local/helpers/DomHelper.ts';
import {
    AnalyticsDataSourceDocument,
    DATABASE_TABLE_ANALYTICS_DATA_SOURCES,
    FIRESTORE_COLLECTION_ANALYTICS_DATA_SOURCES,
} from '../../../../utils/DatabaseTypes.ts';
import {RecordId} from 'surrealdb';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper.ts';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper.ts';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper.ts';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';

@customElement('component-analytics-explore')
class ComponentAnalyticsExplore extends BunnyElement {

    @property({type: Object})
    existingDataSourceId: RecordId | 'NEW' = 'NEW';

    @property({type: Object})
    @computed('existingDataSourceId')
    get existingDataSource() {
        if (!this.existingDataSourceId || this.existingDataSourceId === 'NEW') return undefined;

        return new SurrealDocument<AnalyticsDataSourceDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_ANALYTICS_DATA_SOURCES}/${this.existingDataSourceId.id}`],
            {method: FetchMethod.NETWORK_ONLY},
        );
    };

    @property({type: Object})
    exploreData?: SurrealDocument<any>;

    @property({type: String})
    exploreRenderer = 'component-analytics-dashboard-panel-table';

    @property({type: String, notify: true})
    @storageBoundQueryString('q')
    customDataSourceQuery = '';

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .querySelector {
                component-input {
                    margin: 0;
                    margin-left: 15px;
                    flex: 1;
                }
            }
        `,
    ];

    override render() {
        return html`
            <div style="margin-bottom: 25px; display: flex" class="querySelector">
                <component-input-surreal-select style="width: 200px;"
                                                label="Data source"
                                                .value="${this.bind.existingDataSourceId}"
                                                .path="${DATABASE_TABLE_ANALYTICS_DATA_SOURCES}">
                    <component-input-select-item value="NEW">
                        NEW QUERY
                    </component-input-select-item>
                </component-input-surreal-select>

                ${this.existingDataSource?.data ? html`
                    <component-input label="Query(READONLY)"
                                     .value="${this.existingDataSource?.data.rawQuery}"
                                     disabled></component-input>
                ` : html`
                    <component-input label="Query"
                                     .value="${this.bind.customDataSourceQuery}"
                                     placeholder="Write the query"
                                     @keypress="${(e: KeyboardEvent) => {
                                         if (e.key === 'Enter') this.runQuery();
                                     }}"
                                     @input="${() => {
                                         this.exploreData = undefined;
                                     }}">
                        <span slot="prefix" style="margin-left: 15px">SELECT</span>
                    </component-input>
                `}

                <component-button style="margin-top: auto; margin-left: 15px" @click="${this.runQuery}">
                    Run
                </component-button>
                ${this.existingDataSourceId === 'NEW' ? html`
                    <component-button style="margin-top: auto; margin-left: 15px; min-width: 0"
                                      @click="${this.saveQuery}"
                                      .disabled="${!this.exploreData?.data}">
                        Save
                    </component-button>
                ` : html`
                    <component-button style="margin-top: auto; margin-left: 15px; min-width: 0"
                                      @click="${() => {
                                          this.existingDataSourceId = 'NEW';
                                          this.customDataSourceQuery = this.existingDataSource?.data.rawQuery?.replace(/^SELECT /, '') || '';
                                      }}">
                        Dupe
                    </component-button>
                `}
            </div>


            <div style="display: flex">
                <div style="font-size: 60%; margin-top: auto">
                    Results: ${this.exploreData?.data?.length || 0} &middot;
                    Size: ${(JSON.stringify(this.exploreData?.data || []).length / 1024).toFixed(1)}kb &middot;
                    Latency: ${this.exploreData?.latency || 0}ms
                </div>

                <component-input-select style="width: 150px; margin-left: auto" label="Renderer"
                                        .value="${this.bind.exploreRenderer}">
                    <component-input-select-item value="component-analytics-dashboard-panel-table">
                        Table
                    </component-input-select-item>
                    <component-input-select-item value="component-analytics-dashboard-panel-json">
                        JSON
                    </component-input-select-item>
                </component-input-select>
            </div>

            ${this.exploreData ? html`
                <component-loading .loading="${this.exploreData.loading}">
                    ${this.exploreData.loadingError ? html`
                <div style="color: #911">
                    ${this.exploreData.loadingError.message}
                </div>
            ` : html`
                ${createComponent({
                    component: this.exploreRenderer,
                    properties: {
                        data: this.exploreData.data || [],
                    },
                }, {})}
            `}
                </component-loading>
            ` : undefined}
        `;
    }

    runQuery() {
        if (!this.existingDataSourceId) return;


        if (this.existingDataSourceId === 'NEW') {
            if (!this.customDataSourceQuery) return;

            this.exploreData = new SurrealDocument<any>(
                this,
                'analytics::runTempDataSourceQuery',
                [
                    `SELECT ${this.customDataSourceQuery}`,
                ],
                {method: FetchMethod.NETWORK_ONLY},
            );
            return;
        }

        this.exploreData = new SurrealDocument<any>(
            this,
            'analytics::runDataSource',
            [
                this.existingDataSourceId,
            ],
            {method: FetchMethod.NETWORK_ONLY},
        );
    }

    @toastProgressWrapper()
    async saveQuery() {
        let dataSourceName = prompt('What to call it?');
        if (!dataSourceName) throw new FriendlyMessage('User canceled');

        await this._saveQuery(this.customDataSourceQuery, dataSourceName);
    }

    @confirmationDialog({title: 'Are you sure?', body: 'Are you sure you want to save this query?'})
    private async _saveQuery(query: string, name: string) {
        if (this.existingDataSourceId === 'NEW') {
            await callableQuery('__internal::addDoc')(
                DATABASE_TABLE_ANALYTICS_DATA_SOURCES,
                {
                    name: name,
                    rawQuery: `SELECT ${query}`,
                } as Partial<AnalyticsDataSourceDocument>,
            );

        } else {
            //TODO save over the top of a non locked query
            throw new Error('not implimented');
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-explore': ComponentAnalyticsExplore;
    }
}