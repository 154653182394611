import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {
    FIRESTORE_COLLECTION_SHOP_PRODUCTS,
    FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS,
    ShopProductDocument,
    ShopProductVariantDocument,
} from '../../../shop/shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {storageBoundSessionStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {choose} from 'lit/directives/choose.js';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';

@customElement('component-aspire-comps-product-tab-our-process')
class ComponentAspireCompsProductTabOurProcess extends BunnyElement {

    @property({type: String})
    productId: string;

    @property({type: Object})
    @computed('productId')
    get product() {
        if (!this.productId) return undefined;

        return new SurrealDocument<ShopProductDocument>(this, '__internal::loadFirestoreDocument', [`${FIRESTORE_COLLECTION_SHOP_PRODUCTS}/${this.productId}`], {method: FetchMethod.CACHE_FIRST});
    };

    @property({type: String})
    @storageBoundSessionStorage('shop-product-selected-variant')
    selectedVariantDocPath: string;

    @property({type: Object, notify: true})
    @computed('productId', 'selectedVariantDocPath')
    get selectedVariant() {
        if (!this.productId) return undefined;
        if (!this.selectedVariantDocPath) return undefined;

        return new SurrealDocument<ShopProductVariantDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_PRODUCTS}/${this.productId}/${FIRESTORE_COLLECTION_SHOP_PRODUCTS_SUB_VARIANTS}/${this.selectedVariantDocPath}`],
            {method: FetchMethod.CACHE_FIRST},
        );
    };

    @property({type: Object})
    @computed('product')
    get drawAt() {
        return this.product?.data?.drawDate;
    }

    @property({type: Object})
    @computed('drawAt')
    get dayAfterDraw() {
        if (!this.drawAt) return null;

        return new Date(this.drawAt.getTime() + 86400000);
    }

    @property({type: Object})
    @computed('product')
    get salePriceEnds() {
        let published = this.product?.data?.published as any;
        if (!published) return null;

        return new Date(published.getTime() + (86400000 * 2));
    }


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
          component-loading {
            text-align: center;
            background: linear-gradient(180deg, #F2B781, #F2B781 50%, #C5DF98 51%, #C5DF98) no-repeat left center;
            background-size: 7px 200%;
            padding: 0 25px;
            width: 400px;
            max-width: 100%;
            box-sizing: border-box;

            background-position-y: calc(100% - var(--competition-progress));
          }

          .panel {
            position: relative;
          }

          .panel .heading {
            font-weight: bold;
            font-family: proxima-nova, serif;
            text-transform: uppercase;
          }

          .panel > div {
            background: rgba(230, 230, 230, .75);
            border-bottom: solid #8dc033 2px;
            box-shadow: 0 0 10px 0 rgba(32, 32, 32, .1);
            padding: 15px;
          }

          .panel + .panel {
            margin-top: 35px;
          }

          .panel .heading span.date {
            color: #8dc033;
            font-size: 25px;
          }

          .panel:after {
            content: '';
            position: absolute;
            top: 10px;
            height: 6px;
            width: 25px;
            right: 100%;
            background: #C5DF98;
            z-index: -1;
          }


          @supports (display: grid) {
            @media screen and (min-width: 768px) {
              :host {
                display: block;
              }
              component-loading {
                background-position-x: 50%;
                width: 800px;
                display: grid;
                grid-template-columns: 47% 47%;
                column-gap: 6%;
                padding: 50px 0;
              }

              .panel {
                margin: auto;
                grid-row: span 3;
                width: 100%;
              }

              .panel:nth-of-type(2n) {
                grid-column: 2;
              }

              .panel:nth-of-type(2n+1):after {
                left: 100%;
                right: auto;
              }

              .panel:nth-of-type(1) {
                grid-row: 1 / span 3;
              }

              .panel:nth-of-type(2) {
                grid-row: 3 / span 3;
              }

              .panel:nth-of-type(3) {
                grid-row: 5 / span 3;
              }

              .panel:nth-of-type(4) {
                grid-row: 7 / span 3;
              }

              .panel:nth-of-type(5) {
                grid-row: 9 / span 3;
              }
            }
          }
        `,
    ];

    override render() {
        let loading = !(this.product?.data && this.selectedVariant?.data);

        return html`
            <component-loading .loading="${loading}" class="contentContainer">
                ${!loading ? html`

                    <div class="panel">
                        <div class="heading">
                            <span class="date">Our process</span>
                            <br>
                            ${this.product?.data.name}
                        </div>
                    </div>

                    ${choose(this.product?.data.type, [
                        ['autoDrawCompetition', () => html`
                            <div class="panel">
                                <div class="heading">
                                    <span class="date">${RenderingHelper._dateFormat(this.product?.data.published as Date, 'EEEE do MMM')}</span><br>
                                    Competition starts with <strong>${this.selectedVariant?.data.maxStock}
                                    tickets</strong>
                                    available in total
                                </div>
                                <div>
                                    Early badger discount ends after <strong>50% sales</strong> or on
                                    <strong>${RenderingHelper._dateFormat(this.salePriceEnds, 'EEEE do MMM \'at\' hh:mma')}</strong>,
                                    which ever happens to come first
                                </div>
                            </div>

                            <div class="panel">
                                <div class="heading">
                                    <span class="date">${RenderingHelper._dateFormat(this.product?.data.finished as Date, 'EEEE do MMM')}</span><br>
                                    Ticket sales end at
                                    ${RenderingHelper._dateFormat(this.product?.data?.finished as Date, 'hh:mma')},
                                    regardless of Sales so if we don't sell out this means better odds for you!
                                </div>
                                <div>
                                    Ticket numbers are randomly allocated <strong>Daily by 10pm</strong> and available
                                    on
                                    <strong><a href="/entry-list/my-competitions">your personal entry list</a></strong>.
                                    All
                                    entrants
                                    are notified shortly after by email with a link to view your ticket numbers
                                </div>
                            </div>

                            <div class="panel">
                                <div class="heading">
                                    <span class="date">${RenderingHelper._dateFormat(this.drawAt, 'EEEE do MMM')}</span><br>
                                    Auto Draw at
                                    <strong>${RenderingHelper._dateFormat(this.drawAt, 'hh:mma')}</strong>
                                </div>
                                <div>
                                    Winner chosen automatically by the same method as the
                                    <a href="https://www.google.com/search?q=random+number+generator"
                                       target="_blank">
                                        Google random number generator</a>, and soon after they're notified of their win
                                    by email.
                                </div>
                            </div>

                            <div class="panel">
                                <div class="heading">
                                    <span class="date">${RenderingHelper._dateFormat(this.dayAfterDraw, 'EEEE do MMM')}</span><br>
                                    All entrants notified of winners by email
                                </div>
                                <div>
                                    Our awesome winners are listed on our <a href="/previous-winners/">Winners Page</a>
                                </div>
                            </div>
                        `],
                        ['competition', () => html`
                            <div class="panel">
                                <div class="heading">
                                    <span class="date">${RenderingHelper._dateFormat(this.product?.data.published as Date, 'EEEE do MMM')}</span><br>
                                    Competition starts with <strong>${this.selectedVariant?.data.maxStock}
                                    tickets</strong>
                                    available in total
                                </div>
                                <div>
                                    Early badger discount ends after <strong>50% sales</strong> or on
                                    <strong>${RenderingHelper._dateFormat(this.salePriceEnds, 'EEEE do MMM \'at\' hh:mma')}</strong>,
                                    which ever happens to come first
                                </div>
                            </div>

                            <div class="panel">
                                <div class="heading">
                                    <span class="date">${RenderingHelper._dateFormat(this.product?.data.finished as Date, 'EEEE do MMM')}</span><br>
                                    Ticket sales end at
                                    ${RenderingHelper._dateFormat(this.product?.data?.finished as Date, 'hh:mma')},
                                    regardless of Sales so if we don't sell out this means better odds for you!
                                </div>
                                <div>
                                    Ticket numbers are randomly allocated <strong>Daily by 10pm</strong> and available
                                    on
                                    <strong><a href="/entry-list/my-competitions">your personal entry list</a></strong>.
                                    All
                                    entrants
                                    are notified shortly after by email with a link to view your ticket numbers
                                </div>
                            </div>

                            <div class="panel">
                                <div class="heading">
                                    <span class="date">${RenderingHelper._dateFormat(this.drawAt, 'EEEE do MMM')}</span><br>
                                    Draw ${RenderingHelper._dateFormat(this.drawAt, 'EEEE')} night at
                                    <strong>${RenderingHelper._dateFormat(this.drawAt, 'hh:mma')} live</strong> on
                                    <a href="https://www.facebook.com/aspirecompetitions/live/"
                                       target="_blank">Facebook</a>
                                    and
                                    <a href="https://www.youtube.com/@AspireCompetitions"
                                       target="_blank">YouTube</a><br>
                                </div>
                                <div>
                                    Winner chosen by the
                                    <a href="https://www.google.com/search?q=random+number+generator"
                                       target="_blank">
                                        Google random number generator</a>, and then called. Be sure to have your
                                    phone
                                    handy as
                                    this could be you. If we can't get hold
                                    of you don't worry as we will keep on trying until we do!
                                </div>
                            </div>

                            <div class="panel">
                                <div class="heading">
                                    <span class="date">${RenderingHelper._dateFormat(this.dayAfterDraw, 'EEEE do MMM')}</span><br>
                                    All entrants notified of winners by email
                                </div>
                                <div>
                                    Our awesome winners are listed on our <a href="/previous-winners/">Winners Page</a>
                                </div>
                            </div>
                        `],
                    ])}
                ` : undefined}
            </component-loading>
        `;
    }

    @observe('product')
    updateCompetitionProgressBar(product?: FirestoreDocument<ShopProductDocument>) {
        if (!product?.data?.finished || !product?.data?.published) return;

        let now = (new Date()).getTime();
        let finished: Date = product.data.finished;
        let published: Date = product.data.published;

        this.style.setProperty('--competition-progress', `${((now - published.getTime()) / (finished.getTime() - published.getTime())) * 100}%`);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-product-tab-our-process': ComponentAspireCompsProductTabOurProcess;
    }
}