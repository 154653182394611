import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {html} from 'lit';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {Auth} from '../../../auth/local/controllers/Auth.ts';

@customElement('component-notifications-auto-dismiss-all')
class ComponentNotificationsAutoDismissAll extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    override render() {
        return html`
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        if (this.auth.user) {
            this.dismissAll();
        }
    }

    async dismissAll() {
        await callableQuery('notifications::dismissAll')(); //TODO add this function
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-auto-dismiss-all': ComponentNotificationsAutoDismissAll;
    }
}