import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';

@customElement('component-charities-collection-header')
class ComponentCharitiesCollectionHeader extends BunnyElement {

    @property({type: Object})
    charitiesStats = new SurrealDocument<{ totalDonated: number, count: number }>(
        this,
        'aspireCompsCompetitions::charitiesStats',
        [],
        {method: FetchMethod.FASTEST_THEN_CLEAN},
    );

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];


    override render() {
        return html`
            <h1 style="margin-top: 0">
                Our ${this.charitiesStats.data?.count} Aspire charities to date, totaling
                &pound;${this.charitiesStats.data?.totalDonated}
            </h1>

            <slot></slot>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-charities-collection-header': ComponentCharitiesCollectionHeader;
    }
}