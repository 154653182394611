import {DateableDocument, RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';

export const FIRESTORE_COLLECTION_SHOP_POINTS = 'shopPoints';
export const FIRESTORE_COLLECTION_SHOP_POINTS_SUB_LOGS = 'logs';


declare global {
    interface Collections {
        shopPoints: FirebaseFirestore.CollectionReference<ShopPointDocument>;
    }
}

export interface ShopPointDocument extends RefferableDocument, FirebaseFirestore.DocumentData, DateableDocument {
    owner: FirestoreDocumentReference,
    points: number;
}

export interface ShopPointLogDocument extends RefferableDocument, FirebaseFirestore.DocumentData, DateableDocument {
    modificationValue: number
    modificationType: string
    message: string
    causer: FirestoreDocumentReference,
}