import {customElement} from 'lit/decorators.js';
import {ComponentPrizeClaimingMessagesMessage} from './component-prize-claiming-messages-message';


@customElement('component-prize-claiming-messages-delivery-sent')
class ComponentPrizeClaimingMessagesDeliverySent extends ComponentPrizeClaimingMessagesMessage {

}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-messages-delivery-sent': ComponentPrizeClaimingMessagesDeliverySent;
    }
}