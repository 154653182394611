import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import './component-media-view-image';
import {MediaDocument, MediaReferenceField} from '../../shared/helpers/FirebaseHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';

@customElement('component-input-media-library-list-item')
class ComponentInputMediaLibraryListItem extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-media-view-image {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                max-width: 100%;
                cursor: pointer;
            }
        `,
    ];

    override render() {
        return html`
            <div style="position:relative;">
                <component-media-view-image @click="${this.select}"
                                            .media="${this._wrapMediaToReference(this.item)}"
                                            type="default-thumbnail"
                                            width="400"
                                            height="300"></component-media-view-image>

                <div style="position:absolute; top: 0; left: 0; right: 0; padding: 3px; background: rgba(32, 32, 32, .6); font-size: 10px; color: white">
                    ${this.item.originalFile}
                </div>
                <div style="position:absolute; bottom: 0; left: 0; right: 0; padding: 3px; background: rgba(32, 32, 32, .6); font-size: 10px; color: white"
                     title="Created ${RenderingHelper._dateFormat(this.item.created, 'dd/MM/yyyy HH:mm')}">
                    ${RenderingHelper._dateFromNow(this.item.created)}
                </div>
            </div>

        `;
    }

    _wrapMediaToReference(media: MediaDocument): MediaReferenceField | undefined {
        if (!media) return;

        return {
            media: media._ref as FirestoreDocumentReference,
            type: media.type,
        };
    }

    select() {
        this.dispatchEvent(new CustomEvent('select-media', {
            detail: {
                media: this.item,
            },
            bubbles: true,
            composed: true,
        }));
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-media-library-list-item': ComponentInputMediaLibraryListItem;
    }
}