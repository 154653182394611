import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-data-collection-list-item-cell-admin-orders-items-summary')
class ComponentDataCollectionListItemCellAdminOrdersItemsSummary extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let itemsSummary = Object.values(this.item.items).map(_ => `${_.quantity}x - ${_.name}`).join('\n');
        return html`
            <pre title="${itemsSummary}" style="margin: 0; max-height: 46px; overflow: hidden">${itemsSummary}</pre>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-admin-orders-items-summary': ComponentDataCollectionListItemCellAdminOrdersItemsSummary;
    }
}