import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';

@customElement('component-homepage-competitions-available')
class ComponentHomepageCompetitionsAvailable extends BunnyElement {

    @property({type: Object})
    activeProductsStats = new SurrealDocument<{ count: number }>(
        this,
        'shop::getActiveProductsStats',
        [],
        {method: FetchMethod.FASTEST_THEN_CLEAN},
    );

    @property({type: Object})
    get nextDrawAt() {
        let now = new Date();
        return new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + ((7 - now.getDay()) % 7 + 3) % 7,
            19,
            30,
            0,
        );
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                text-align: center;
                text-transform: uppercase;
                color: #fefefe;
            }

            p {
                font-size: 25px;
                font-size: calc(1vw + 14px);
                font-weight: bold;
                background-color: #222222;
                padding: 10px;
                box-shadow: 25vw 0 #222222, -25vw 0 #222222;
                margin: 0;
            }

            component-countdown-timer {
                background-color: #43454b;
                box-shadow: 25vw 0 #43454b, -25vw 0 #43454b;
            }

            component-button {
                display: block;
                box-shadow: 25vw 0 var(--primary-color), -25vw 0 var(--primary-color);
            }
        `,
    ];

    override render() {
        return html`
            <p>
                ${this.activeProductsStats?.data?.count} competitions available, next draw in
            </p>

            <component-countdown-timer .target="${this.nextDrawAt}"></component-countdown-timer>

            <a href="/shop/products">
                <component-button>
                    View all
                </component-button>
            </a>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-homepage-competitions-available': ComponentHomepageCompetitionsAvailable;
    }
}