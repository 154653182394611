import {customElement} from 'lit/decorators.js';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {downloadFile} from '../../../__internal/local/helpers/FileHelper';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {html} from 'lit';

@customElement('component-data-collection-list-item-cell-download-winners')
class ComponentDataCollectionListItemCellDownloadWinners extends ComponentFirestoreCollectionListItemCell {
    @property({type: Object})
    @computed('item')
    get drawDate(): Date {
        return this.item.drawn;
    }

    override render() {
        return html`
            <component-button @click="${this.downloadWinnersCsv}">
                Download winners
            </component-button>
        `;
    }

    @toastProgressWrapper({
        successMessage: 'Downloaded',
        progressMessage: 'Creating csv',
        failedMessage: 'Failed creating csv: {{e}}',
    })
    async downloadWinnersCsv() {
        let winners = await adminCall.competitionsDownloadWinners(this.drawDate);
        downloadFile(winners.csv, `${this.drawDate.toDateString()} winners.csv`, 'text/csv');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-download-winners': ComponentDataCollectionListItemCellDownloadWinners;
    }
}
