import LocalPackage from '../../__internal/local/LocalPackage';
import {NotificationsPackage} from '../shared';
import './components/component-data-collection-list-item-cell-notification-item';
import './components/component-notifications-preferences';
import './components/component-menus-item-notifications';
import './components/component-notifications-do-action';
import './components/component-notifications-auto-dismiss-all';
import './components/component-firestore-collection-list-item-cell-notification-campaign-stats';
import './components/component-notifications-campaign-admin-publishing-controls';
import './components/component-input-notification-campaign-channel-overrides';
import './components/component-notifications-create-campaign-from-template';


export class NotificationsLocalPackage extends NotificationsPackage implements LocalPackage {

}