import {customElement} from 'lit/decorators.js';
import {ComponentNotificationsPreferenceChannelBase} from '../component-notifications-preference-channel-base';

@customElement('component-notifications-preference-channel-site')
class ComponentNotificationsPreferenceChannelSite extends ComponentNotificationsPreferenceChannelBase {

    get name() {
        return 'Site';
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-preference-channel-site': ComponentNotificationsPreferenceChannelSite;
    }
}