export class FriendlyMessage extends Error {

}

export const remapExceptionCode = async <T extends any>(remappedMessages: {
    [key: string]: { message: string, errorClass?: typeof Error | typeof FriendlyMessage }
}, call: () => T): Promise<T> => {
    try {
        return await call();

    } catch (e: any) {
        let remappedMessage = remappedMessages[e.code] || remappedMessages[e.message];
        if (remappedMessage) {
            let ErrorClass = remappedMessage.errorClass || Error;
            if (remappedMessage) throw new ErrorClass(remappedMessage.message);
        }

        throw e;
    }
};