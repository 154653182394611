import {DateableDocument, RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';

export const FIRESTORE_COLLECTION_MEDIA = 'media';

declare global {
    interface Collections {
        media?: FirebaseFirestore.CollectionReference<MediaDocument>;
    }
}

export type MediaDocumentAccessField = 'public' | 'private';

export interface MediaDocument extends RefferableDocument, DateableDocument {
    state: 'pre-upload' | 'uploading' | 'unprocessed' | 'processing' | 'processed' | 'failed',
    type: string;
    owner: FirestoreDocumentReference,
    key: string;
    originalFile: string;
    access: 'public' | 'private';
    meta?: {
        [key: string]: any;
    },
    items: {
        [key: string]: string;
        original: string;
    }
}

export interface MediaReferenceField {
    media: FirestoreDocumentReference;
    type: string;
    miniPreloadImage?: string;
    usages?: string[];
}