import {RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';

export const FIRESTORE_COLLECTION_MENU = 'menus';
export const FIRESTORE_COLLECTION_MENU_SUB_ITEMS = 'items';


declare global {
    interface Collections {
        menus?: FirebaseFirestore.CollectionReference<MenuDocument>;
    }
}


export interface MenuDocument extends RefferableDocument {
    created?: any;
    updated?: any;
    name: string;
    component: string;
    properties?: {
        [key: string]: any;
        classList?: string;
        style?: string;
    };
}

export interface MenuItemDocument extends RefferableDocument {
    created?: any;
    updated?: any;
    name: string;
    component: string;
    permissions?: string[];
    order?: number;
    parent?: FirestoreDocumentReference | null;
    properties: {
        [key: string]: any;
        textContent: string;
        href?: string;
        classList?: string;
        style?: string;
        icon?: string;
    };
}