import {RecordId, Surreal, surrealql as _surrealql} from 'surrealdb';
import {config} from '../../../../config';
import {Firestore, firestoreDatabase} from '@lupimedia/firebase-polyfill/src/firestore/Firestore.ts';
import {doc} from '@lupimedia/firebase-polyfill/src/firestore-v9/DocThings.ts';
import {calculateDocumentPath} from '@lupimedia/firebase-polyfill/src/firestore/helpers/FirestoreHelper.ts';


export type User =
    {
        id: string,
        emailAddress: string,
        firstName: string,
        lastName: string,
        created: Date,
        permissions: string[],
        authMethods: { id: RecordId, type: string }[],
    }
    | null;

const LOCALSTORAGE_AUTH_TOKEN = 'authToken';
export const DATEBASE_CONFIG = config.database;
let storage = sessionStorage[LOCALSTORAGE_AUTH_TOKEN] ? sessionStorage : localStorage;
let activeUser: User = null;

export const surrealApp = {
    db: new Surreal(),
};


const oldSurrealDbSend = (surrealApp.db as any).send;
(surrealApp.db as any).send = async function (...args: any[]) {
    //TODO might need reattaching for the _ref
    let response = await oldSurrealDbSend.apply(this, args);


    return JSON.parse(JSON.stringify(response), (key, value) => {
        debugger;
        if (typeof value === 'string' && value[value.length - 1] === 'Z' && value.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.?\d{0,15}Z/)) {
            return new Date(value);
        }

        if (value && typeof value === 'object' && value._ref?.id && value._ref.path) {
            Object.defineProperty(value, '_ref', {value: doc(firestoreDatabase, value._ref.path), enumerable: false});
        }

        return value;
    });
};
//patch surrealdb send, handleLiveBatch

let reconnectionAttempts = 0;
const _surrealConnectInternal = async () => {
    await surrealApp.db.connect(DATEBASE_CONFIG.url, {
        namespace: DATEBASE_CONFIG.namespace,
        database: DATEBASE_CONFIG.database,
        versionCheck: false,
        async prepare(db) {
            if (((db as any).connection as any)?.auth) return;

            let authToken = storage[LOCALSTORAGE_AUTH_TOKEN];
            if (authToken) {
                await signInWithToken(authToken);

            } else {
                setUser(null);
            }
        },
    });
};
let surrealConnectTimeoutId: number;
const surrealConnect = (delay = 0) => {
    clearTimeout(surrealConnectTimeoutId);

    const doConnect = async () => {
        reconnectionAttempts++;

        try {
            await _surrealConnectInternal();

        } catch (e) {
            console.info('[Surreal] Failed connecting, reconnecting', reconnectionAttempts);
            await surrealConnect(Math.min(1000, reconnectionAttempts * 100));
        }
    };

    if (delay) {
        surrealConnectTimeoutId = setTimeout(doConnect, delay);

    } else {
        doConnect();
    }
};
const watchStorageForAuthChange = () => {
    window.addEventListener('storage', async (e) => {
        if (e.key !== LOCALSTORAGE_AUTH_TOKEN) return;

        let authToken = storage[LOCALSTORAGE_AUTH_TOKEN];
        if (authToken) {
            await signInWithToken(authToken);

        } else {
            signOut();
        }
    });
};

surrealConnect();
watchStorageForAuthChange();
surrealApp.db.emitter.subscribe('connected', () => {
    reconnectionAttempts = 0;
});
surrealApp.db.emitter.subscribe('disconnected', () => {
    console.info('[Surreal] Disconnected, reconnecting', reconnectionAttempts);
    surrealConnect(Math.min(1000, reconnectionAttempts * 100));
});
surrealApp.db.emitter.subscribe('error', (e) => {
    console.error('[Surreal] error', e);
});
Firestore.init(surrealApp.db);


export const getActiveAuthToken = (): string | undefined => {
    return storage[LOCALSTORAGE_AUTH_TOKEN];
};

let authStateChangedListeners = new Set<(user: User) => void>();
export const onAuthStateChanged = (listener: (user: User) => void) => {
    authStateChangedListeners.add(listener);
};
export const offAuthStateChanged = (listener: (user: User) => void) => {
    authStateChangedListeners.delete(listener);
};

export const loadUser = async () => {
    setUser((await surrealApp.db.query('RETURN fn::client::auth::getAuthInfo()'))[0] as User);

    return activeUser;
};

export const refreshAuthToken = async () => {
    // let markLastActiveResponse = surrealApp.db.query(`UPDATE $auth SET lastActive = time ::now(), lastActiveIp = $session.ip RETURN NONE`);
    let shouldRefreshSession = (await surrealApp.db.query('RETURN fn::client::auth::shouldRefreshSession()'))[0];
    if (shouldRefreshSession) {
        try {
            await surrealApp.db.authenticate(storage[LOCALSTORAGE_AUTH_TOKEN]);

        } catch (e) {
            await surrealApp.db.use({
                namespace: DATEBASE_CONFIG.namespace,
                database: DATEBASE_CONFIG.database,
            });// remove when unneeded (https://github.com/surrealdb/surrealdb/issues/4813)

            throw e;
        }
    }
};

const setUser = (user: User) => {
    activeUser = user;

    for (let authStateChangedListener of authStateChangedListeners) {
        authStateChangedListener(user);
    }
};

export const signInWithToken = async (authToken: string) => {
    try {
        await surrealApp.db.authenticate(authToken);
        requestAnimationFrame(async () => {
            await loadUser();
        });

    } catch (e) {
        await surrealApp.db.use({
            namespace: DATEBASE_CONFIG.namespace,
            database: DATEBASE_CONFIG.database,
        }); // remove when unneeded (https://github.com/surrealdb/surrealdb/issues/4813)

        //TODO maybe dont nuke the token for all reasons???
        delete storage[LOCALSTORAGE_AUTH_TOKEN];
        console.error('Failed reauthing, running logged out', e, e.message);
        setUser(null);
    }
};

export const signupWithUsernameAndPassword = async (email: string, password: string, extraArgs: any) => {
    let authToken = await surrealApp.db.signup({
        namespace: DATEBASE_CONFIG.namespace,
        database: DATEBASE_CONFIG.database,
        access: DATEBASE_CONFIG.scope,
        variables: {
            emailAddress: email.toLowerCase(),
            password: password,
            deviceId: localStorage.__deviceId,
            ...extraArgs,
        },
    });
    storage[LOCALSTORAGE_AUTH_TOKEN] = authToken;

    return await loadUser();
};

export const signInWithEmailAndPassword = async (email: string, password: string) => {
    return reauthenticateWithCredential({
        access: DATEBASE_CONFIG.scope,
        emailAddress: email.toLowerCase(),
        password: password,
    });
};

export const signInWithAuthProvider = async (provider: string, authData: any) => {
    return reauthenticateWithCredential({
        access: `${DATEBASE_CONFIG.scope}_${provider}`,
        authData: authData,
    });
};

export const linkWithAuthProvider = async (provider: string, authData: any) => {
    await callableQuery(`auth::provider::${provider}::link`)(authData);
};

export const reauthenticateWithCredential = async (credential: { access: string, [key: string]: any }) => {
    let authToken = await surrealApp.db.signin({
        namespace: DATEBASE_CONFIG.namespace,
        database: DATEBASE_CONFIG.database,
        access: credential.access,
        variables: {
            ...credential,
            deviceId: localStorage.__deviceId,
        },
    });
    storage[LOCALSTORAGE_AUTH_TOKEN] = authToken;

    return await loadUser();
};

export const setAuthPersistence = (state: 'local' | 'session') => {
    let activeToken = sessionStorage[LOCALSTORAGE_AUTH_TOKEN] || localStorage[LOCALSTORAGE_AUTH_TOKEN];

    if (state === 'local') {
        if (activeToken) {
            localStorage[LOCALSTORAGE_AUTH_TOKEN] = activeToken;

        } else {
            delete localStorage[LOCALSTORAGE_AUTH_TOKEN];
        }

        delete sessionStorage[LOCALSTORAGE_AUTH_TOKEN];
        storage = localStorage;

    } else if (state === 'session') {
        if (activeToken) {
            sessionStorage[LOCALSTORAGE_AUTH_TOKEN] = activeToken;

        } else {
            delete sessionStorage[LOCALSTORAGE_AUTH_TOKEN];
        }

        delete localStorage[LOCALSTORAGE_AUTH_TOKEN];
        storage = sessionStorage;
    }
};

export const signOut = () => {
    surrealApp.db.invalidate();

    if (storage[LOCALSTORAGE_AUTH_TOKEN]) {
        delete storage[LOCALSTORAGE_AUTH_TOKEN];
    }

    setUser(null);
};


export const surrealql = _surrealql;


const NAMED_QUERY_ARG = 'arg';
type NamedQueryOptionsSource = 'server' | 'cache';
type NamedQueryOptions = {
    source: NamedQueryOptionsSource
};

const deepReffableize = (object: any) => {
    if (object && object.id && object.__path && object.__id) {
        object._ref = {
            path: object.__path,
            id: object.__id,
            surrealId: object.id,
        };

        delete object.__path;
        delete object.__id;
        delete object.id;
    }

    for (let key in object) {
        if (key === '_ref') continue;

        if (typeof object[key] === 'object') {
            deepReffableize(object[key]);
        }
    }
};

export const callableQuery = (queryName: string, options?: NamedQueryOptions) => {
    options ??= {} as NamedQueryOptions;
    options.source ??= 'server';


    return async (...args: any[]) => {
        if (queryName === '__internal::loadFirestoreDocument' || queryName === '__internal::loadFirestoreCollection') {
            if (args[0] instanceof RecordId) {
                args[0] = calculateDocumentPath(args[0]);
            }
        }

        if (options?.source === 'cache') {
            console.warn('Cache not yet implimented, assuming raw fetch for ');
            if (queryName === '__internal::loadFirestoreCollection' || queryName === 'aspireCompsCompetitions::winnersMap') {
                return {
                    metadata: {
                        source: options?.source as NamedQueryOptionsSource,
                        fromCache: true,
                    },
                    data: {docs: []},
                };

            } else {
                throw new Error(`Cache not yet implimented, assuming raw fetch for ${queryName}`);
            }
        }

        let queryArgs = Object.fromEntries(args.map((_, i) => [`${NAMED_QUERY_ARG}${i}`, _]));
        let query = `RETURN fn::client::${queryName}(${args.map((_, i) => `$${NAMED_QUERY_ARG}${i}`).join(', ')});`;

        let queryResponse = await surrealApp.db.query(query, queryArgs);
        deepReffableize(queryResponse);

        return {
            metadata: {
                source: options?.source as NamedQueryOptionsSource,
                fromCache: options?.source === 'cache',
            },
            data: queryResponse[0],
        };
    };
};

export const callableLiveQuery = (queryName: string, options?: NamedQueryOptions) => {
    options ??= {} as NamedQueryOptions;
    options.source ??= 'server';

    let liveQueryCallable = callableQuery(`${queryName}::LIVE`);

    return (callback: (data: {
        metadata: { source: NamedQueryOptionsSource, fromCache: boolean },
        data: any
    }) => void, ...args: any[]) => {
        let liveUUID: any | undefined;

        const loadLiveData = async () => {
            let liveQuery = await liveQueryCallable(...args);
            let params = typeof liveQuery.data[liveQuery.data.length - 1] === 'object' ? liveQuery.data.pop() : {};
            let rawQuery = liveQuery.data.join(';\n');
            if (Object.keys(params).length) {
                // TODO remove when this is sorted https://github.com/surrealdb/surrealdb/issues/2623
                for (let i in params) {
                    rawQuery = rawQuery.replace(`$${i}`, JSON.stringify(
                        params[i],
                        function (key, value) {
                            if (typeof value === 'string' && (this[key] instanceof RecordId)) return `<surrealql-code>${value}</surrealql-code>`;

                            return value;
                        },
                    ));
                }

                rawQuery = rawQuery.replace(
                    /"<surrealql-code>(.*)<\/surrealql-code>"/g,
                    (_, m1) => `${JSON.parse(`"${m1}"`)}`,
                );
            }

            let liveResponse = await surrealApp.db.query(rawQuery, params);


            let lastData = liveResponse[1] as any[] | any;
            deepReffableize(lastData);
            let isSingleItem = !lastData?.docs;
            callback({
                metadata: {
                    source: options?.source as NamedQueryOptionsSource,
                    fromCache: options?.source === 'cache',
                },
                data: lastData,
            });

            liveUUID = liveResponse[0] as any;
            await surrealApp.db.subscribeLive(liveUUID, async (action, result) => {
                if (action === 'CLOSE') return;


                deepReffableize(result);

                if (isSingleItem) {
                    if (action === 'DELETE') {
                        lastData = undefined;

                    } else {
                        lastData = result;
                    }

                } else {
                    if (action === 'UPDATE') {
                        let lastItem = lastData.docs.find(_ => _._ref.id === result._ref.id);
                        if (lastItem) {
                            delete result._ref; //dont reset the _ref as it wont change and is readonly
                            Object.assign(lastItem, result);

                        } else {
                            lastData.docs.push(result);
                        }

                    } else if (action === 'CREATE') {
                        lastData.docs.push(result);

                    } else if (action === 'DELETE') {
                        let deleteIndex = lastData.docs.findIndex(_ => _._ref.id === result._ref.id);
                        if (deleteIndex >= 0) {
                            lastData.docs.splice(deleteIndex, 1);
                        }
                    }
                }


                callback({
                    metadata: {
                        source: options?.source as NamedQueryOptionsSource,
                        fromCache: options?.source === 'cache',
                    },
                    data: lastData,
                });
            });
        };
        const killLiveData = async () => {
            if (!liveUUID) return;

            await surrealApp.db.kill(liveUUID);
            liveUUID = undefined;
        };
        const onAuthChanged = async () => {
            await killLiveData();
            await loadLiveData();
        };

        loadLiveData();
        surrealApp.db.emitter.subscribe('connected', loadLiveData);
        onAuthStateChanged(onAuthChanged);

        return async () => {
            surrealApp.db.emitter.unSubscribe('connected', loadLiveData);
            offAuthStateChanged(onAuthChanged);

            await killLiveData();
        };
    };
};