import {ReactiveController, ReactiveControllerHost} from 'lit';
import {BunnyController} from './BunnyController';
import {surrealApp} from '../helpers/SurrealHelper';
import {measurePerformance, performanceNow, performanceTimeOrigin} from '../helpers/PerformanceHelper.ts';
import {ENV} from '../../../../config.ts';

export enum FetchMethod {
    NETWORK_ONLY,
    NETWORK_FIRST,
    CACHE_ONLY,
    CACHE_FIRST,
    FASTEST,
    FASTEST_THEN_CLEAN,
    LIVE,
    STATIC
}

export enum LOADING_STATE {
    WAITING,
    ABORTED,
    LOADING,
    LOADED,
    STREAMING,
}

export interface Options {
    method?: FetchMethod;
    suppressLoadingError?: boolean;
    measurePerformance?: boolean;
}

const OPTIONS_DEFAULT_MEASURE_PERFORMANCE = ENV === 'local';
export const CACHE_DOCUMENT_DELAYS = new Set<Promise<void>>();

export const hostlessRequest = (Class: (host: ReactiveControllerHost, namedQuery: string, queryArgs: any[], options?: Options) => void, namedQuery: string, queryArgs: any[], options?: Options, streamUpdates?: (data: any) => void) => {
    let dataLoader = undefined as any;
    let ret = new Promise((s) => {
        let tempHost = {
            dataLoader: undefined as any,
            addController(controller: ReactiveController) {
                if (controller.hostConnected) {
                    controller.hostConnected();
                }
            },
            requestUpdate() {
                if (streamUpdates) {
                    streamUpdates(tempHost.dataLoader.data);
                }

                if (!tempHost.dataLoader.loading) {
                    s(tempHost.dataLoader.data);

                    if (!streamUpdates) {
                        tempHost.dataLoader.hostDisconnected();
                    }
                }
            },
            removeController(_controller: ReactiveController) {
            },
            updateComplete: Promise.resolve(true),
        };
        dataLoader = tempHost.dataLoader = (new (Class as any)(tempHost, namedQuery, queryArgs, options)) as any;
    });
    (ret as any).disconnect = () => {
        dataLoader.hostDisconnected();
    };

    return ret as (Promise<any> & { disconnect: () => void });
};


export abstract class SurrealData<T = any> extends BunnyController {
    latency = -1;

    static resolveFetchMethod(fetchMethod?: string): FetchMethod | undefined {
        console.warn(`Returning resolved fetch method, replace this with enums for ${fetchMethod}`);
        if (fetchMethod === undefined) return fetchMethod;

        let ret = {
            'networkOnly': FetchMethod.NETWORK_ONLY,
            'networkFirst': FetchMethod.NETWORK_FIRST,
            'cacheOnly': FetchMethod.CACHE_ONLY,
            'cacheFirst': FetchMethod.CACHE_FIRST,
            'fastest': FetchMethod.FASTEST,
            'fastestThenClean': FetchMethod.FASTEST_THEN_CLEAN,
            'live': FetchMethod.LIVE,
        }[fetchMethod];

        if (!Number.isFinite(ret)) throw new Error(`Failed to look up resolved fetch method of ${fetchMethod}`);

        return ret;
    }

    static async delayCacheDocuments(waitFor: Promise<void>) {
        CACHE_DOCUMENT_DELAYS.add(waitFor);
        await waitFor;
        CACHE_DOCUMENT_DELAYS.delete(waitFor);
    }

    loading: boolean = true;

    loadingState: LOADING_STATE = LOADING_STATE.WAITING;

    loadingError?: Error;

    method: FetchMethod;

    suppressLoadingError: boolean = false;

    measurePerformance: boolean = false;

    options?: Options;

    protected abortController?: AbortController;

    namedQuery: string;

    queryArgs: any[];

    static get db() {
        let db = surrealApp.db;


        Object.defineProperty(this, 'db', {value: db});
        return db;
    };

    constructor(host: ReactiveControllerHost | undefined, namedQuery: string, queryArgs: any[], options?: Options) {
        super();


        this.namedQuery = namedQuery;
        this.queryArgs = queryArgs;
        // if (!path || typeof path !== 'string' || path.includes('null') || path.includes('undefined') || path.includes('//')) throw new Error(`Invalid firestore path of ${path}`);


        this.method = options?.method ?? FetchMethod.CACHE_FIRST;
        this.suppressLoadingError = options?.suppressLoadingError ?? false;
        this.measurePerformance = options?.measurePerformance ?? OPTIONS_DEFAULT_MEASURE_PERFORMANCE;
        this.options = options;


        if (host) {
            this.addHost(host);
        }
    }

    abstract receiveData(loadingState: LOADING_STATE, data: any): Promise<void>;

    abstract fetchData(): AsyncGenerator<[LOADING_STATE, any | undefined]>;

    private async load() {
        if (this.loadingState === LOADING_STATE.LOADING) return;

        this.loading = true;
        this.loadingError = undefined;
        this.abortController = new AbortController();
        let hasMeasuredPerformance = false;

        try {
            let startTs = Date.now();
            this.latency = -1;

            for await (let [loadingState, data] of this.fetchData()) {
                this.loadingState = loadingState;
                await this.receiveData(loadingState, data);

                if (loadingState === LOADING_STATE.LOADED || loadingState === LOADING_STATE.STREAMING) {
                    this.latency = Date.now() - startTs;

                    if (this.measurePerformance && !hasMeasuredPerformance) {
                        hasMeasuredPerformance = true;

                        measurePerformance(`SD:${this.namedQuery}:${this.queryArgs[0] || ''}`, {
                            start: startTs - performanceTimeOrigin(),
                            end: performanceNow(),
                        });
                    }
                }

                console.debug('loadingState', loadingState, this.namedQuery, this.queryArgs);
                this.notifyUpdated();
            }

        } catch (e) {
            this.loadingError = e as any;
            this.notifyUpdated();

            if (!this.suppressLoadingError) {
                console.error('Failed loading surreal data', e, e.message, 'in controller', this);
            }

        } finally {
            this.loading = false;
        }
    }

    private stop() {
        this.abortController?.abort();
    }

    private queuedDisconnectTimeoutId?: number;

    hostConnected(host: ReactiveControllerHost) {
        super.hostConnected(host);


        if (this.queuedDisconnectTimeoutId) {
            clearTimeout(this.queuedDisconnectTimeoutId);
            this.queuedDisconnectTimeoutId = undefined;

            return; //if its reconnecting right after a disconnect its probably cus dom node moving so ignore the reload
        }


        queueMicrotask(() => {
            this.load();
        });
    }

    hostDisconnected(host: ReactiveControllerHost) {
        super.hostDisconnected(host);

        clearTimeout(this.queuedDisconnectTimeoutId);
        this.queuedDisconnectTimeoutId = window.setTimeout(() => {
            this.stop();
            this.queuedDisconnectTimeoutId = undefined;
        }, 0);
    }
}
