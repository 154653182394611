import {EventCallbackFunction} from '../../../__internal/shared/Package';
import {PageContentComponent} from '../../../routing/shared/helpers/FirestoreHelper';


export const EVENT__INTERNAL_APP_READY: string = '_internal-appReady';


export interface AppReadyEvent {
    registerPreComponent(componentSpec: PageContentComponent, registerGlobal?: undefined | string, lazyLoad?: boolean): void;

    registerPostComponent(componentSpec: PageContentComponent, registerGlobal?: undefined | string, lazyLoad?: boolean): void;
}

declare global {
    interface Listeners {
        '_internal-appReady'?: EventCallbackFunction<AppReadyEvent>;
    }
}
