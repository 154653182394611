import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {ComponentForm} from '../../../form/local/components/component-form.ts';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper.ts';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper.ts';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import {adminCall} from '../../../admin/local/helpers/AdminHelper.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';

@customElement('component-notifications-campaign-admin-publishing-controls')
class ComponentNotificationsCampaignAdminPublishingControls extends BunnyElement {

    @property({type: Object, notify: true})
    value: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`

        `,
    ];

    override render() {
        let state = this.value.state;

        return html`
            <div>
                ${state === 'draft' ? html`
                    <component-button @click="${this.save}">
                        Save
                    </component-button>
                    <component-button @click="${this.publish}">
                        Publish
                    </component-button>
                    <component-button @click="${this.sendDraft}">
                        Send draft
                    </component-button>
                    <component-button @click="${this.sendTest}">
                        Send test(local)
                    </component-button>
                ` : undefined}
                ${state === 'queued' ? html`
                    <component-button @click="${this.unpublish}">
                        Unpublish(return to draft)
                    </component-button>
                ` : undefined}
            </div>

            <div style="margin-top: 5px">
                State: ${state}
            </div>
        `;
    }

    async save() {
        let form = (this as any).__formGroup.getRootNode().host as ComponentForm;
        await form.saveAction();
    }

    @confirmationDialog({title: 'Are you sure?', body: 'Are you sure you want to publish this notification campaign?'})
    @toastProgressWrapper()
    async publish() {
        if (!this.value.createAt) throw new FriendlyMessage('Create at is not set');

        this.value.state = 'queued';

        await this.save();
        this.requestUpdate('value');


        HistoryHelper.pushState('/admin/notifications/campaigns');
    }

    @confirmationDialog({title: 'Are you sure?', body: 'Doing this close to the send time is vary dangerous'})
    @toastProgressWrapper()
    async unpublish() {
        if (!this.value.createAt) throw new FriendlyMessage('Create at is not set');

        this.value.state = 'draft';

        await this.save();
        this.requestUpdate('value');
    }

    @toastProgressWrapper({
        progressMessage: 'Sending draft',
        successMessage: 'Sent draft',
        failedMessage: 'Failed sending draft: {{e}}',
    })
    async sendDraft() {
        await this.save();

        await adminCall.notificationsSendDraftCampaign(this.value._ref.id);
    }

    @toastProgressWrapper({
        progressMessage: 'Sending test',
        successMessage: 'Sent test',
        failedMessage: 'Failed sending test: {{e}}',
    })
    async sendTest() {
        await this.save();

        await adminCall.notificationsSendDraftCampaign(this.value._ref.id, 'Lh0Ay8r0TkPmywwxxzkwckM3bzI4');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-campaign-admin-publishing-controls': ComponentNotificationsCampaignAdminPublishingControls;
    }
}