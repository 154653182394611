import {html} from 'lit';
import {customElement} from 'lit/decorators.js';
import './components/component-button';
import {BunnyElement} from './packages/__internal/local/components/bunny-element';
import {property} from './packages/__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {PageContentComponent} from './packages/routing/shared/helpers/FirestoreHelper';
import {requestIdleCallback} from './packages/__internal/local/helpers/TaskHelper';
import {createComponent} from './packages/routing/local/helpers/DomHelper';
import {version} from './config';
import packages from './packages';
import {EVENT__INTERNAL_APP_READY} from './packages/__internal/local/helpers/SetupAppReadyEvent';
import {Auth} from './packages/auth/local/controllers/Auth';
import {doc} from '@lupimedia/firebase-polyfill/src/firestore-v9/DocThings.ts';
import {calculateFullDocumentRecordId} from '@lupimedia/firebase-polyfill/src/firestore/helpers/FirestoreHelper.ts';
import {collection} from '@lupimedia/firebase-polyfill/src/firestore-v9/CollectionThings.ts';
import {firestoreDatabase} from '@lupimedia/firebase-polyfill/src/firestore/Firestore.ts';
import {FIRESTORE_COLLECTION_ACCOUNTS} from './packages/auth/shared/helpers/FirebaseHelper.ts';
import {measurePerformance, performanceNow} from './packages/__internal/local/helpers/PerformanceHelper.ts';


export const GLOBAL_GRAPPYFILL = {
    get account() {
        let user = Auth.getInstance().user;
        if (!user) return;

        return {
            _surrealId: calculateFullDocumentRecordId(doc(collection(firestoreDatabase, FIRESTORE_COLLECTION_ACCOUNTS), user.uid)), //TODO load the account ref
        };
    },
    packages: packages,
};

let instance: AspireApp;

@customElement('aspire-app')
export class AspireApp extends BunnyElement {
    auth = Auth.getInstance(this);

    static getInstance() {
        return instance;
    }

    @property({type: Boolean})
    finishedLoading = false;

    @property({type: Array})
    preComponents: any[] = [];

    @property({type: Array})
    postComponents: any[] = [];

    override render() {
        return html`
            ${this.preComponents}

            <routing-page></routing-page>

            ${this.postComponents}
        `;
    }

    constructor() {
        super();

        instance = this;
    }

    connectedCallback() {
        super.connectedCallback();


        document.documentElement.classList.remove('preLoaded');
        this.finishedLoading = true;
        measurePerformance('App ready', {
            start: (window as any).__appRunStart,
            end: performanceNow(),
        });

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js?version=' + version, {scope: '/'})
                .then(function (reg) {
                    if (reg.update) {
                        reg.onupdatefound = function () {
                            reg.update();
                        };
                    }
                })
                .catch(function (error) {
                    console.log('[SW] Registration failed with ' + error);
                });
        }

        let registerComponent = (parent: any[], componentSpec: PageContentComponent, registerGlobal: undefined | string = undefined, lazyLoad: boolean = false) => {
            if (lazyLoad) {
                let time = Date.now();
                requestIdleCallback(() => {
                    console.info('Delayed loading of ', componentSpec.component, 'by', (Date.now() - time));
                    registerComponent(parent, componentSpec, registerGlobal);
                });

                return;
            }

            let component = createComponent(componentSpec, {});
            parent.push(component);
            this.requestUpdate();
        };
        packages.trigger(EVENT__INTERNAL_APP_READY, {
            registerPreComponent: registerComponent.bind(null, this.preComponents),
            registerPostComponent: registerComponent.bind(null, this.postComponents),
        });
    }

    createRenderRoot() {
        return this;
    }

}

declare global {
    interface HTMLElementTagNameMap {
        'aspire-app': AspireApp;
    }
}
