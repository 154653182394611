import {customElement} from 'lit/decorators.js';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {userCall} from '../../../auth/local/helpers/UserCallHelper.ts';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell.ts';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';

@customElement('component-promotional-draws-collection-list-item')
class ComponentPromotionalDrawsCollectionListItem extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        //language=SCSS
        scss`
            .itemContainer {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding-bottom: 15px;
            }

            h4 {
                background: var(--primary-color);
                color: white;
                padding: 10px 15px;
                margin: 0;
            }

            p {
                padding: 0 15px;
                margin-bottom: 0;
            }

            h4 {
                background: var(--primary-color);
                color: white;
                padding: 10px 15px;
                margin: 0;
            }

            component-media-view-image {
                background-color: #f2f2f2;
                position: relative;
                box-shadow: 0 1px 0 0 rgba(142, 146, 135, .3);
                overflow: hidden;

                > component-media-view-image {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    max-width: 33%;
                    max-height: 33%;
                    background-color: #f2f2f2;
                    box-shadow: -1px -1px 3px 0 rgba(142, 146, 135, .6), 0 0 10px 0 rgba(32, 32, 32, .4);
                    width: 100%;
                    overflow: hidden;
                }
            }
        `,
    ];

    render() {
        let item = this.item;
        let authMethodKey: string = this.item.authMethod;
        if (authMethodKey === 'youtube') {
            authMethodKey = 'google';
        }


        return html`
            <div class="itemContainer">
                <h4>${item.productName}</h4>
                <component-media-view-image .media="${item.winnerImage}"
                                            type="default-thumbnail"
                                            fetch-method="cacheFirst"
                                            width="400"
                                            height="300">
                    <component-media-view-image .media="${item.productImage}"
                                                type="default-thumbnail"
                                                fetch-method="cacheFirst"
                                                width="400"
                                                height="300"></component-media-view-image>
                </component-media-view-image>

                <p>
                    <strong>Winner</strong> &middot; ${item.winnerUsername}
                    <small>on</small>
                    <span style="text-transform: capitalize;">${item.authMethod}</span>
                </p>

                <p>
                    <component-button class="loginWithButton"
                                      @click="${() => this.onClickAccountLink()}"
                                      style="text-transform: none; background-color: white; color: var(--primary-text-color);">
                        <component-icon .icon="app:${authMethodKey}" style="margin-right: 5px"></component-icon>
                        <span style="font-weight: bold">Sign in with <span
                                style="text-transform: capitalize;">${authMethodKey}</span></span>
                        to claim
                    </component-button>
                </p>
            </div>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Loading claim auth',
        successMessage: 'Claim auth loaded, please login to continue',
        failedMessage: 'Failed loading claim auth: {{e}}',
    })
    async onClickAccountLink() {
        let url = (await userCall(`PromotionalDrawGetClaimUrl`, {
            promotionalDrawId: this.item._ref.id,
        })).returnUrl;

        if (url[0] === '/') {
            HistoryHelper.redirect(url);
            throw new FriendlyMessage('Please log into aspire to continue');
        }

        let popup = window.open(url, '', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=540,height=600`);
        if (!popup) throw new FriendlyMessage('There was a problem opening the auth popup, please allow popups');
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'component-promotional-draws-collection-list-item': ComponentPromotionalDrawsCollectionListItem;
    }
}