import {DateableDocument, RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {FORM_ELEMENT_VALUE_TREATMENTS} from '../index';

export const FIRESTORE_COLLECTION_FORM = 'forms';
export const FIRESTORE_COLLECTION_FORM_SUB_ELEMENTS = 'elements';


declare global {
    interface Collections {
        forms?: FirebaseFirestore.CollectionReference<FormDocument>;
    }
}


export enum SAVE_HANDLER {
    SURREAL = 'surreal',
    FIRESTORE = 'firestore',
    FIREBASE_FUNCTION = 'firebaseFunction',
    FETCH = 'fetch',
    ADMIN_CALL = 'ADMIN_CALL',
}

export interface FormDocument extends RefferableDocument, DateableDocument {
    name: string;
    component: string;
    properties?: {
        [key: string]: any;
    };
    events?: {
        [key: string]: any;
    }[];
}

export interface FormElementDocument extends RefferableDocument, DateableDocument {
    key?: string;
    name?: string;
    component: string;
    extendedValueMap?: {
        [key: string]: any;
    };
    placementRegion?: string;
    valueProperty?: string;
    valueTreatment?: FORM_ELEMENT_VALUE_TREATMENTS;
    defaultValue?: string;
    parent?: FirestoreDocumentReference | null;
    order?: number;

    properties?: {
        [key: string]: any;
    };
    events?: {
        [key: string]: any;
    }[];
}