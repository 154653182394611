import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper';
import {AccountDocument} from '../../../auth/shared/helpers/FirebaseHelper';
import {ShopContactDetailDocument} from '../../../shop/shared/helpers/FirebaseHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {Auth} from '../../../auth/local/controllers/Auth';


const ADMIN_CALL_URL = '/_/admincall';

export const internalAdminCall = async (action: string, args: any) => {
    let auth = Auth.getInstance().getIdToken() as string;
    let response = await (await fetch(`${ADMIN_CALL_URL}?action=${action}`, {
        headers: {
            ...(auth ? {'X-Auth': auth as string} : undefined),
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSONStringify(args),
    })).json();
    if (response.status === 'error') throw new FriendlyMessage(response._messages[0]);

    return response;
};

export const internalAdminCallStream = async (action: string, args: any) => {
    let auth = Auth.getInstance().getIdToken() as string;
    return new EventSource(`${ADMIN_CALL_URL}?action=${action}&auth=${encodeURIComponent(auth)}&args=${encodeURIComponent(JSONStringify(args))}`);
};

export const adminCall = {
    competitionsDownloadWinners(drawn: Date) {
        return internalAdminCall('CompetitionsDownloadWinners', {
            drawn: drawn,
        });
    },
    competitionsExportSaleTickets(filter: any, field: 'competitionId' | 'drawn') {
        return internalAdminCall('CompetitionsExportSaleTickets', {
            [field]: filter,
        });
    },
    competitionsManageWinner(competitionId: string, action: 'add' | 'remove' = 'add', number: number) {
        return internalAdminCall('CompetitionsManageWinner', {
            competitionId: competitionId,
            action: action,
            number: number,
        });
    },
    competitionsApplyRippleWinner(competitionId: string, number: number, ticketRippleBounds: number[]) {
        return internalAdminCall('CompetitionsApplyRippleWinner', {
            competitionId: competitionId,
            number: number,
            ticketRippleBounds: ticketRippleBounds,
        });
    },
    shopRefundTransaction(args: any) {
        return internalAdminCall('ShopRefundTransaction', args);
    },
    shopDuplicateProduct(productId: string, newName: string) {
        return internalAdminCall('ShopDuplicateProduct', {
            productId: productId,
            name: newName,
        });
    },
    authDeleteAccount(userId: string) {
        return internalAdminCall('AuthDeleteAccount', {
            userId: userId,
        });
    },
    authUpdateAccount(userId: string, account: Partial<AccountDocument>) {
        return internalAdminCall('AuthUpdateAccount', {
            userId: userId,
            account: account,
        });
    },
    authUnlinkAccountAuth(userId: string, providerId: string, uid: string) {
        return internalAdminCall('AuthUnlinkAccountAuth', {
            userId: userId,
            providerId: providerId,
            uid: uid,
        });
    },
    authUpdateAccountAuthPassword(userId: string, providerId: string, uid: string, password: string) {
        return internalAdminCall('AuthUpdateAccountAuthPassword', {
            userId: userId,
            providerId: providerId,
            uid: uid,
            password: password,
        });
    },
    authUpdateAccountAuthEmail(userId: string, providerId: string, uid: string, emailAddress: string) {
        return internalAdminCall('AuthUpdateAccountAuthEmail', {
            userId: userId,
            providerId: providerId,
            uid: uid,
            emailAddress: emailAddress,
        });
    },
    async authGetAccountAuthMethods(userId: string) {
        return (await internalAdminCall('AuthGetAccountAuthMethods', {
            userId: userId,
        })).authMethods;
    },
    authUpdateAccountPermissionRoles(userId: string, roles: any) {
        return internalAdminCall('AuthUpdateAccountPermissionRoles', {
            userId: userId,
            roles: roles,
        });
    },
    async authLoginAsUser(userId: string) {
        return (await internalAdminCall('AuthLoginAsUser', {
            userId: userId,
        })).token as string;
    },
    async authGetAccountTrackingInfo(userId: string) {
        return (await internalAdminCall('AuthGetAccountTrackingInfo', {
            userId: userId,
        })) as {
            devices: any[],
            sessions: any[]
        };
    },
    async firebaseAnalyticsGetActivityStream(query: string, limit: number) {
        return (await internalAdminCall('FirebaseAnalyticsGetActivityStream', {
            query: query,
            limit: limit,
        })) as {
            activity: any[],
            stats: any
        };
    },
    async shopGetAccountContactDetails(userId: string) {
        return (await internalAdminCall('ShopGetAccountContactDetails', {
            userId: userId,
        })).contactDetails as {
            _id: string,
            data: ShopContactDetailDocument
        }[];
    },
    shopUpdateAccountContactDetail(userId: string, contactDetailId: string, contactDetail: ShopContactDetailDocument, applyToRecentOrders: boolean) {
        return internalAdminCall('ShopUpdateAccountContactDetail', {
            userId: userId,
            contactDetailId: contactDetailId,
            contactDetail: contactDetail,
            applyToRecentOrders: applyToRecentOrders,
        });
    },
    async notificationsCreateCampaign(type: string, prePopulateFlags?: {
        autoDraws?: boolean,
        newIn?: boolean,
        finishingThisWeek?: boolean,
        earlyBadger?: boolean
    }) {
        return (await internalAdminCall('NotificationsCreateCampaign', {
            type: type,
            prePopulateFlags: prePopulateFlags,
        })) as {
            campaignId: string;
        };
    },
    async notificationsSendDraftCampaign(campaignId: string, accountId?: string) {
        return internalAdminCall('NotificationsSendDraftCampaign', {
            campaignId: campaignId,
            accountId: accountId,
        });
    },
};