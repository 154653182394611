import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {downloadFile} from '../../../__internal/local/helpers/FileHelper';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';

@customElement('component-data-collection-list-item-cell-competition-export')
class ComponentDataCollectionListItemCellCompetitionExport extends ComponentFirestoreCollectionListItemCell {

    override render() {
        return html`
            <component-button @click="${this.exportSaleTickets}" title="Shift+Click for all of same draw date">
                Early badger
            </component-button>
        `;
    }

    @toastProgressWrapper()
    async exportSaleTickets(e: MouseEvent) {
        let responseData = e.shiftKey ?
            await adminCall.competitionsExportSaleTickets(this.item.drawn, 'drawn') :
            await adminCall.competitionsExportSaleTickets(this.item._ref.id, 'competitionId');

        for (let competition of responseData.competitions) {
            downloadFile(competition.csv, `${competition.name}.csv`, 'text/csv');
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-competition-export': ComponentDataCollectionListItemCellCompetitionExport;
    }
}