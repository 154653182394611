import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {FIRESTORE_COLLECTION_SHOP_PRODUCTS} from '../../shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {ComponentInputSurrealSelect} from '../../../inputs/local/components/component-input-surreal-select.ts';


@customElement('component-shop-admin-order-create-products')
class ComponentShopAdminOrderCreateProducts extends BunnyElement {

    @property({type: Object})
    newProduct = {
        quantity: 1,
        product: null as any,
    };

    @property({type: Array, notify: true})
    value: {
        name: string;
        productVariant: any;
        quantity: number;
    }[];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <div class="gridContainer">
                <div style="--col: 8">
                    Name
                </div>
                <div style="--col: 4">
                    Quantity
                </div>

                ${this.value?.map(item => html`
                    <div style="--col: 8">
                        ${item.name} (${item.productVariant.path})
                    </div>
                    <div style="--col: 4">
                        <component-input-number .value="${item.quantity}" @value-changed="${(e: CustomEvent) => {
                            item.quantity = e.detail.value;
                            this.requestUpdate('value');
                        }}"></component-input-number>
                    </div>
                `)}
            </div>

            <div class="gridContainer">
                <div style="--col-md: 7; background-color: rgba(32, 32, 32, .1);">
                    <component-input-surreal-select id="newProductSelect"
                                                    .value="${this.bindDeep('newProduct.product')}"
                                                    .path="${FIRESTORE_COLLECTION_SHOP_PRODUCTS}"
                                                    .valueField="${'variants.0._ref'}"
                                                    .fetches="${['variants']}"
                                                    label="Product"></component-input-surreal-select>
                </div>
                <div style="--col-md: 2; background-color: rgba(32, 32, 32, .1);">
                    <component-input-number label="Quantity"
                                            .value="${this.bindDeep('newProduct.quantity')}"></component-input-number>
                </div>
                <div style="--col-md: 3; background-color: rgba(32, 32, 32, .1); color: white">
                    <component-button
                            style="min-width: 0; width: 100%; margin: 10px"
                            @click="${this.addProduct}">
                        Add
                    </component-button>
                </div>
            </div>

        `;
    }

    async addProduct() {
        let product = this.newProduct.product;
        let options = this.shadowRoot.getElementById<ComponentInputSurrealSelect>('newProductSelect').options;
        let selectedOption = options.find(_ => _.value.path === product.path);

        this.value ??= [];
        this.value.push({
            name: selectedOption.label,
            productVariant: product,
            quantity: this.newProduct.quantity,
        });
        this.requestUpdate('value');


        this.newProduct = {product: null, quantity: 1};
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-order-create-products': ComponentShopAdminOrderCreateProducts;
    }
}