import {ReactiveControllerHost} from 'lit';
import {BunnyController} from '../../../__internal/local/controllers/BunnyController';
import {FriendlyMessage, remapExceptionCode} from '../../../__internal/shared/helpers/ExceptionHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {ComponentReauthenticationPasswordDialog} from '../components/component-reauthentication-password-dialog.ts';
import {
    AccountDocument,
    AccountPermissionsDocument,
    FIRESTORE_COLLECTION_ACCOUNTS,
} from '../../shared/helpers/FirebaseHelper.ts';
import {
    callableQuery,
    DATEBASE_CONFIG,
    getActiveAuthToken,
    linkWithAuthProvider,
    onAuthStateChanged,
    reauthenticateWithCredential,
    refreshAuthToken,
    setAuthPersistence,
    signInWithAuthProvider,
    signInWithEmailAndPassword,
    signOut,
    signupWithUsernameAndPassword,
    User,
} from '../../../__internal/local/helpers/SurrealHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {performanceNow} from '../../../__internal/local/helpers/PerformanceHelper.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';
import {userCalls} from '../helpers/UserCallHelper.ts';
import {config} from '../../../../config.ts';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper.ts';
import {RecordId} from 'surrealdb';


const REMAP_ERROR_MESSAGES = {
    'auth/internal-error': {
        message: 'Please try again or contact us for help if the problem persists',
        errorClass: Error,
    },
    'auth/invalid-email': {message: 'Email address is invalid', errorClass: FriendlyMessage},
    'auth/email-already-in-use': {
        message: 'This email address is already in use by another account',
        errorClass: FriendlyMessage,
    },
    'auth/requires-recent-login': {message: 'To do this action please reauthenticate', errorClass: FriendlyMessage},
    'auth/weak-password': {
        message: 'Please enter a password that is at least 6 characters',
        errorClass: FriendlyMessage,
    },
    'auth/user-not-found': {message: 'Invalid email address', errorClass: FriendlyMessage},
    'auth/invalid-action-code': {
        message: 'This supplied action code has expired, please request again',
        errorClass: Error,
    },
    'auth/expired-action-code': {
        message: 'This supplied action code has expired, please request again',
        errorClass: FriendlyMessage,
    },
    'auth/wrong-password': {message: 'Invalid email address or password', errorClass: FriendlyMessage},
    'There was a problem with the database: An error occurred: Account or password do not match.': {
        message: 'Invalid email address or password',
        errorClass: FriendlyMessage,
    },
    'auth/credential-already-in-use': {
        message: 'This credential is already linked to another account',
        errorClass: FriendlyMessage,
    },
    'auth/account-exists-with-different-credential': {
        message: 'The email associated with your [Google/Facebook] account has already got an account with us, please log in with your password and link to your [Google/Facebook] account in your account settings',
        errorClass: FriendlyMessage,
    },

    'auth/popup-closed-by-user': {message: 'Auth popup closed', errorClass: FriendlyMessage},
};

let instance: Auth;

const _sessionStorage = window.sessionStorage;
const _localStorage = window.localStorage;
const SESSION_STORAGE_ACTIVE_AUTH_KEY = '__activeAuth';
const PERIODIC_REFRESH_INTERVAL = 60 * 10 * 1000;
const LOCAL_STORAGE_ACCOUNT_ID_KEY = '__accountId';

let googleAccountsPromise: Promise<any>;
export const getGoogleAccounts = () => {
    if (!googleAccountsPromise) {
        googleAccountsPromise = new Promise<any>(async (s) => {
            await loadTrackingLibrary('https://accounts.google.com/gsi/client');

            s((window as any).google.accounts);
        });
    }


    return googleAccountsPromise;
};

let facebookAccountsPromise: Promise<any>;
export const getFacebookAccounts = () => {
    if (!facebookAccountsPromise) {
        facebookAccountsPromise = new Promise<any>(async (s) => {
            await loadTrackingLibrary('https://connect.facebook.net/en_US/sdk.js');

            s((window as any).FB);
        });
    }


    return facebookAccountsPromise;
};


type StoredUser =
    {
        uid: string,
        emailAddress: string,
        permissions: string[],
        firstName: string,
        lastName: string,
        created: Date,
        authMethods: { id: RecordId, type: string }[],
    }
    | null
const generateFlatUser = (user: User | null) => {
    return user ? {
        uid: user.id.id,
        emailAddress: user.emailAddress,
        firstName: user.firstName,
        lastName: user.lastName,
        created: user.created,
        permissions: user.permissions,
        authMethods: user.authMethods,
    } : null;
};
let activeUser: StoredUser | null = null;

const storeActiveUser = (user: StoredUser) => {
    if (user) {
        _sessionStorage[SESSION_STORAGE_ACTIVE_AUTH_KEY] = JSON.stringify(activeUser);
        _localStorage[LOCAL_STORAGE_ACCOUNT_ID_KEY] = activeUser?.uid;

    } else {
        delete _sessionStorage[SESSION_STORAGE_ACTIVE_AUTH_KEY];
        delete _localStorage[LOCAL_STORAGE_ACCOUNT_ID_KEY];
    }
};

export class Auth extends BunnyController {

    private internalUser: User | null = null;

    getIdToken() {
        return getActiveAuthToken();
    }

    getInternalUser() {
        return this.internalUser;
    }

    set user(value: User | null) {
        this.internalUser = value;
        activeUser = generateFlatUser(value);

        this._trackUserPermissionChanges();
        this._periodicRefresh();

        storeActiveUser(activeUser);
    };

    get user(): StoredUser {
        return activeUser;
    }

    private permissionTracker: (Promise<AccountPermissionsDocument> & {
        disconnect: () => void
    }) | undefined;

    static getInstance(host?: ReactiveControllerHost) {
        if (!instance) {
            instance = new Auth();
        }

        if (host) {
            instance.addHost(host);
        }

        return instance;
    }

    private constructor() {
        super();


        if (activeUser === null) {
            let sessionStorageActiveAuth = _sessionStorage[SESSION_STORAGE_ACTIVE_AUTH_KEY];
            activeUser = sessionStorageActiveAuth ? JSON.parse(sessionStorageActiveAuth) : null;
            console.log('Auth ready after', performanceNow());
        }

        onAuthStateChanged(async (user) => {
            this.user = user;
            this.notifyUpdated();
        });

        this.attemptSigninToken();
        this.attemptSigninState();
    }

    async attemptSigninToken() {
        let hash = location.hash;
        if (!hash || !hash.includes('signInWithToken')) return;

        let signInToken = hash.match(/[#&]signInWithToken=([a-zA-Z0-9]+)/);
        if (!signInToken) return;


        let [username, password] = atob((signInToken[1] as string)).split(':', 2);
        await this.signInWithEmailAndPassword(username, password);
    }

    async attemptSigninState() {
        let hash = location.hash;
        if (!hash || !hash.includes('signInWithState')) return;

        let signInState = hash.match(/[#&]signInWithState=([^&]+)/);
        if (!signInState) return;

        if (localStorage._authCompletedSignInWithState) {
            location.hash = '';
            return;
        }


        let newAuthState = JSON.parse(atob(signInState[1] as string));
        await this.importFirebaseUser(newAuthState);

        if (newAuthState._returnUrl) {
            HistoryHelper.replaceState(newAuthState._returnUrl, document.title, history.state);
        }
        localStorage._authCompletedSignInWithState = 1;
    }

    private _trackUserPermissionChanges() {
        if (this.permissionTracker) {
            this.permissionTracker.disconnect();
            this.permissionTracker = undefined;
        }

        if (!this.user) return;


        this.permissionTracker = SurrealDocument.hostlessRequest(
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_ACCOUNTS}/${this.user.uid}`],
            {method: FetchMethod.LIVE},
            (data: AccountDocument) => {
                if (!data) return;

                if (!this.internalUser || this.internalUser.id.id !== data._ref.surrealId.id) return;
                let currentInternalUserPermissions = (this.internalUser as any).permissions;
                if (JSON.stringify(currentInternalUserPermissions) === JSON.stringify(data.permissions)) return;//its permissions havnt changed so bail

                (this.internalUser as any).permissions = data.permissions;
                activeUser = generateFlatUser(this.internalUser);
                storeActiveUser(activeUser);
                this.notifyUpdated();
            },
        );
    }

    private _periodicRefreshIntervalId: number;

    private _periodicRefresh() {
        clearInterval(this._periodicRefreshIntervalId);

        if (!this.user) return;


        this._periodicRefreshIntervalId = setInterval(async () => {
            await refreshAuthToken();
        }, PERIODIC_REFRESH_INTERVAL);
    }

    getSigninProvider(providerName: string) {
        switch (providerName) {
            case 'facebook':
                return async () => {
                    let facebookAccounts = await getFacebookAccounts();
                    let token = await new Promise((s) => {
                        facebookAccounts.init({
                            appId: config.facebook.appId,
                            version: config.facebook.version,
                        });
                        facebookAccounts.login((response) => {
                            if (response.authResponse) {
                                s(response.authResponse.accessToken);

                            } else {
                                s(false);
                            }
                        }, {scope: 'public_profile,email'});
                    });

                    return token ? {token: token} : false;
                };

            case 'google':
                return async () => {
                    let googleAccounts = await getGoogleAccounts();
                    let token = await new Promise((s) => {
                        //TODO maybe nonce google
                        googleAccounts.id.initialize({
                            client_id: config.google.oauth.clientId,
                            callback: (response) => {
                                s(response.credential);
                            },
                        });
                        googleAccounts.id.prompt((notification) => {
                            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                                s(false);
                            }
                        });
                    });

                    return token ? {token: token} : false;
                };

            case 'apple':
                throw 'nope apple implimneted';
        }

        throw new Error(`Unknown signin provider ${providerName}`);
    }

    async signInWithEmailAndPassword(email: string, password: string) {
        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return signInWithEmailAndPassword(email, password);
        });
    }

    async signInWithCustomToken(token: string) {
        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return signInWithCustomToken(this.auth, token);
        });
    }


    async signInWithPopup(providerName: 'google' | 'facebook') {
        let provider = this.getSigninProvider(providerName);
        if (!provider) throw new Error(`Unknown provider ${providerName}`);

        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            let authData = await provider();
            if (!authData) throw new FriendlyMessage(`Login with ${providerName} canceled`);

            return signInWithAuthProvider(providerName, authData);
        });
    }

    async createUserWithEmailAndPassword(email: string, password: string, extraArgs: any) {
        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return await signupWithUsernameAndPassword(email, password, extraArgs);
        });
    }

    signOut() {
        return signOut();
    }

    private async getReauthenticationCredential() {
        let authMethod = (await callableQuery('auth::getAuthMethod')()).data;
        if (!authMethod) throw new FriendlyMessage('Unable to get reauthentication method for this account, please contact support');


        if (authMethod.type === 'password') {
            return {
                access: DATEBASE_CONFIG.scope,
                emailAddress: this.internalUser?.emailAddress,
                password: await ComponentReauthenticationPasswordDialog.requestPassword(),
            };

        } else if (authMethod.type === 'google' || authMethod.type === 'facebook') {
            let provider = this.getSigninProvider(authMethod.type);
            return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
                let authData = await provider();
                if (!authData) throw new FriendlyMessage(`Login with ${authMethod.type} canceled`);

                return {
                    access: `${DATEBASE_CONFIG.scope}_${authMethod.type}`,
                    authData: authData,
                };
            });
        }

        throw new Error('Unknown authentation provider: ' + authMethod.type);
    }

    async reauthenticate() {
        while (1) {
            try {
                let credential = await this.getReauthenticationCredential();

                await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
                    await reauthenticateWithCredential(credential);
                });

                return;

            } catch (e: any) {
                if (e.message.includes('Account or password do not match')) {
                    await showToast('Reauthenticate failed, try again');
                    continue;
                }

                throw e;
            }
        }
    }

    async reauthenticationWrapper<ReturnType = any>(call: () => Promise<ReturnType>): Promise<ReturnType> {
        while (1) {
            try {
                return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
                    let requiresReauthentication = (await callableQuery('auth::requiresReauthentication')()).data;
                    if (requiresReauthentication) throw new FriendlyMessage('Reauthentication required');

                    return await call();
                });

            } catch (e: any) {
                if (e.message.includes('Reauthentication required')) {
                    await this.reauthenticate();

                    continue;
                }

                throw e;
            }
        }
    }

    async updateEmail(email: string) {
        return this.reauthenticationWrapper(async () => {
            return (await callableQuery('__internal::updateDoc')(this.internalUser.id, {
                emailAddress: email,
            } as Partial<AccountDocument>)).data;
        });
    }


    async updatePassword(password: string) {
        return this.reauthenticationWrapper(async () => {
            return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
                (await callableQuery('auth::updatePassword')(password)).data;
            });
        });
    }

    async linkWithPopup(providerName: string) {
        let provider = this.getSigninProvider(providerName);
        if (!provider) throw new Error(`Unknown provider ${providerName}`);

        return this.reauthenticationWrapper(async () => {
            let authData = await provider();
            if (!authData) throw new FriendlyMessage(`Login with ${providerName} canceled`);

            return linkWithAuthProvider(providerName, authData);
        });
    }

    async unlink(authId: RecordId) {
        return this.reauthenticationWrapper(async () => {
            return (await callableQuery(`__internal::deleteDoc`)(authId)).data;
        });
    }

    async confirmPasswordReset(code: string, newPassword: string) {
        return await remapExceptionCode(REMAP_ERROR_MESSAGES, async () => {
            return (await userCalls.confirmPasswordReset(code, newPassword)).emailAddress;
        });
    }

    reauthenticateWithEmailLink(email: string, url: string) {
        return reauthenticateWithCredential(
            this.internalUser as User,
            EmailAuthProvider.credentialWithLink(email, url),
        );
    }

    async signInWithEmailLink(email: string, url: string) {
        return signInWithEmailLink(this.auth, email, url);
    }

    fetchSignInMethodsForEmail(email: string) {
        return fetchSignInMethodsForEmail(this.auth, email);
    }

    async setStatePersistence(state: 'local' | 'session') {
        await setAuthPersistence(state);
    }

    exportFirebaseUser() {
        return this.internalUser?.toJSON();
    }

    async importFirebaseUser(user?: object) {
        let internalUser: User | null = null;

        if (user) {
            let persistenceManager;
            while (!(persistenceManager = (this.auth as any).persistenceManager)) {
                //wait until persistenceManager is avaliable, its normally about 4 frames
                await delayPromise();
            }

            //HACKS to convert JSON user to Firebase User obj via persistenceManager.getCurrentUser that exists under Auth
            internalUser = await persistenceManager.getCurrentUser.apply({
                auth: this.auth,
                persistence: {
                    _get() {
                        return user;
                    },
                },
            }, []);
        }

        return updateCurrentUser(this.auth, internalUser);
    }


}


if (localStorage['testMode']) {
    (window as any).__Auth = Auth;
}