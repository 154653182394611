import {choose} from 'lit/directives/choose.js';
import {customElement} from 'lit/decorators.js';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FIRESTORE_COLLECTION_SHOP_PRODUCTS} from '../../shared/helpers/FirebaseHelper.ts';

const PREVENT_CLOSE_ON_UNDERLAY_CLICK_DURATION = 250;

@customElement('component-shop-cart-overlay')
class ComponentShopCartOverlay extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    youMayAlsoLikeHeading: string = 'You may also like';

    @property({type: Boolean, reflect: true})
    @computed('recentlyAddedProduct')
    get open() {
        return !!this.recentlyAddedProduct;
    };

    set open(value: boolean) {
        this.recentlyAddedProduct = value;
    };

    @property({type: Object})
    recentlyAddedProduct: any;

    @property({type: String})
    recentlyAddedProductStatus: string;

    @property({type: String})
    recentlyAddedProductErrorMessage: string;

    @property({type: String})
    cartStatus: string;

    _underlayClosePreventionAfterOpen: Promise<void>;

    @property({type: String})
    lastCartUpdateAction: string = '';

    @property({type: Object})
    @computed('recentlyAddedProduct')
    get youMayAlsoLikeFilters() {
        if (!this.recentlyAddedProduct?.product) return undefined;

        return {
            _crossSale: ['IN', [this.recentlyAddedProduct.product._ref.surrealId]],
        };
    }

    @property({type: Array})
    get youMayAlsoLikeFields() {
        return [
            {
                cellProperties: {
                    field: 'name',
                    type: 'text',
                },
                header: false,
                cell: {
                    component: 'component-shop-collection-list-item',
                    properties: {
                        href: '/shop/products/',
                        display: 'condensed',
                        style: '--col: 6',
                    },
                },
            },
        ];
    }

    @property({type: Array})
    youMayAlsoLikeItems: any[] = [];


    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            #underlay {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: .125s;
                z-index: 99;
            }

            #content {
                width: 400px;
                max-width: 100vw;
                position: fixed;
                top: var(--header-bottom, 0);
                right: 0;
                bottom: 0;
                transform: scaleX(0);
                transform-origin: right;
                transition: .125s transform;
                background: white;
                box-shadow: 0 0 7px rgba(32, 32, 32, .2);
                z-index: 99;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                border-bottom: solid #000000 env(safe-area-inset-bottom);
            }

            #content .contentScroll {
                overflow: auto;
                flex: 1;
            }

            @media screen and (max-width: 450px) {
                :host #content {
                    left: 0;
                    width: auto;
                    max-width: none;
                }
            }

            :host(:not([open])) {
                pointer-events: none;
            }

            :host([open]) #content {
                transform: scaleX(1);
            }

            :host([open]) #underlay {
                background-color: rgba(32, 32, 32, .3);
            }

            .actions {
                box-shadow: 0 0 7px rgba(32, 32, 32, .1);
                width: 100%;
                margin: 0;
                --col-margin-scale: 0px
            }

            .actions > * {
                padding: 0;
            }

            .actions component-button {
                display: block;
                margin: 0;
                text-align: center;
                border-radius: 0;
            }

            .productRow {
                display: flex;
                flex-direction: row;
                background-color: rgba(32, 32, 32, .05);
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .productRow .productThumbnailContainer {
                padding: 5px;
                border: 1px solid rgb(216, 216, 216);
                background: rgb(255, 255, 255);
                margin-right: 10px;
                width: 66px;
                height: 50px;
            }

            .productRow .productDescription {
                flex: 1;
            }

            .sectionHeader {
                background-color: var(--primary-text-color);
                color: white;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .sectionHeader > * {
                vertical-align: middle
            }

            h1 {
                font-size: 27px;
            }
        `,
    ];

    override render() {
        let actions = html`
            <div class="gridContainer actions">
                <a style="--col: 6" href="/shop/cart">
                    <component-button>Checkout</component-button>
                </a>
                <a style="--col: 6" href="/shop/products">
                    <component-button style="background: white; color: var(--primary-text-color);">More competitions
                    </component-button>
                </a>
            </div>
        `;

        return html`
            <div id="underlay"></div>

            <div id="content">
                <div class="contentScroll">
                    ${this.recentlyAddedProduct ? html`
                        <div class="gridContainer">
                            <h1 class="sectionHeader">
                                ${choose(this.lastCartUpdateAction, [
                                    ['product-added', () => html`
                                        <component-icon icon="icons:check-circle"
                                                        style="color: var(--primary-color); "></component-icon>
                                    `],
                                    ['product-adding', () => html`
                                        <component-loading
                                                style="display: inline-flex; --loading-spinner-size: 24px; transform: scale(.75); --loading-margin: 0;"
                                                loading></component-loading>
                                    `],
                                    ['product-added-error', () => html`
                                        <component-icon icon="icons:error"
                                                        style="color: var(--attention-color);"></component-icon>
                                    `],
                                ])}

                                <span>
                                    ${this.cartStatus}
                                </span>
                            </h1>

                            <div class="productRow">
                                <component-media-view-image
                                        .media="${this.recentlyAddedProduct?.product?.productImages?.[0]}"
                                        type="default-thumbnail"
                                        .width="${400}"
                                        .height="${300}"
                                        class="productThumbnailContainer">
                                </component-media-view-image>
                                <div class="productDescription">
                                    <h2 style="text-transform: none; line-height: 1; font-size: 22px">
                                        ${this.recentlyAddedProductStatus}
                                    </h2>
                                    <p>${this.recentlyAddedProduct?.variant?.name}</p>
                                </div>
                            </div>
                            ${this.recentlyAddedProductErrorMessage ? html`
                                <div style="background-color: var(--attention-color); padding: 8px 15px; color: white">
                                    <h3>
                                        The following error prevented your basket from being updated, please try again.
                                    </h3>
                                    <p style="margin: 0">
                                        ${this.recentlyAddedProductErrorMessage}
                                    </p>
                                </div>
                                <component-button @click="${this.close}"
                                                  style="margin-left: 15px; margin-right: 15px;">
                                    Back
                                </component-button>
                            ` : undefined}
                        </div>
                    ` : undefined}

                    ${this.lastCartUpdateAction === 'product-added' ? html`
                        ${actions}

                        ${this.youMayAlsoLikeFilters ? html`
                            ${this.youMayAlsoLikeItems.length ? html`
                                <h2 class="sectionHeader"
                                    style="padding-left: 15px; padding-right: 15px; margin-top: 75px">
                                    ${this.youMayAlsoLikeHeading}
                                </h2>
                            ` : undefined}
                            <component-firestore-collection-list .path="${FIRESTORE_COLLECTION_SHOP_PRODUCTS}"
                                                                 .namedQuery="${'shop::getCrossSaleProducts'}"
                                                                 .filters="${this.youMayAlsoLikeFilters}"
                                                                 .fields="${this.youMayAlsoLikeFields}"
                                                                 .items="${this.bind.youMayAlsoLikeItems}"
                                                                 .noItemsMessage="${''}"
                                                                 style="margin-top: 5px">
                            </component-firestore-collection-list>
                        ` : undefined}
                    ` : undefined}
                </div>

                ${actions}
            </div>
        `;
    }

    @listen('shop-cart-updated', window)
    async onShopProductAdd(e: Event) {
        let recentlyAddedProduct = (e as CustomEvent).detail;


        let cartStatus = '';
        let recentlyAddedProductStatus = '';
        let recentlyAddedProductErrorMessage = '';
        switch (recentlyAddedProduct.action) {
            case 'product-added':
                cartStatus = 'Basket updated';
                recentlyAddedProductStatus = `Added ${recentlyAddedProduct.quantity} ticket${recentlyAddedProduct.quantity === 1 ? '' : 's'}`;
                recentlyAddedProductErrorMessage = '';
                break;

            case 'product-adding':
                cartStatus = 'Updating basket';
                recentlyAddedProductStatus = `Adding ${recentlyAddedProduct.quantity} ticket${recentlyAddedProduct.quantity === 1 ? '' : 's'}`;
                recentlyAddedProductErrorMessage = '';
                this.recentlyAddedProduct = recentlyAddedProduct;
                break;

            case 'product-added-error':
                cartStatus = 'Failed updating basket';
                recentlyAddedProductStatus = `Failed adding ${recentlyAddedProduct.quantity} ticket${recentlyAddedProduct.quantity === 1 ? '' : 's'}`;
                recentlyAddedProductErrorMessage = recentlyAddedProduct.error.message.replace('There was a error updating your basket - ', '');
                break;

            default:
                cartStatus = 'Updating basket';
                recentlyAddedProductStatus = 'Updating';
        }

        this.lastCartUpdateAction = recentlyAddedProduct.action;
        this.cartStatus = cartStatus;
        this.recentlyAddedProductStatus = recentlyAddedProductStatus;
        this.recentlyAddedProductErrorMessage = recentlyAddedProductErrorMessage;


        this._underlayClosePreventionAfterOpen = delayPromise(PREVENT_CLOSE_ON_UNDERLAY_CLICK_DURATION);
    }

    @listen('click', 'underlay')
    async closeOnUnderlayClick() {
        await this._underlayClosePreventionAfterOpen;

        this.close();
    }

    @observe('route')
    closeOnRouteChanged(_route: Route) {
        if (!this.recentlyAddedProduct) return;

        this.close();
    }

    close() {
        this.recentlyAddedProduct = null;
        this.recentlyAddedProductStatus = '';
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-cart-overlay': ComponentShopCartOverlay;
    }
}
