import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {Auth} from '../../../auth/local/controllers/Auth.ts';


@customElement('component-data-collection-list-item-cell-notification-item')
class ComponentDataCollectionListItemCellNotificationItem extends ComponentFirestoreCollectionListItemCell {

    @property({type: Boolean})
    visible: boolean = false;

    @property({type: Boolean, reflect: true})
    @computed('item')
    get unread() {
        return !!this.item.unread;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                position: relative;
            }

            :host([unread]):before {
                content: 'NEW';
                position: absolute;
                top: 0;
                right: 0;
                background-color: var(--attention-color);
                border-bottom-left-radius: 3px;
                padding: 3px;
                color: white;
                font-size: 10px;
            }

            h3, p {
                margin: 0;
                line-height: 1.3;
            }

            h3 {
                margin-bottom: 10px;
            }

            .content {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            .actions {
                margin-left: -15px;
                margin-right: -15px;
                overflow: hidden;
                display: flex;
                text-align: center;
            }

            .actions component-button {
                flex: 1;
                border-radius: 0;
                background: var(--primary-color);
                color: white;
                margin: 0;
                flex-direction: column;
                padding-top: 3px;
                padding-bottom: 3px;
                min-height: 46px;
                height: auto;
            }

            .actions component-button + component-button {
                margin-left: 5px;
            }

            .actions component-button .meta {
                color: var(--attention-color);
                line-height: 1;
                background-color: white;
                font-size: 13px;
                border-radius: 3px;
                padding: 3px;
            }
        `,
    ];

    override render() {
        return html`
            ${this.item.image ? html`
                <component-media-view-image .media="${this.item.image}"
                                            type="default-preview"
                                            fetch-method="live"
                                            .width="${400}"
                                            .height="${300}"
                                            style="background-color: #f2f2f2; margin: 0 -15px">
                </component-media-view-image>
            ` : undefined}
            <div style="flex: 1" class="content">
                <h3>
                    ${this.item.title}
                    <small style="text-transform: none; font-weight: normal">
                        &middot; ${RenderingHelper._dateFromNow(this.item.created)}
                    </small>
                </h3>
                <p style="white-space: break-spaces;">${this.item.body}</p>
            </div>
            ${this.item.actions ? html`
                <div class="actions">
                    ${this.item.actions.map((item: any) => html`
                        <component-button @click="${(_: MouseEvent) => this.doAction(item.name)}" style="${item.style}">
                            <span>${item.name}</span>
                            ${item.meta ? html`
                                <span class="meta">${item.meta}</span>
                            ` : undefined}
                        </component-button>
                    `)}
                </div>
            ` : undefined}
        `;
    }

    @toastProgressWrapper({
        failedMessage: 'Failed applying action to notification, please try again: {{e}}',
        progressMessage: 'Actioning',
        successMessage: 'Actioned',
    })
    async doAction(action: string) {
        let auth = Auth.getInstance().getIdToken() as string;
        let responseData = await (await fetch('/_/notifications-DoAction', {
            headers: {
                ...(auth ? {'X-Auth': auth as string} : undefined),
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                notificationId: this.item._ref.id,
                action: action,
            }),
        })).json();


        if (responseData.action === 'redirect') {
            HistoryHelper.redirect(responseData.url);

        } else if (responseData.action === 'dismissed') {
            this.item.unread = false;
            this.requestUpdate('item');
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-notification-item': ComponentDataCollectionListItemCellNotificationItem;
    }
}
