import {customElement} from 'lit/decorators.js';
import {FIRESTORE_COLLECTION_SHOP_CARTS, ShopCartDocument} from '../../shared/helpers/FirebaseHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ComponentShopCartInternal} from './component-shop-cart-internal';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';

@customElement('component-shop-checkout')
export class ComponentShopCheckout extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    cartId: string;

    @property({type: Object})
    @computed('cartId')
    get cart() {
        if (!this.cartId) return undefined;

        return new SurrealDocument<ShopCartDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_CARTS}/${this.cartId}`],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: String})
    step: string = '';

    @property({type: Object})
    internalCart = ComponentShopCartInternal.getInstance();

    private componentValidForUrl: string; // Shit fix for components running when they shouldn't

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            ${super.render()}

            <component-loading loading></component-loading>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.componentValidForUrl = window.location.href; // Shit fix for components running when they shouldn't
    }

    templateBase() {
        return html`
        `;
    }

    @observe('step', 'auth', 'cartId', 'cart', 'internalCart')
    loadInitialStep(step: string, auth: Auth | null, cartId: string, cart: SurrealDocument<ShopCartDocument> | undefined, internalCart: ComponentShopCartInternal) {
        if (this.componentValidForUrl !== window.location.href) return; // Shit fix for components running when they shouldn't

        if (!internalCart) return;
        if (!cart) return;
        if (cart.loading) return;

        if (!cartId) {
            HistoryHelper.replaceState(`/shop/cart`);
            return;
        }

        if (step === '') {
            if (auth?.user) {
                if (!cart.data?.owner) {
                    internalCart.claimAnonymousCart();
                    //currently claiming cart show loading
                    return;
                }

                HistoryHelper.replaceState(`/shop/checkout/${cartId}/your-details`);

            } else {
                HistoryHelper.replaceState(`/shop/checkout/${cartId}/login`);
            }
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-checkout': ComponentShopCheckout;
    }
}