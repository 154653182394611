import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
// import {updateDoc} from 'firebase/firestore';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument';
import {AccountDocument, FIRESTORE_COLLECTION_ACCOUNTS} from '../../shared/helpers/FirebaseHelper';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';

@customElement('component-account-details')
class ComponentAccountDetails extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    title: string = 'Account details';

    @property({type: Object})
    @computed('auth')
    get account() {
        let userId = this.auth.user?.uid;
        if (!userId) return undefined;

        return new SurrealDocument<AccountDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_ACCOUNTS}/${userId}`],
            {method: FetchMethod.LIVE},
        );
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <div class="contentStep">
                ${this.title ? html`
                    <h2 style="margin-bottom: 0">${this.title}</h2>
                ` : undefined}

                <component-native-form @submit="${this.save}">
                    <component-input
                            label="First name"
                            name="firstName"
                            .value="${this.account?.data?.firstName}"
                            required
                    ></component-input>
                    <component-input
                            label="Last name"
                            name="lastName"
                            .value="${this.account?.data?.lastName}"
                            required
                    ></component-input>

                    <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                        Submit
                    </button>

                    <div slot="submitButton" style="margin: 25px -15px -25px -15px;">
                        <component-button style="display: block; border-radius: 0">
                            Update account details
                        </component-button>
                    </div>
                </component-native-form>
            </div>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Updating account details',
        successMessage: 'Account details updated successfully',
        failedMessage: 'Failed updating account details : {{e}}',
    })
    @bind()
    async save(e: FormSubmitEvent) {
        let finalResponse = await e.detail.setResponse(async () => {
            let formData = e.detail.data;
            formData.firstName = formData.firstName.trim();
            formData.lastName = formData.lastName.trim();

            if (formData.firstName.length < 2) throw new FriendlyMessage('First name must be atleast 2 characters long');
            if (formData.firstName.includes('@')) throw new FriendlyMessage('First name can not contain a "@" character');
            if (formData.lastName.length < 2) throw new FriendlyMessage('Last name must be atleast 2 characters long');
            if (formData.lastName.includes('@')) throw new FriendlyMessage('Last name can not contain a "@" character');


            await callableQuery('__internal::updateDoc')(this.account?.data._ref.surrealId, {
                firstName: formData.firstName,
                lastName: formData.lastName,
            } as Partial<AccountDocument>);

            return 'Account details updated successfully';
        });

        if (finalResponse instanceof Error) {
            throw finalResponse;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-account-details': ComponentAccountDetails;
    }
}