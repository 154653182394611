import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-aspire-comps-stats')
class ComponentAspireCompsStats extends BunnyElement {

    @property({type: Object})
    stats: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                text-align: center;
            }

            h3 {
                font-size: 16px;
                font-weight: normal;
            }

            img, component-media-view-image {
                height: 50px;
            }
        `,
    ];

    override render() {
        let items = [
            {
                value: this.stats?.winners || 0,
                label: 'Winners',
                image: {
                    src: '/images/aspire-star.svg',
                    alt: 'Aspire Competitions',
                },
            },
            {
                value: this.stats?.facebookLikes || 0,
                label: 'Likes',
                image: {
                    src: '/images/facebook-like.svg',
                    alt: 'Facebook',
                },
            },
            {
                value: this.stats?.trustpilotReviews || 0,
                label: 'Reviews',
                image: {
                    src: '/images/trustpilot.svg',
                    alt: 'Trustpilot',
                },
            },
        ];

        return html`
            <component-firebase-remote-config .value="${this.bind.stats}"
                                              key="homepageStats"></component-firebase-remote-config>

            <div class="gridContainer" style="max-width: 800px; --col-margin-scale: 0">
                ${items.map(item => html`
                    <div style="--col: 4">
                        <component-media-view-image width="30" height="30"
                                                    alt="${item.image.alt}"
                                                    src="${item.image.src}"></component-media-view-image>
                        <h3><strong>${item.value}</strong><br>${item.label}</h3>
                    </div>
                `)}
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-stats': ComponentAspireCompsStats;
    }
}