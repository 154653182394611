import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {html} from 'lit';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';

@customElement('component-firestore-collection-list-item-cell-notification-campaign-stats')
class ComponentFirestoreCollectionListItemCellNotificationCampaignStats extends ComponentFirestoreCollectionListItemCell {

    private updateRenderTimeoutId? = 0;

    static override styles = [
        ...ComponentFirestoreCollectionListItemCell.styles,
        // language=SCSS
        scss`
            component-progress {
                display: flex;
                padding: 3px;
                margin: 1px;

                span {
                    margin-left: auto
                }
            }
        `,
    ];

    override render() {
        let stats = this.item.stats;
        let sent = this.getSentCount(this.item.createAt, stats?.lastScheduledSend, stats?.total ?? 1);

        return html`
            lastProcessedRecord: ${JSON.stringify(this.item.sending?.lastProcessedRecord)} <br>
            <component-progress .max="${stats?.total ?? 0}"
                                .value="${sent ?? 0}" style="display: flex">
                <strong>Sending</strong>
                <span>${sent ?? 0} / ${stats?.total ?? 0}</span>
            </component-progress>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.doUpdateRenderSchedule();
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        delete this.updateRenderTimeoutId;
        clearTimeout(this.updateRenderTimeoutId);
    }

    @observe('item')
    private doUpdateRenderSchedule() {
        let lastScheduledSend = this.item.stats?.lastScheduledSend;
        if (!lastScheduledSend) return;
        if (lastScheduledSend.getTime() < Date.now()) return;
        if (this.updateRenderTimeoutId) return;

        this.updateRenderTimeoutId = window.setTimeout(() => {
            delete this.updateRenderTimeoutId;
            this.requestUpdate('item');
            this.doUpdateRenderSchedule();
        }, 2000);
    }

    getSentCount(createAt: Date, lastScheduledSend?: Date, total?: number) {
        if (!lastScheduledSend) return 0;


        let start = createAt.getTime();
        let now = Date.now();
        let end = lastScheduledSend.getTime();

        let progress = (now - start) / (end - start);
        if (progress > 1) {
            progress = 1;
        }

        return Math.ceil((total || 0) * progress);
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-item-cell-notification-campaign-stats': ComponentFirestoreCollectionListItemCellNotificationCampaignStats;
    }
}