import {customElement} from 'lit/decorators.js';
import {html, PropertyValues} from 'lit';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../helpers/StyleHelper';
import {BunnyElement} from './bunny-element';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {delayPromise} from '../helpers/PromiseHelper';
import {ref} from '../helpers/decorators/RefDecoratorHelper.ts';

let dialogPolyfill: Promise<{
    readonly default: {
        registerDialog(dialog: HTMLDialogElement): void;
        forceRegisterDialog(dialog: HTMLDialogElement): void;
    }
}>;
const polyfilDialog = async (dialog: HTMLDialogElement) => {
    if (!dialogPolyfill) {
        dialogPolyfill = import('dialog-polyfill');
    }

    (await dialogPolyfill).default.registerDialog(dialog);
};


@customElement('component-dialog')
export class ComponentDialog extends BunnyElement {

    @ref()
    dialogElement: HTMLDialogElement;

    @property({type: Boolean, reflect: true, notify: true})
    opened = false;

    @property({type: Boolean, reflect: true})
    polyfilled = false;

    @property({type: Object})
    positionTarget?: HTMLElement;

    @property({type: Boolean})
    showCloseButton = true;

    @property({type: Boolean})
    closeOnClickBackdrop = true;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                --width: fit-content;
                --height: fit-content;
            }

            dialog {
                flex-direction: column;
                padding: 0;
                border: 0;
                border-radius: 15px;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
                transform: translateY(-25px);
                opacity: 0;
                transition: .125s;
                transition-delay: 62ms;
                overflow: hidden;
                color: inherit;
                width: var(--width);
                height: var(--height);
                max-width: 95vw;
                max-height: 95vh;

                &[open] {
                    display: flex;
                }

                &:focus {
                    outline: none;
                }

                header {
                    display: var(--header-display, flex);
                    background: var(--primary-color);
                    color: white;
                    min-height: 35px;
                    padding-left: 15px;
                    line-height: 1.2;
                    overflow: hidden;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
                    flex-shrink: 0;
                    align-items: center;

                    slot {
                        flex: 1;
                    }

                    component-button {
                        min-width: 0;
                        margin-left: auto;
                        border-radius: 0;
                        background-color: var(--primary-text-color);
                        line-height: 1;
                        flex-shrink: 0;
                        --padding: 6px;
                    }

                    ::slotted(h1), ::slotted(h2), ::slotted(h3), ::slotted(h4), ::slotted(h5), ::slotted(h6) {
                        margin: 0 !important;
                        font-weight: normal;
                        font-size: 20px;
                        margin-top: auto;
                        margin-bottom: auto;
                        padding-right: 15px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                main {
                    overflow: auto;
                    padding: var(--content-padding, 15px);
                    display: var(--main-display, block);
                    flex-direction: var(--main-flex-direction);
                }

                footer {
                    display: var(--footer-display, flex);
                    padding: var(--footer-padding, 10px 15px);
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
                    flex-shrink: 0;
                }

                &::backdrop {
                    transition: .125s;
                    background: rgba(32, 32, 32, .3);
                    opacity: 0;
                }

                & + .backdrop {
                    //these same elements are seperated cus browsers will bail when seeing the ::backdrop in the same line
                    position: fixed;
                    inset: 0;
                    transition: .125s;
                    background: rgba(32, 32, 32, .3);
                    opacity: 0;
                }

                &.opened {
                    transform: translateY(0);
                    opacity: 1;
                    transition: .375s;

                    &::backdrop {
                        opacity: 1;
                    }

                    & + .backdrop {
                        //these same elements are seperated cus browsers will bail when seeing the ::backdrop in the same line
                        opacity: 1;
                    }
                }
            }

            :host([polyfilled]) {
                dialog {
                    &.opened {
                        position: fixed;
                        top: 50% !important;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        background: white;
                    }

                    &:not(.opened) {
                        display: none;
                    }
                }
            }
        `,
    ];

    override render() {
        return html`
            <dialog ${this.dialogElement} @cancel="${this.onDialogCancel}"
                    @click="${this.closeOnClickBackdrop ? this.onBackdropClick : undefined}">
                <header>
                    <slot name="heading"></slot>

                    ${this.showCloseButton ? html`
                        <component-button @click="${() => this.opened = false}">
                            <component-icon icon="icons:close" title="Close"></component-icon>
                        </component-button>
                    ` : undefined}
                </header>

                <main>
                    <slot></slot>
                </main>

                <footer>
                    <slot name="footer"></slot>
                </footer>
            </dialog>
        `;
    }

    protected firstUpdated(_changedProperties: PropertyValues) {
        super.firstUpdated(_changedProperties);

        if (!this.dialogElement.showModal) {
            this.polyfilled = true;
            polyfilDialog(this.dialogElement);
        }
    }

    onDialogCancel(e: Event) {
        e.preventDefault();
        this.opened = false;
    }

    onBackdropClick(e: Event) {
        if (e.target !== this.dialogElement) return;

        this.opened = false;
    }

    @(observe as any)('positionTarget', 'opened')
    repositionToTarget(positionTarget?: HTMLElement, opened?: boolean) {
        if (!positionTarget || !opened) return;

        let positionTargetRect = positionTarget.getBoundingClientRect();
        let dialogStyle = this.dialogElement.style;
        dialogStyle.top = `${positionTargetRect.bottom}px`;
        dialogStyle.left = `${positionTargetRect.left}px`;
        dialogStyle.margin = '0';
    }

    @observe('opened')
    async toggleDialogVisibility(opened: boolean) {
        if (!this.dialogElement.showModal) return;//dialog isnt avaliable yet(unpolyfilled)


        let dialog = this.dialogElement;
        let dialogClassList = dialog.classList;

        if (opened) {
            dialog.showModal();
            dialogClassList.add('opened');

        } else {
            dialogClassList.remove('opened');
            await delayPromise(125);

            // Only close the dialog if it's still meant to be closed after the delay
            if (!this.opened) {
                dialog.close();
            }
        }
    }


}


declare global {
    interface HTMLElementTagNameMap {
        'component-dialog': ComponentDialog;
    }
}