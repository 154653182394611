import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {getRegisterMeta} from '../helpers/AccountHelper';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';
import {html} from 'lit';
import {UnshadowStyles} from '../../../__internal/local/controllers/UnshadowStyles';

@customElement('component-signup')
export class ComponentSignup extends BunnyElement {
    //@ts-ignore
    private unshadowStyles = new UnshadowStyles(this);

    @property({type: Object, notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    @storageBoundQueryString('email')
    emailAddress: string = '';

    @property({type: String})
    returnUrl: string = '/';

    @property({type: String})
    termsUrl: string = '';

    @property({type: String})
    loginUrl: string = '/login';

    @property({type: Boolean})
    termsAccepted: boolean = false;


    private attempts = 0;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-signup {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding: 0 15px 0 15px;
                overflow: hidden;

                .formHeader {
                    text-align: center;
                    margin-bottom: 25px;
                    background-color: var(--primary-color);
                    color: white;
                    padding: 10px 15px;
                    margin-left: -15px;
                    margin-right: -15px;
                    box-shadow: var(--shadow-elevation-2dp-box-shadow);
                }

                .formHeader > * {
                    margin: 0;
                }

                component-login-alt {
                    position: relative;
                    margin-top: 35px;
                    margin-left: -15px;
                    margin-right: -15px;
                    padding: 35px 5px 15px 5px;
                    border-top: solid rgba(64, 64, 64, .3) 1px;
                    background-color: var(--primary-text-color);
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }

                component-login-alt:after {
                    content: 'Or login with';
                    display: inline-block;
                    padding: 3px 15px;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: white;
                    box-shadow: var(--shadow-elevation-2dp-box-shadow);
                }
            }
        `,
    ];

    override render() {
        return html`
            <div class="formHeader">
                <h1>
                    Register
                </h1>
                <p>New customers</p>
            </div>
            ${this.loginUrl ? html`
                <div style="text-align: right; margin-top: -20px">
                    <a href="${this.renderUrlEmail(this.loginUrl, 'email', this.emailAddress)}"
                       style="color: var(--attention-color); font-size: 14px">
                        Already have a account? Login
                    </a>
                </div>
            ` : undefined}

            <component-native-form @submit="${this.sendCallback}" style="--loading-inset: -500px">
                <component-input
                        required
                        name="firstName"
                        label="First name"
                        autofocus
                        autocomplete="given-name"></component-input>
                <component-input
                        required
                        name="lastName"
                        label="Last name"
                        autocomplete="family-name"></component-input>
                <component-input-email
                        required
                        name="email"
                        label="Email address"
                        autocomplete="email"
                        inputmode="email"
                        .value="${this.bind.emailAddress}"></component-input-email>
                <component-input-password
                        required
                        name="password"
                        label="Password"
                        autocomplete="new-password"></component-input-password>

                <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">Submit
                </button>

                ${this.termsUrl ? html`
                    <p>
                        <component-input-checkbox required name="terms" .checked="${this.termsAccepted}">
                            I have read and accept the
                            <component-dialogable-link target="_blank" href="${this.termsUrl}"
                                                       style="color: var(--primary-color); display: inline-block">
                                Terms and conditions
                            </component-dialogable-link>
                        </component-input-checkbox>
                    </p>
                ` : undefined}

                <p style="line-height: 1.2; font-size: 80%">
                    Your personal data will be used to support your experience throughout this website, to manage access
                    to your account, and for other purposes described in our <a href="/privacy-policy">privacy
                    policy</a>.
                </p>
                <p style="line-height: 1.2; font-size: 80%">
                    Your phone number will be collected when you make a order so that we can contact you when you win.
                </p>

                <div slot="submitButton" style="margin-top: 50px">
                    <component-button raised
                                      style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                        Register
                    </component-button>
                    <div style="text-align: right; margin-bottom: 50px; margin-top: 10px; font-size: 18px">
                        <a href="${this.renderUrlEmail(this.loginUrl, 'email', this.emailAddress)}"
                           style="color: var(--primary-color);">Already have a account? Login</a>
                    </div>
                </div>
            </component-native-form>

            <component-login-alt></component-login-alt>
        `;
    }

    createRenderRoot() {
        return this;
    }

    @toastProgressWrapper({
        successMessage: 'Signed up successfully',
        progressMessage: 'Signing up',
        failedMessage: 'Failed signing up: {{e}}',
    })
    @bind()
    async sendCallback(e: FormSubmitEvent) {
        this.attempts++;

        let finalResponse = await e.detail.setResponse(async () => {
            let formData = e.detail.data;
            formData.email = (formData.email || '').trim();
            formData.firstName = (formData.firstName || '').trim();
            formData.lastName = (formData.lastName || '').trim();

            if (formData.email.endsWith('@aspirecomps.co.uk')) throw new FriendlyMessage('Sorry but your email address cant end with @aspirecomps.co.uk');

            let result = await this.auth.createUserWithEmailAndPassword(formData.email, formData.password, {
                firstName: formData.firstName,
                lastName: formData.lastName,
                meta: getRegisterMeta(),
            });
            if (!result) throw 'Failed signing up';


            track('accountRegister', {
                path: location.href.replace(location.origin, ''),
                method: 'password',
                attempts: this.attempts,
            });


            HistoryHelper.pushState(this.returnUrl);

            return 'Signed up successfully';
        });

        if (finalResponse instanceof Error) {
            throw finalResponse;
        }
    }

    renderUrlEmail(url: string, field: string, emailAddress: string) {
        if (emailAddress) {
            url += `${url.includes('?') ? '&' : '?'}${field}=${encodeURIComponent(emailAddress)}`;
        }


        return url;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-signup': ComponentSignup;
    }
}