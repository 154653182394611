import {customElement, query, queryAll} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../helpers/decorators/BindDecoratorHelper';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {PageContentComponent} from '../../../routing/shared/helpers/FirestoreHelper';
import {sharedStyles} from '../../../../shared-styles';

interface Controls {
    header?: any[];
    footer?: any[];
}


@customElement('component-data-collection-list')
export class ComponentDataCollectionList extends BunnyElement {

    @property({type: Array, notify: true})
    items: any[] = [];

    @property({type: Array})
    fields: any[] = [];

    @property({type: Object})
    controls: Controls = {};

    @property({type: Array})
    order: string[][] = [];

    @property({type: Array})
    defaultOrder: string[][] | string[] = [];

    @property({type: Object})
    filters: {
        [key: string]: any
    };

    @property({type: Object})
    controlFilters: any = {};

    @property({type: Number, notify: true})
    limit: number = 30;

    @property({type: String})
    noItemsMessage: string = 'No results found';

    @property({type: Boolean, notify: true})
    loadingItems: boolean;

    @property({type: Object})
    @query('#content')
    content: HTMLElement;

    @computed('fields')
    get flatFields() {
        let flatFields = [...this.fields];

        for (let field of flatFields) {
            field.header ??= {};
            if (field.header) {
                field.header.component ??= 'component-firestore-collection-list-header-cell';
                field.header.properties = {...field.cellProperties, ...field.header.properties};
            }

            field.cell ??= {};
            if (field.cell) {
                field.cell.component ??= 'component-firestore-collection-list-item-cell';
                field.cell.properties = {...field.cellProperties, ...field.cell.properties};
            }
        }


        return flatFields;
    }

    @property({type: String})
    headerClass: string = 'gridContainer';

    @property({type: String})
    headerStyle: string = 'max-width: 100%;';

    @property({type: String})
    contentClass: string = 'gridContainer';

    @property({type: String})
    contentStyle: string = 'max-width: 100%;';

    @property({type: String})
    footerClass: string = 'gridContainer';

    @property({type: String})
    footerStyle: string = 'max-width: 100%;';

    @property({type: String})
    error: string;

    @queryAll('#header>*, #footer>*')
    controlElements: HTMLElement[];

    static override styles = [
        // language=SCSS
        sharedStyles,
        scss`
            :host {
                --no-items-message-text-align: center;
            }

            .noItemsMessage {
                text-align: var(--no-items-message-text-align);
            }

            component-loading {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(255, 255, 255, .75);
            }

            component-loading[data-items-count="0"] {
                background-color: white;
            }

            component-loading:not([loading]):not([data-items-count="0"]) {
                display: none;
            }
        `,
    ];

    // language=HTML
    get dataProvider() {
        return html``;
    }

    override render() {
        return html`
            ${this.dataProvider}

            <div class="${this.headerClass}" style="${this.headerStyle}" id="header">
                ${this.renderControls(this.controls?.header || [])}
            </div>
            <div style="min-height: 100px; position: relative">
                <div class="${this.headerClass}" style="${this.headerStyle}" id="contentHeader">
                    ${Object.values(this.flatFields || []).map((field: any) => this.renderCell(field.header, null))}
                </div>
                <div class="${this.contentClass}" style="${this.contentStyle}" id="content">
                    ${this.items.flatMap(item => (this.flatFields || []).map(field => this.renderCell(field.cell, item)))}
                </div>

                <component-loading .loading="${this.loadingItems}" data-items-count="${this.items.length}">
                    ${!this.items.length ? html`
                        ${this.error ? html`
                            <p class="noItemsMessage" style="color: red">
                                There was an error showing results:<br>
                                ${this.error}
                            </p>
                        ` : html`
                            <p class="noItemsMessage">
                                ${this.noItemsMessage}
                            </p>
                        `}
                    ` : undefined}
                </component-loading>
            </div>

            <div class="${this.footerClass}" style="${this.footerStyle}" id="footer">
                ${this.renderControls(this.controls?.footer || [])}
            </div>
        `;
    }

    renderControl(controlSpec: PageContentComponent) {
        if (!controlSpec) return null;

        return createComponent(controlSpec, {}, {list: this}, false);
    }

    renderCell(cellSpec: any, item: any = null) {
        if (!cellSpec) return null;


        let extendedProperties: {
            [key: string]: any
        } = {
            list: this,
        };

        if (item) {
            extendedProperties.item = item;
        }

        return createComponent(cellSpec, {}, extendedProperties, false);
    }

    private renderControls(controls: any[]) {
        return controls.map(control => this.renderControl({
            ...control,
            events: [{
                'filters-changed': this.onControlFiltersChanged,
            }],
        }));
    }

    @bind()
    onControlFiltersChanged() {
        let newFilters: Record<string, any[]> = {};

        for (let control of this.controlElements) {
            let filters = (control as any).filters;
            if (!filters) continue;

            let standiziedFilters = filters;
            if (Array.isArray(standiziedFilters)) {
                standiziedFilters = Object.fromEntries(standiziedFilters.map(_ => [_.field, [_.comparator, _.value]]));
            }

            Object.assign(newFilters, standiziedFilters);
        }


        if (Object.keys(this.controlFilters).length || Object.keys(newFilters).length) {
            this.controlFilters = newFilters;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list': ComponentDataCollectionList;
    }
}
