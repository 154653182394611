import {customElement} from 'lit/decorators.js';
import {ComponentFirestoreCollectionListControlSearch} from './component-firestore-collection-list-control-search.ts';
import {scss} from '../helpers/StyleHelper';
import {property} from '../helpers/decorators/PropertyDecoratorHelper.ts';
import {storageBoundQueryString} from '../helpers/decorators/StorageBoundDecoratorHelper.ts';
import {html} from 'lit';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper.ts';


@customElement('component-firestore-collection-list-control-search-simple')
export class ComponentFirestoreCollectionListControlSearchSimple extends ComponentFirestoreCollectionListControlSearch {

    @property({type: String})
    label = 'Search';

    @property({type: String, notify: true})
    @storageBoundQueryString('q')
    search = '';

    @property({type: String})
    field = '_search';

    static override styles = [
        ...ComponentFirestoreCollectionListControlSearch.styles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <component-input .value="${this.bind.search}" label="${this.label}"></component-input>
        `;
    }

    @observe('search')
    forceUpdateSearchIntoList(search: string) {
        let filters = [];

        if (search) {
            filters.push({
                field: '_search',
                comparator: '@@',
                value: search,
            });
        }

        this.filters = filters;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-control-search-simple': ComponentFirestoreCollectionListControlSearchSimple;
    }
}