import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {AccountPermissionsDocument, FIRESTORE_COLLECTION_ACCOUNTS} from '../../shared/helpers/FirebaseHelper';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';

@customElement('component-admin-auth-roles')
class ComponentAdminAuthRoles extends BunnyElement {

    @property({type: String})
    accountId: string;

    @property({type: Object})
    @computed('accountId')
    get accountPermissions() {
        if (!this.accountId) return undefined;

        return new SurrealDocument<AccountPermissionsDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_ACCOUNTS}/${this.accountId}`],
            {method: FetchMethod.LIVE},
        );
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <component-loading .loading="${this.accountPermissions?.loading ?? true}">
                <component-input-json .value="${this.bindDeep('accountPermissions.data.roles')}"
                                      label="Roles"
                                      .asString="${false}"></component-input-json>

                <component-button @click="${this.updateRoles}">
                    Update Roles
                </component-button>
            </component-loading>
        `;
    }

    @toastProgressWrapper()
    async updateRoles() {
        await adminCall.authUpdateAccountPermissionRoles(this.accountId, this.accountPermissions?.data.roles);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-auth-roles': ComponentAdminAuthRoles;
    }
}