import {REGISTERED_FUNCTION_ID_shopProductFormAfterSave, ShopPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-shop-cart';
import './components/component-shop-cart-internal';
import './components/component-shop-cart-overlay';
import './components/component-shop-product';
import './components/component-shop-checkout';
import './components/component-shop-checkout-login';
import './components/component-shop-checkout-your-details';
import './components/component-shop-checkout-payment';
import './pages/page-layout-default-shop-checkout';
import './components/component-shop-price';
import './components/component-shop-collection-list-item';
import './components/component-shop-slideshow';
import './components/component-shop-purchase-quantity';
import './components/component-shop-product-description';
import './components/component-shop-checkout-steps';
import './components/component-data-collection-list-item-cell-cart-item';
import './components/component-menus-item-shop-basket';
import './components/component-shop-customer-contact-card';
import './components/component-shop-customer-contact-card-editable';
import './components/component-shop-customer-contact-card-collection';
import './components/component-shop-customer-new-contact';
import './components/component-shop-payment-iframe';
import './components/component-shop-payment-checkoutcom';
import './components/component-shop-payment-redirect';
import './components/component-shop-payment-google-pay';
import './components/component-shop-payment-apple-pay';
import './components/component-data-collection-list-item-cell-order-total';
import './components/component-data-collection-list-item-cell-order-actions';
import './components/component-shop-order-overview';
import './components/component-shop-order-thank-you';
import './components/component-data-collection-list-item-cell-order-item-name';
import './components/component-input-shop-sale-matrix';
import './components/component-input-shop-sale-matrix-rule';
import './components/component-shop-admin-order-products';
import './components/component-shop-admin-order-transactions';
import './components/component-shop-admin-order-notes';
import './components/component-shop-product-guaranteed-draw-date';
import './components/component-shop-product-name';
import './components/component-data-collection-list-item-cell-admin-order-transaction-actions';
import './components/component-data-collection-list-item-cell-admin-orders-customer-name';
import './components/component-data-collection-list-item-cell-admin-orders-items-summary';
import './components/component-shop-product-form-product-price';
import './components/component-shop-products-admin-actions';
import './components/component-data-collection-list-item-cell-shop-product-variant';
import './components/component-input-shop-product-media-file-info-collection';
import './components/component-shop-my-account-addresses';
import './components/component-shop-my-account-cards';
import './components/component-shop-entry-route-type';
import './components/component-shop-order-reserved-countdown';
import {AppReadyEvent, EVENT__INTERNAL_APP_READY} from '../../__internal/local/helpers/SetupAppReadyEvent';
import {registerFunction} from '../../__internal/shared/helpers/RegisteredFunctionsHelper';
import './components/component-shop-admin-wait-for-product';
import './components/component-shop-admin-product-competition-time-defaults';
import './components/component-shop-product-features';
import './components/component-shop-admin-order-create-products';
import './components/component-shop-stock-remaining';
import './components/component-shop-stock-remaining-bar';
import HistoryHelper from '../../__internal/local/helpers/HistoryHelper';
import {addOrderMeta} from './helpers/OrderHelper.ts';


export class ShopLocalPackage extends ShopPackage implements LocalPackage {

    constructor() {
        super();

        registerFunction(REGISTERED_FUNCTION_ID_shopProductFormAfterSave, function (e: CustomEvent) {
            HistoryHelper.replaceState(window.location.href.replace('/create', `/wait-for-product/${e.detail.formData._ref.id}`));
        });
    }

    listen = {
        [EVENT__INTERNAL_APP_READY](e: AppReadyEvent) {
            e.registerPreComponent({
                component: 'component-shop-cart-internal',
            }, 'shopCart');


            let locationSearchParams = new URL(location.href).searchParams;

            let fbc = locationSearchParams.get('fbc');
            let fbp = locationSearchParams.get('fbp');
            if (fbc || fbp) {
                addOrderMeta('facebookLanding', {
                    fbc: fbc,
                    fbp: fbp,
                });
            }

            let utmValues = {
                utm_source: locationSearchParams.get('utm_source'),
                utm_medium: locationSearchParams.get('utm_medium'),
                utm_campaign: locationSearchParams.get('utm_campaign'),
                utm_term: locationSearchParams.get('utm_term'),
                utm_content: locationSearchParams.get('utm_content'),
            };
            if (Object.values(utmValues).filter(_ => _).length) {
                addOrderMeta('landingUtm', utmValues);
            }
        },
    };
}