import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {
    AccountNotificationPreferenceChannel,
    AccountNotificationPreferenceDocument,
} from '../../shared/helpers/FirestoreHelper';
import './component-notifications-preference-channel/component-notifications-preference-channel-site';
import './component-notifications-preference-channel/component-notifications-preference-channel-email';
import './component-notifications-preference-channel/component-notifications-preference-channel-sms';
import './component-notifications-preference-channel/component-notifications-preference-channel-whatsapp';
import './component-notifications-preference-channel/component-notifications-preference-channel-push';
import './component-notifications-preference-channel/component-notifications-preference-channel-fbmessenger';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {Auth} from '../../../auth/local/controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html, unsafeStatic} from 'lit/static-html.js';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData';
import {FIRESTORE_COLLECTION_ACCOUNTS} from '../../../auth/shared/helpers/FirebaseHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {ComponentPwaInstallPrompt} from '../../../__internal/local/components/component-pwa-install-prompt.ts';
import {showToast} from '../../../__internal/local/helpers/ToastHelper.ts';


@customElement('component-notifications-preferences')
class ComponentNotificationsPreferences extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({notify: true})
    auth = Auth.getInstance(this);

    private dirtyPreferences = false;

    private notificationPreferencesLoadTs: number;

    @property({type: Object})
    @computed('auth')
    get notificationPreferences() {
        let userUid = this.auth.user?.uid;
        if (!userUid) return undefined;

        this.notificationPreferencesLoadTs = Date.now();
        return new SurrealDocument<AccountNotificationPreferenceDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_ACCOUNTS}/${userUid}/private/notificationPreferences`],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: Object})
    get pushNotificationsEnabler() {
        let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
                (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
            !(window as any).MSStream;
        let pushSupported = 'Notification' in window && 'PushManager' in window;
        if (isIOS && !pushSupported) {
            let enabler = async () => {
                if (!await ComponentPwaInstallPrompt.getInstance().pwaPrompt(true)) {
                    await showToast('There was a problem showing the install prompt, please share this page to your Homescreen', {heading: 'Something went wrong'});
                }

                return false;
            };
            (enabler as any).helperText = 'Push notifications from iOS 16.4 are only available for Homescreen apps';

            return enabler;
        }

        return pushSupported;
    }

    @property({type: Boolean})
    get pushNotificationsSupported() {
        return !!this.pushNotificationsEnabler;
    }

    // language=CSS
    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .contentStep h2 {
                background-color: var(--primary-text-color);
                color: white;
                margin-top: -25px !important;
                margin-left: -15px;
                margin-right: -15px;
                padding: 5px 15px;
            }

            .addChannels {
                padding-bottom: 0;

                component-button {
                    display: flex;
                    --primary-color: white;
                    color: var(--primary-text-color);
                    position: relative;
                    overflow: visible;
                    text-align: left;
                    margin: 0 -15px;

                    img {
                        width: 45px;
                        margin-right: 15px;
                    }

                    p {
                        text-transform: none;
                        line-height: 1.2;
                    }

                    h3, p {
                        margin: 0;
                    }

                    &[disabled] {
                        > * {
                            opacity: .3;
                            pointer-events: none;
                        }

                        &:after {
                            content: 'Active';
                            font-size: 150%;
                            color: white;
                            background: var(--primary-text-color);
                            border-radius: 5px;
                            padding: 0 5px;
                            line-height: 1.5;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-transform: none;
                        }
                    }

                    + component-button {
                        margin-top: 3px;
                    }
                }
            }


            #channels > * {
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 5px;
            }
        `,
    ];

    override render() {
        let channels = this.notificationPreferences?.data?.channels;
        let addChannelMeta = this.route.current.query.addChannelMeta;
        let pushNotificationsEnabler = this.pushNotificationsEnabler;
        let deviceId = localStorage.__deviceId;

        return html`
            <div class="gridContainer row">
                <div style="--col-md: 6">
                    <div class="contentStep addChannels">
                        <h2>Notify me via</h2>

                        <p>Receive notification about your wins, live draws and notification only special offers!</p>

                        ${this.pushNotificationsSupported ? html`
                            <component-button @click="${async (_: MouseEvent) => {
                                if (typeof pushNotificationsEnabler === 'function') {
                                    if (!await pushNotificationsEnabler()) return;
                                }

                                this.addChannel('push', {deviceId: deviceId});
                            }}"
                                              ?disabled="${
                                                      this.isAddChannelDisabled('push', 5, channels || []) ||
                                                      this.isAddChannelDisabled('push', 1, channels || [], (channel) => channel.meta.deviceId === deviceId)
                                              }">
                                <img src="/images/social/push.svg" alt="Push notifications">
                                <div>
                                    <h3>Push notification</h3>
                                    <p>Receive notifications via device push notification</p>
                                    ${typeof pushNotificationsEnabler === 'function' ? html`
                                        <p style="background: var(--attention-color); color: white; text-align: center; padding: 3px; border-radius: 10px; line-height: 1.1">
                                            ${(pushNotificationsEnabler as any).helperText}
                                        </p>
                                    ` : undefined}
                                </div>
                            </component-button>
                        ` : undefined}
                        <component-button @click="${(_: MouseEvent) => this.addChannel('sms')}"
                                          ?disabled="${this.isAddChannelDisabled('sms', 1, channels || [])}">
                            <img src="/images/social/sms.svg" alt="Sms">
                            <div>
                                <h3>Text message</h3>
                                <p>
                                    Receive notifications via text message
                                    ${addChannelMeta ? html`
                                        on
                                        <strong style="color: var(--attention-color)">
                                            ${addChannelMeta}
                                        </strong>
                                    ` : undefined}
                                </p>
                            </div>
                        </component-button>
                        <!--                        <component-button @click="{_ => this.addChannel('whatsapp')}" -->
                        <!--                            <img src="/images/social/whatsapp.svg" alt="Whatsapp">-->
                        <!--                            Whatsapp<br>messages-->
                        <!--                        </component-button>-->
                        <!--                        <component-button @click="{_ => this.addChannel('fbmessenger')}" -->
                        <!--                            <img src="/images/social/fbmessenger.svg" alt="Facebook messenger">-->
                        <!--                            Fb<br>messenger-->
                        <!--                        </component-button>-->
                    </div>
                </div>

                <div style="--col-md: 6">
                    <div class="contentStep" style="padding-bottom: 0" id="notificationChannels">
                        <h2>Notification methods</h2>
                        <p>
                            Below you can manage your notification methods and the types of notifications you receive on
                            each <br>
                        </p>

                        <div id="channels">
                            ${channels?.map(item => {
                                let tag = unsafeStatic(`component-notifications-preference-channel-${item.id}`);
                                return html`
                                    <${tag} .item="${item}"></${tag}>
                                `;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    isAddChannelDisabled(id: string, max: number, channels: AccountNotificationPreferenceChannel[], extraFilter?: (channel: AccountNotificationPreferenceChannel) => boolean) {
        if (!channels) return true;

        let filteredChannels = channels.filter(_ => _.id === id);
        if (extraFilter) {
            filteredChannels = filteredChannels.filter(extraFilter);
        }


        let currentCount = filteredChannels.length;

        return currentCount >= max;
    }

    addChannel(channel: string, meta?: any) {
        if (!this.notificationPreferences) return;

        let newChannel = {
            id: channel,
            created: new Date(),
            updated: new Date(),
            meta: meta,
        } as AccountNotificationPreferenceChannel;

        let channels = (this.notificationPreferences.data.channels ??= []);
        channels.push(newChannel);
        this.markDirtyPreferences();
    }

    @observe('notificationPreferences')
    async saveNotificationPreferencesChanges(_notificationPreferences?: FirestoreDocument<AccountNotificationPreferenceDocument>) {
        if (!this.dirtyPreferences) return;

        let ref = this.notificationPreferences?.data?._ref;
        if (!ref) return;

        let durationSinceNotificationPreferencesLoad = Date.now() - this.notificationPreferencesLoadTs;
        if (durationSinceNotificationPreferencesLoad < 1000) return;//TODO kinda meh method of change tracking/original ignoring (probbaly should mark it dirty)
        this.dirtyPreferences = false;
        await this.notificationPreferences?.save();
    }

    @listen('remove-channel', 'channels')
    onRemoveChannel(e: Event) {
        let channel = (e as CustomEvent).detail.channel;
        let channels = this.notificationPreferences?.data.channels || [];
        let channelIndex = channels.indexOf(channel);
        console.log('channelIndex', channelIndex);
        console.log('channel', channel);

        if (channelIndex >= 0) {
            channels.splice(channelIndex, 1);
            this.markDirtyPreferences();
        }
    }

    @listen('update-channel', 'channels')
    markDirtyPreferences() {
        this.dirtyPreferences = true;
        this.requestUpdate('notificationPreferences');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-notifications-preferences': ComponentNotificationsPreferences;
    }
}