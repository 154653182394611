import {customElement} from 'lit/decorators.js';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {ComponentAnalyticsDashboardPanel} from './component-analytics-dashboard-panel.ts';

@customElement('component-analytics-dashboard-panel-json')
class ComponentAnalyticsDashboardPanelJson extends ComponentAnalyticsDashboardPanel {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    renderPanel() {
        return html`
            <pre style="margin: 0">${JSON.stringify(this.data, undefined, 4)}</pre>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-dashboard-panel-json': ComponentAnalyticsDashboardPanelJson;
    }
}