import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';

@customElement('component-winners-collection-header')
class ComponentWinnersCollectionHeader extends BunnyElement {

    @property({type: Object})
    winnersStats = new SurrealDocument<{ prizeValue: number, count: number }>(
        this,
        'aspireCompsCompetitions::winnersStats',
        [],
        {method: FetchMethod.FASTEST_THEN_CLEAN},
    );

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let prizeValue = this.winnersStats.data?.prizeValue;
        return html`
            <h1 style="margin-top: 0">
                Our ${this.winnersStats.data?.count || ''} Aspire winners to date, totaling
                &pound;${prizeValue ? this._formatPrizeValue(prizeValue) : ''}M
            </h1>

            <slot></slot>
        `;
    }

    _formatPrizeValue(value: number) {
        return (value / 1000000).toFixed(1).replace('.0', '');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-winners-collection-header': ComponentWinnersCollectionHeader;
    }
}
