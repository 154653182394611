import {MediaPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './components/component-input-media-file';
import './components/component-input-media-file-info';
import './components/component-media-view-image';
import './components/component-media-view-video';
import './components/component-input-media-file-info-collection';
import './components/component-media-view-collection';
import './components/component-data-collection-list-item-cell-media';
import './components/component-input-media-library';
import './components/component-input-media-library-list-item';

export class MediaLocalPackage extends MediaPackage implements LocalPackage {
}