import {customElement} from 'lit/decorators.js';
import {EventType, track, TypeParams} from '../helpers/TrackingHelper';
import {ComponentAnalytics} from './component-analytics';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

import {TrackingSocket} from '../helpers/TrackingSocketHelper.ts';
import {dehydrate} from '../../shared/helpers/TrackingSocketHelper.ts';
import {requestIdleCallback} from '../../../__internal/local/helpers/TaskHelper.ts';


let trackingSocket = new TrackingSocket('/_/fba-authenticate');
let landingUrl = location.pathname;
let landingQueryParams = Object.fromEntries(new URLSearchParams(location.search).entries());
let landingTs = Date.now();
let pageTs = Date.now();
let navigationDepth = 0;

const APP_PERFORMANCE_SAMPLE_RATE = 'performance' in window ? 0.01 : 0;
const APP_PERFORMANCE_SAMPLE_DELAY = 5000;

const sendData = async (type: string, data: object) => {
    let sendData = dehydrate(type, data);

    trackingSocket.send(sendData);
};

const safeFloatPriceToIntPrice = (value?: number) => {
    return Math.round((value || 0) * 100);
};

const getLandingRefererClean = () => {
    let referrer = document.referrer;
    if (!referrer) return undefined;

    let referrerHostname = new URL(referrer)
        .host
        .replace(/^www\./, '');
    if (referrerHostname === location.hostname) return undefined;

    return referrerHostname;
};

@customElement('component-tracking')
class ComponentTracking extends ComponentAnalytics {


    @property({type: String})
    get deviceId() {
        return localStorage.__deviceId as string;
    }

    @property({type: String})
    get sessionId() {
        return sessionStorage.__sessionId as string;
    }

    @property({type: String})
    get tabId() {
        return (window as any).__tabId as string;
    }

    protected generateEventHandlers(): { [key in EventType]: (params: TypeParams[key]) => void } {
        let me = this;
        return {
            appPerformance: async function process(params) {
                try {
                    let url = new URL(params.path, location.origin);

                    await sendData('app-performance', {
                        accountId: me.userId,
                        sessionId: me.sessionId,
                        deviceId: me.deviceId,
                        tabId: me.tabId,
                        url: url.pathname,
                        queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                        landingUrl: landingUrl,
                        landingQueryParams: landingQueryParams,
                        landingTs: landingTs,
                        landingReferrer: getLandingRefererClean(),
                        pageTs: pageTs,
                        errorTs: params.__eventTs,
                        navigationDepth: navigationDepth,
                        historyDepth: history.length,
                        performanceEntries: params.performanceEntries,
                    });

                } catch (e: any) {
                    console.error('Error handler errored :D bailing', e);
                }
            },
            appError: async function process(params) {
                let performanceEntries: any[] = [];
                try {
                    performanceEntries = performance.getEntries();

                } catch (e) {
                    console.error('Failed capturing performance enteries', e);
                }


                try {
                    let url = new URL(params.path, location.origin);

                    await sendData('app-error', {
                        accountId: me.userId,
                        sessionId: me.sessionId,
                        deviceId: me.deviceId,
                        tabId: me.tabId,
                        url: url.pathname,
                        queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                        landingUrl: landingUrl,
                        landingQueryParams: landingQueryParams,
                        landingTs: landingTs,
                        landingReferrer: getLandingRefererClean(),
                        pageTs: pageTs,
                        errorTs: params.__eventTs,
                        navigationDepth: navigationDepth,
                        historyDepth: history.length,
                        error: {
                            name: params.errorReport.name,
                            message: params.errorReport.message,
                            stack: params.errorReport.stack.map((stack: any) => {
                                let ret = {...stack};
                                delete ret.context;

                                return ret;
                            }),
                        },
                        extendedInfo: params.extendedInfo,
                        performanceEntries: performanceEntries,
                    });

                } catch (e: any) {
                    console.error('Error handler errored :D bailing', e);
                }
            },
            viewContent: function process(params) {
                let url = new URL(params.path, location.origin);
                sendData('view-content', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    url: url.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    viewTs: params.__eventTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    viewType: params.type,
                    content: params.id,
                    contentName: params.name,
                    contentValue: safeFloatPriceToIntPrice(params.value),
                    contentImage: params.image,
                    contentMeta: params.meta,
                    tags: params.categories?.map(_ => `category-${_}`),
                });
            },
            shopAddToCart: function process(params) {
                let url = new URL(params.path, location.origin);
                sendData('add-to-cart', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    url: url.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    addToCartTs: params.__eventTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    content: params.id,
                    contentName: params.name,
                    contentValue: safeFloatPriceToIntPrice(params.value),
                    contentImage: params.image,
                    contentMeta: params.meta,
                    value: safeFloatPriceToIntPrice(params.value) * params.quantity,
                    quantity: params.quantity,
                    tags: params.categories?.map(_ => `category-${_}`),
                });
            },
            pageView: function process(params) {
                let url = new URL(params.path, location.origin);
                pageTs = params.__eventTs || Date.now();
                sendData('page-view', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    title: params.title,
                    url: url.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    navigationDepth: navigationDepth + 1, //navigation depth hasnt been moved yet but this is for the next page
                    historyDepth: history.length,
                });

                requestAnimationFrame(() => {
                    //move the navigation depth after a timeout so the disposing events are counted as the actual page
                    navigationDepth++;
                });

                if (APP_PERFORMANCE_SAMPLE_RATE && Math.random() <= APP_PERFORMANCE_SAMPLE_RATE) {
                    setTimeout(() => {
                        requestIdleCallback(() => {
                            track('appPerformance', {
                                path: location.href.replace(location.origin, ''),
                                performanceEntries: performance.getEntries(),
                            });
                        }, {timeout: APP_PERFORMANCE_SAMPLE_DELAY});
                    }, APP_PERFORMANCE_SAMPLE_DELAY);
                }
            },
            accountRegister: function process(params) {
                let url = new URL(params.path, location.origin);
                sendData('auth-register', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    url: url.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    authMethod: params.method,
                    authAttempts: params.attempts,
                    authTs: params.__eventTs,
                });
            },
            accountLogin: function process(params) {
                let url = new URL(params.path, location.origin);
                sendData('auth-login', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    url: url.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    authMethod: params.method,
                    authAttempts: params.attempts,
                    authTs: params.__eventTs,
                });
            },
            shopPurchase: function process(params) {
                sendData('purchase', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    url: location.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(location.search).entries()),
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    value: safeFloatPriceToIntPrice(params.value),
                    orderId: params.orderId,
                    orderItems: params.items.map(_ => ({
                        content: _.id,
                        contentName: _.name,
                        contentValue: safeFloatPriceToIntPrice(_.productPrice),
                        quantity: _.quantity,
                        tags: _.categories?.map(_ => `category-${_}`),
                        value: safeFloatPriceToIntPrice(_.subTotal),
                        wasOnSale: _.wasOnSale,
                    })),
                    purchaseTs: params.__eventTs,
                });
            },
            shopStartCheckout: function process(params) {
                sendData('start-checkout', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    url: location.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(location.search).entries()),
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    value: safeFloatPriceToIntPrice(params.value),
                    orderId: params.orderId,
                    orderItems: params.items.map(_ => ({
                        content: _.id,
                        contentName: _.name,
                        contentValue: _.productPrice,
                        quantity: _.quantity,
                        tags: _.categories?.map(_ => `category-${_}`),
                        value: _.subTotal,
                        wasOnSale: _.wasOnSale,
                    })),
                    startCheckoutTs: params.__eventTs,
                });
            },
            interactableView: function process(params) {
                let url = new URL(params.path, location.origin);
                sendData('interaction-view', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    interactionTs: params.__eventTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    url: url.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                    context: params.context,
                    content: params.content,
                    contentName: params.contentName,
                    contentImage: params.contentImage,
                    contentValue: safeFloatPriceToIntPrice(params.contentValue),
                    contentMeta: params.contentMeta,
                    duration: params.duration,
                    tags: params.tags,
                    instanceId: params.instanceId,
                    type: params.type,
                });
            },
            interactableClick: function process(params) {
                let url = new URL(params.path, location.origin);
                sendData('interaction-click', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    interactionTs: params.__eventTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    url: url.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                    clickInfo: params.clickInfo,
                    context: params.context,
                    content: params.content,
                    contentName: params.contentName,
                    contentImage: params.contentImage,
                    contentValue: safeFloatPriceToIntPrice(params.contentValue),
                    contentMeta: params.contentMeta,
                    duration: params.duration,
                    tags: params.tags,
                    instanceId: params.instanceId,
                    type: params.type,
                });
            },
            pwaPrompt: function process(params) {
                sendData('pwa-prompt', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    url: location.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(location.search).entries()),
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    promptTs: params.__eventTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    pwaPlatform: params.platform,
                    pwaState: params.state,
                });
            },
            toastShown: function process(params) {
                let url = new URL(params.path, location.origin);
                sendData('toast-shown', {
                    accountId: me.userId,
                    sessionId: me.sessionId,
                    deviceId: me.deviceId,
                    tabId: me.tabId,
                    landingUrl: landingUrl,
                    landingQueryParams: landingQueryParams,
                    landingTs: landingTs,
                    landingReferrer: getLandingRefererClean(),
                    pageTs: pageTs,
                    toastTs: params.__eventTs,
                    navigationDepth: navigationDepth,
                    historyDepth: history.length,
                    url: url.pathname,
                    queryParams: Object.fromEntries(new URLSearchParams(url.search).entries()),
                    content: params.content,
                });
            },
        };
    }

    protected setCurrentScreen(_id: string, _options?: any): void {
    }

    protected setUserId(id: string, _options?: any): void {
        this.userId = id;
    }

    protected setUserProperties(_properties: object, _options?: any): void {
    }

    protected async startup() {
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-tracking': ComponentTracking;
    }
}