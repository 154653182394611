import {RoutingPackage} from '../shared';
import LocalPackage from '../../__internal/local/LocalPackage';
import './pages/page-layout-default';
import './pages/page-layout-none';
import './pages/routing-page';
import './components/component-editor-content';
import './components/component-page-editor-content';
import './components/component-page-preloader';
import './components/component-page-editor-content-multi-type';
import './components/component-routing-aliases';
import './components/component-routing-link';
import './components/component-routing-error';
import './components/component-routing-loading';

export class RoutingLocalPackage extends RoutingPackage implements LocalPackage {
}