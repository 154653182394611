import {customElement} from 'lit/decorators.js';
import {ComponentAnalytics} from './component-analytics';
import {loadTrackingLibrary} from '../helpers/TrackingLibraryLoaderHelper';
import {EventType, TypeParams} from '../helpers/TrackingHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';

@customElement('component-google-analytics')
class ComponentGoogleAnalytics extends ComponentAnalytics {

    private gtag: any;

    @property({type: String})
    pixelId: string;

    protected async startup() {
        (window as any).GoogleAnalyticsObject = '__ga';
        (window as any).dataLayer ??= [];
        let dataLayer: any[] = (window as any).dataLayer;

        let ga: any = this.gtag = function () {
            dataLayer.push(arguments);
        };

        ga.l = Date.now();
        (window as any).__ga = ga;

        await loadTrackingLibrary(`//www.googletagmanager.com/gtag/js?id=${this.pixelId}`);

        this.gtag('js', new Date());
        this.gtag('config', this.pixelId);
    }

    protected setUserId(id: string, _options: any = {}) {
        this.receiveEvent(() => {
            this.gtag('config', this.pixelId, {
                user_id: id,
            });
            this.gtag('set', 'user_properties', {
                crm_id: id,
            });
        });
    }

    protected setCurrentScreen(_id: string, _options: any = {}) {
    }

    protected setUserProperties(_properties: object, _options: any = {}) {
    }

    protected generateEventHandlers(): { [key in EventType]: (params: TypeParams[key]) => void } {
        let track = (...args: any[]) => {
            this.gtag('event', ...args);
        };

        return {
            appPerformance() {
            },
            appError() {
            },
            viewContent() {
            },
            shopAddToCart() {
            },
            pageView(params) {
                track('page_view', {
                    page_location: params.path,
                });
            },
            accountRegister() {
            },
            accountLogin() {
            },
            shopPurchase() {
            },
            shopStartCheckout() {
            },
            interactableView() {
            },
            interactableClick() {
            },
            pwaPrompt() {
            },
            toastShown() {
            },
        };
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-google-analytics': ComponentGoogleAnalytics;
    }
}
