import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FIRESTORE_COLLECTION_SHOP_POINTS, ShopPointDocument} from '../../shared/helpers/FirebaseHelper.ts';

@customElement('component-shop-points-admin-user-heading')
class ComponentShopPointsAdminUserHeading extends BunnyElement {

    @property({type: String})
    accountId: string;

    @property({type: Object})
    @computed('accountId')
    get shopPoints() {
        return new SurrealDocument<ShopPointDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [
                `${FIRESTORE_COLLECTION_SHOP_POINTS}/${this.accountId}`,
                {
                    fetch: ['owner'],
                },
            ],
        );
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        let shopPointsData = this.shopPoints?.data;

        return html`
            ${shopPointsData ? html`
                <h1>
                    ${shopPointsData.owner.firstName} ${shopPointsData.owner.lastName}'s points &middot;
                    ${shopPointsData.points}
                </h1>
            ` : html`
                <h1>${this.accountId} doesnt have any points yet</h1>
            `}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-points-admin-user-heading': ComponentShopPointsAdminUserHeading;
    }
}