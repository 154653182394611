import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';

@customElement('component-data-collection-list-item-cell-admin-orders-customer-name')
class ComponentDataCollectionListItemCellAdminOrdersCustomerName extends ComponentFirestoreCollectionListItemCell {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <a href="?filters[*]=owner.id%3A${this.item.owner.id}">
                <component-button>
                    ${this.item.contact.firstName}
                    ${this.item.contact.lastName}
                </component-button>
            </a>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-admin-orders-customer-name': ComponentDataCollectionListItemCellAdminOrdersCustomerName;
    }
}